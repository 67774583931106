<template lang='pug'>
  //- TODO：SP対応の時は通常のSelectの方が望ましいので、v-ifで分岐が必要
  .a-time-select(@mouseleave="onMouseleave")
    .a-time-select__button(@mouseenter="openSelectItems" :class="{ 'a-time-select__button--disabled': disabled }")
      .a-time-select__text(:class="{ 'a-time-select__text--placeholder': selectedTime === '' }") {{ changeSelectedTime(value) }}
      span.a-time-select__clear(@click="clearSelectedTime" v-if="!required && !disabled")
        i.crevo-icon.crevo-close
    transition(name="fade")
      ul.a-time-select__options(v-if="visibleOptions" :style="popoverPositionStyle")
        li.a-time-select__option(
          v-for="option in options"
          :class="{ 'a-time-select__option--selected': option === selectedTime }"
          @click="changeTime(option)"
        ) {{ option }}
</template>

<script>

export default {
  name: 'TimeSelect',
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: '12:00'
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    byMinute: {
      type: Number,
      required: true,
      validator: (value) => 60 % value === 0
    }
  },
  data() {
    return {
      visibleOptions: false,
      selectedTime: this.value,
      selecting: false,
      checkError: false,
      popoverPositionStyle: null
    }
  },
  computed: {
    options(){
      return Array.from(Array(24)).map((_, hour) =>
        `${this.padding(hour)}:00`
      ).flat()
    },
  },
  watch: {
    value(newVal) {
      this.selectedTime = newVal
    }
  },
  methods: {
    changeTime(selectedOption) {
      this.selectedTime = selectedOption
      this.visibleOptions = false
      this.selecting = false
      this.$emit('change', selectedOption)
    },
    changeSelectedTime() {
      if (this.disabled) this.selectedTime = ''
      if (this.selectedTime === '') return this.placeholder
      return this.selectedTime
    },
    clearSelectedTime() {
      this.selectedTime = ''
    },
    onMouseleave(e) {
      e.preventDefault()
      this.visibleOptions = false
      this.selecting = false
      this.required && this.selectedTime === '' ? this.checkError = true : this.checkError = false
    },
    openSelectItems(e) {
      // NOTE：表示位置にpopoverの高さとフローティングエリア分の高さを加算して画面の高さより数値が大きければ上に表示する
      if (e.clientY + 155 + 100 > window.innerHeight) {
        this.popoverPositionStyle = { bottom: '47px' }
      } else {
        this.popoverPositionStyle = { top: '47px' }
      }
      this.visibleOptions = true

      setTimeout(() => {
        const scrollElement = document.querySelector('.a-time-select__options')
        if (this.selectedTime) {
          const foundScrollElement = [...document.querySelectorAll('.a-time-select__option')].find(data => {
            return this.selectedTime === data.innerText
          })
          // NOTE：選択した時刻が中央に来るようにスクロール(63pxは目視で中央に来るように値を設定)
          scrollElement.scrollTop = foundScrollElement !== undefined ? foundScrollElement.offsetTop - 63 : (scrollElement.scrollHeight / 2) - 63
        } else {
          // NOTE：12:00が縦中央に来るようにスクロール(63pxは目視で中央に来るように値を設定)
          scrollElement.scrollTop = (scrollElement.scrollHeight / 2) - 63
        }
      })
    },
    padding(value){
      return value.toString().padStart(2, '0')
    },
  }
}
</script>

<style lang='scss' scoped>
  .a-time-select {
    position: relative;
    &__button {
      @include flex(between, center);
      width: 100%;
      height: 46px;
      padding: 0 13px 0 18px;
      background: transparent;
      border: 1px solid var(--color-mono-5);
      border-radius: 3px;
      &--disabled {
        background: var(--color-mono-7);
        border-color: var(--color-mono-7);
        .a-time-select__button {
          color: var(--color-mono-4);
          cursor: not-allowed;
        }
      }
    }
    &__text {
      @include flex(start, center);
      width: calc(100% - 20px - 8px);
      height: 100%;
      font-size: 14px;
      &--placeholder {
        color: var(--color-mono-5);
      }
    }
    &__clear {
      @include flex(center, center);
      width: 20px;
      height: 20px;
      color: var(--color-mono-c);
      background: var(--color-mono-5);
      border: 0;
      border-radius: 100%;
      outline: 0;
      transition: .2s;
      &:hover {
        opacity: .8;
      }
      .crevo-icon {
        @include flex(center, center);
        transform: scale(.7);
      }
    }

    &__options {
      position: absolute;
      z-index: 10;
      width: 100px;
      height: 155px;
      overflow-y: auto;
      text-align: left;
      list-style-type: none;
      background-color: var(--color-mono-c);
      border-radius: 3px;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, .15);
    }

    &__option {
      padding: 8px;
      font-size: 14px;
      line-height: 1.1;
      color: var(--color-mono-1);
      cursor: pointer;
      &:hover:not(.a-time-select__option--selected) {
        background: var(--color-mono-a);
      }
      &--selected {
        color: var(--color-mono-c);
        background: var(--color-blue);
      }
    }
  }
  // Animation
  .fade-enter-active, .fade-leave-active {
    transition: .25s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    height: 0;
    opacity: 0;
  }
</style>
