export default [
  { id: 'client_support', description: 'エンドクライアント(動画の納品先)が別にいる場合、直接対応する' },
  { id: 'direction', description: 'シナリオを元に演出を決めてコンテ(指示書)を制作する' },
  { id: 'planning', description: '制作目的、ターゲットに沿って、メディアを選定、適切な動画の構成・表現方法を企画する' },
  { id: 'scenario_writing', description: '企画に沿って具体的なストーリーを設計する' },
  { id: 'narration_script', description: '演出に沿って、ナレーションの原稿を書く' },
  { id: 'copywriting', description: '企画・演出に沿って、キャッチコピーを考える' },
  { id: 'schedule_management', description: 'スケジュール管理・制作全体の進行管理を行う' },
  { id: 'arrange_location', description: '撮影場所の手配をする' },
  { id: 'casting', description: '出演者の手配をして出演交渉する' },
  { id: 'make_up_artist', description: '出演者のヘアメイクをする' },
  { id: 'costume_cordinator', description: '出演者の衣装を準備する' },
  { id: 'art_staff', description: '撮影に必要なセット・小道具などを準備する' },
  { id: 'photography', description: '写真を撮影する' },
  { id: 'film_shooting', description: '動画を撮影する' },
  { id: 'lighting_technician', description: '撮影時の照明を準備・対応する' },
  { id: 'film_editing', description: '動画・静止画・音声・テロップなどをつなぎ合わせる' },
  { id: 'animation', description: 'グラフィック・イラスト・テキストなどに動きをつける' },
  { id: 'animation_3d', description: '3DCGを用いて表現する' },
  { id: 'graphic_design', description: '平面デザイン(レイアウト、テキスト)を制作する' },
  { id: 'illustration', description: 'イラスト(挿絵、人物、キャラクター)を制作する' },
  { id: 'sound_selection', description: '商用利用可能な音楽・効果音を選曲する' },
  { id: 'sound_composition', description: '動画に合わせた音楽・効果音を作曲する' },
  { id: 'narration', description: 'ナレーターを手配し、ナレーションを収録する' }
]
