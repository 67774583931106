import axios from '@axios'

const state = {
  creator: null,
}

const mutations = {
  setCreator(state, creator) {
    state.creator = creator
  }
}

const actions = {
  fetchCreator({ commit }, id) {
    axios.get(`/api/v2/creators/${id}`).then(response => {
      commit('setCreator', response.data.creator)
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
