<template lang='pug'>
  span.a-tag(:class="labelColor") {{ text }}
</template>
<script>
import defaultImage from '@images/common/img-noproject.png'

export default {
  name: 'Label',
  props: {
    color: {
      type: String,
      required: false,
      default: 'green'
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      projectThumbnail: defaultImage
    }
  },
  computed: {
    labelColor() {
      const colors = ['green', 'gray']

      return colors.includes(this.color) ? `a-tag--${this.color}` : ''
    },
  }
}
</script>
<style lang='scss' scoped>
// stylesheets/collet/components/atoms/_label.scssを参照
</style>
