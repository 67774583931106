<template lang="pug">
  .m-input-reference
    Input.m-input-reference__input.m-input-reference__input--url(
      type="url"
      :required="required"
      :value="referenceMovieData.url"
      size="l"
      placeholder="https://"
      @input="InputReferenceUrl"
    )
    .m-input-reference__reasons(:class="{ 'm-input-reference__reasons--separate': withEN }")
      Input.m-input-reference__input(
        type="text"
        :value="referenceMovieData.reasonJA"
        size="l"
        placeholder="参考理由"
        @input="InputReferenceReasonJA"
      )
      //- NOTE：管理画面用
      Input.m-input-reference__input.m-input-reference__input--reason-en(
        type="text"
        :value="referenceMovieData.reasonEN"
        size="l"
        placeholder="参考理由(EN)"
        v-if="withEN"
        @input="InputReferenceReasonEN"
      )
    transition(name="fade")
      p.m-input-reference__validation-message(v-if="validationMessage")
        i.crevo-icon.crevo-exclamation_fill
        | {{ validationMessage }}
</template>

<script>
import Input from 'src/components/atoms/Form/Input'

export default {
  name: 'InputReference',
  components: {
    Input
  },
  props: {
    url: {
      type: String,
      required: false,
      default: ''
    },
    reasonJA: {
      type: String,
      required: false,
      default: ''
    },
    reasonEN: {
      type: String,
      required: false,
      default: ''
    },
    withEN: {
      type: Boolean,
      required: false,
      default: false
    },
    index: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      referenceMovieData: {
        url: '',
        reasonJA: '',
        reasonEN: ''
      },
      required: false
    }
  },
  computed: {
    isRequireUrl () {
      if (!this.referenceMovieData.reasonJA) { return true }
      return !!this.referenceMovieData.url
    },
    isRequireEnglishReason () {
      if (!this.referenceMovieData.reasonJA && !this.referenceMovieData.reasonEN) { return true }
      return !!this.referenceMovieData.reasonEN
    },
    isValidUrl() {
      const url = this.referenceMovieData.url
      const urlRegexp = /^(?:https|http):\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g
      if (!url) { return true }
      return urlRegexp.test(url)
    },
    validationMessage () {
      // NOTE：computed側でreturn前にarea-invalidを発火するようにしないと、赤枠の表示されるタイミングが狂う
      if (!this.isRequireUrl) {
        this.$el.querySelector('.m-input-reference__input--url').setAttribute('aria-invalid', true)
        return 'URLを入力してください'
      } else if (!this.isValidUrl) {
        this.$el.querySelector('.m-input-reference__input--url').setAttribute('aria-invalid', true)
        return 'URLを正しい形式で入力してください'
      } else if (this.withEN && !this.isRequireEnglishReason) {
        this.$el.querySelector('.m-input-reference__input--reason-en').setAttribute('aria-invalid', true)
        return '参考理由(EN)を入力してください'
      } else {
        // NOTE：読み込み時に$elがまだ用意出来てないのでundefinedで判定
        if (this.$el !== undefined) this.$el.querySelector('.m-input-reference__input--url').removeAttribute('aria-invalid')
        return null
      }
    }
  },
  mounted() {
    this.referenceMovieData = { url: this.url, reasonJA: this.reasonJA, reasonEN: this.reasonEN }
  },
  methods: {
    validateURL() {
      if (this.isRequireUrl && this.isValidUrl) this.required = false
      this.required = !this.isRequireUrl
    },
    InputReferenceUrl(e) {
      // elmが判定対象（input系）の場合のみ処理が続行される
      this.referenceMovieData.url = e.target.value
      this.validateURL()
      this.$emit('inputReferenceMovie', {...this.referenceMovieData}, this.index)
      this.$emit('validation')
    },
    InputReferenceReasonJA(e) {
      this.referenceMovieData.reasonJA = e.target.value
      this.validateURL()
      this.$emit('inputReferenceMovie', {...this.referenceMovieData}, this.index)
      this.$emit('validation')
    },
    InputReferenceReasonEN(e) {
      this.referenceMovieData.reasonEN = e.target.value
      this.validateURL()
      this.$emit('inputReferenceMovie', {...this.referenceMovieData}, this.index)
      this.$emit('validation')
    }
  }
}
</script>

<style lang="scss" scoped>
.m-input-reference {
  &__input {
    width: 100%;
    &:not(.m-input-reference__input-url) {
      margin-top: 2px;
    }
    // 管理画面用
    &--reason-en {
      margin-left: 4px;
    }
  }
  // 参考理由
  &__reasons {
    &--separate {
      @include flex(between);
    }
  }
  // バリデーションメッセージ
  &__validation-message {
    @include flex;
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.2;
    color: var(--color-error);

    .crevo-icon {
      margin-right: 2px;
    }
  }
}
// アニメーション
.fade-enter-active, .fade-leave-active {
  transition: opacity .15s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
