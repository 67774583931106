export default {
  ja: {
    creatorAuditions: {
      workflowStates: {
        'draft': '作成中',
        'posted': '確認中',
        'checked': '開催中',
        'waiting_order': '選定中',
        'offering': 'オファー中',
        'ordered': 'PJ作成待ち',
        'in_production': '制作中',
        'finished': '終了',
      },
      auditionStatusText: {
        'canceled': 'キャンセル',
      },
      detail: {
        humanImage: 'クリエイターの選定ポイント',
        information: '仕事情報',
        serviceDescription: '仕事依頼',
        productionSchedule: '制作スケジュール',
        scopes: '仕事の範囲',
        referenceMovies: '参考動画',
        breakdown: '金額内訳',
        closed: '募集終了',
        clientComment: '担当者からのコメント',
        hasBeenSelected: 'が選出されました。',
        infoPanel: {
          otherConditions: '留意事項',
          toCreatorAttention: '■応募期限前でも、依頼者の都合により締め切ることがあります。',
        },
        infoTables: {
          shooting_location: '撮影場所',
          client_name: 'クライアント名',
          requested_movie_time_and_number: '動画の尺・本数',
          publishing_media_types: '配信媒体',
          delivery_of_goods: '納品形式',
          service_target: '動画を届けたい人・ターゲット'
        },
        jobParts: {
          client_support: 'クライアント対応',
          direction: '演出・コンテ制作',
          planning: '企画',
          scenario_writing: 'シナリオライティング',
          narration_script: 'ナレーション原稿',
          copywriting: 'コピーライティング',
          schedule_management: '制作進行',
          arrange_location: 'ロケーション手配',
          casting: 'キャスティング',
          make_up_artist: 'メイク',
          costume_cordinator: '衣装',
          art_staff: '美術',
          photography: '撮影(スチール)',
          film_shooting: '撮影(ムービー)',
          lighting_technician: '照明',
          film_editing: '編集',
          animation: 'アニメーション',
          animation_3d: '3DCG',
          graphic_design: 'グラフィックデザイン',
          illustration: 'イラスト・キャラ',
          sound_selection: '選曲・SE',
          sound_composition: '作曲',
          narration: 'ナレーション',
        },
        mediaTypes: {
          tv_cm: 'テレビCM',
          web_ad: 'Web広告',
          website: 'Webサイト',
          traffic: 'タクシー広告',
          event_signage: 'イベント・サイネージ',
          other: 'その他',
          corporate_site: 'HP・コーポレートサイト',
          service_site: 'サービスサイト・LP',
          facebook: 'Facebook',
          youtube: 'Youtube',
          twitter: 'Twitter',
          vimeo: 'Vimeo',
          appstore: 'Google Play・App Store',
          event: '展示会・イベント・セミナー',
          digital_signage: 'デジタルサイネージ',
          sales_material: '営業ツール・営業資料 ',
          newsletter: 'メルマガ ',
        }
      },
      forum: {
        postedHtml: '<b>%{name}</b>の投稿',
        repliedHtml: '<b>%{name}</b>の返信',
        adminText: '事務局からのお知らせ',
        labels:{
          owner: '依頼主',
          creator: 'クリエイター',
          admin: '事務局'
        },
        labelMyself: 'あなた',
        placeholderOperator: 'クリエイターへのお知らせやメッセージを入力してください。(絵文字不可)\n質問に対する返答は「返信」を押して投稿ください。\n1,000文字以内で入力してください。',
        placeholderCreator: '依頼主への質問やメッセージを入力してください。(絵文字不可)\n1,000文字以内で入力してください。',
        placeholderReply: '返信を入力してください。(絵文字不可)\n1,000文字以内で入力してください。',
        overCharactor: '1,000文字以内で入力してください。',
        post: '投稿する',
        openReply: '返信',
        cancel: 'キャンセル',
        reply: '返信する',
        confirmCancel: '入力内容は破棄されますがよろしいでしょうか？',
        confirmDelete: 'こちらの投稿を削除してもよろしいでしょうか？',
        noComments: '投稿はありません。',
        postFailed: '投稿に失敗しました。',
        replyFailed: '返信に失敗しました。',
        deletePostSuccess: '投稿を削除しました。',
        deletePostFailed: '投稿の削除に失敗しました。',
        deleteReplySuccess: '投稿を削除しました。',
        deleteReplyFailed: '投稿の削除に失敗しました。',
        confirm: 'このページから移動すると、入力している内容が破棄されますがよろしいですか'
      },
      card: {
        creatorCandidateStatuses: {
          offered: 'オファー',
          offer_accept: '採用',
          entry_cancel: '取り下げ',
        },
        invitationOnly: '招待された方限定の\n仕事依頼となります',
        unset: '未設定',
        entryCount: '%{count}人',
        proposalOfferPrice: '金額提案',
        recordANote: 'メモを記録する'
      },
    }
  },
  en: {
    creatorAuditions: {
      workflowStates: {
        'draft': 'Draft',
        'posted': 'Posted',
        'checked': 'Open',
        'waiting_order': 'Reviewing',
        'offering': 'Offering',
        'ordered': 'Selected',
        'in_production': 'In production',
        'finished': 'Closed',
      },
      auditionStatusText: {
        'canceled': 'Canceled',
      },
      detail: {
        humanImage: 'Selection criteria',
        information: 'Project summary',
        serviceDescription: 'Project details',
        productionSchedule: 'Production Schedule',
        scopes: 'Scope of work',
        referenceMovies: 'Reference videos',
        breakdown: 'Breakdown',
        closed: 'Closed',
        clientComment: 'Client\'s Comment',
        hasBeenSelected: 'has been selected.',
        infoPanel: {
          otherConditions: 'Notice',
          toCreatorAttention: 'Application may be closed before the deadline due to client\'s convenience.',
        },
        infoTables: {
          shooting_location: 'Shooting location',
          client_name: 'Client Information',
          requested_movie_time_and_number: 'Number/length of videos',
          publishing_media_types: 'Video usage',
          delivery_of_goods: 'Final delivery format',
          service_target: 'Target audience of video'
        },
        jobParts: {
          client_support: 'Communication with client',
          direction: 'Direction',
          planning: 'Planning',
          scenario_writing: 'Scenario Writing',
          narration_script: 'Narration Script',
          copywriting: 'Copywriting',
          schedule_management: 'Schedule Management',
          arrange_location: 'Arrange Location',
          casting: 'Casting',
          make_up_artist: 'Make-up',
          costume_cordinator: 'Costumes',
          art_staff: 'Scene design',
          photography: 'Photography',
          film_shooting: 'Film Shooting',
          lighting_technician: 'Lighting Technician',
          film_editing: 'Film Editing',
          animation: 'Animation',
          animation_3d: '3D Animation',
          graphic_design: 'Graphic Design',
          illustration: 'Illustration',
          sound_selection: 'Sound Selection',
          sound_composition: 'Sound Composition',
          narration: 'Narration'
        },
        mediaTypes: {
          tv_cm: 'TVCM',
          web_ad: 'Web Ad',
          website: 'Website',
          traffic: 'Taxi Ad',
          event_signage: 'Event, Signage',
          other: 'Other',
          corporate_site: 'Home page, corporate site',
          service_site: 'Service web page, landing page',
          facebook: 'Facebook',
          youtube: 'Youtube',
          twitter: 'Twitter',
          vimeo: 'Vimeo',
          appstore: 'Google Play・App Store',
          event: 'Events/seminars',
          digital_signage: 'Digital Signage',
          sales_material: 'Sales tool ',
          newsletter: 'E-Mail magazines'
        }
      },
      forum: {
        postedHtml: '<b>%{name}</b>\'s post',
        repliedHtml: '<b>%{name}</b>\'s reply',
        adminText: 'Notifications from VideoWorks',
        labels:{
          owner: 'Client',
          creator: 'Creator',
          admin: 'VideoWorks'
        },
        labelMyself: 'Your',
        placeholderOperator: 'Please type notifications or messages for creators. (No emoji allowed)\nClick "reply" button when you answer questions from creators.\nPlease enter up to 1,000 characters.',
        placeholderCreator: 'Please type questions or messages for the client. (No emoji allowed)\nPlease enter up to 1,000 characters.',
        placeholderReply: 'Please type reply messages. (No emoji allowed)\nPlease enter up to 1,000 characters.',
        overCharactor: 'Please enter up to 1,000 characters.',
        post: 'Post',
        openReply: 'Open Reply',
        cancel: 'Cancel',
        reply: 'Reply',
        confirmCancel: 'Contents will be discarded. Are you sure to continue?',
        confirmDelete: 'Contents will be deleted. Are you sure to continue?',
        noComments: 'No posted.',
        postFailed: 'Failed to post.',
        replyFailed: 'Failed to reply.',
        deletePostSuccess: 'The comment has been deleted.',
        deletePostFailed: 'Failed to delete comment.',
        deleteReplySuccess: 'The reply has been deleted.',
        deleteReplyFailed: 'Failed to delete reply.',
        confirm: 'Contents will be discarded if you leave this page. Are you sure to leave?'
      },
      card: {
        creatorCandidateStatuses: {
          offered: 'Offered',
          offer_accept: 'Accept',
          entry_cancel: 'Withdraw',
        },
        invitationOnly: 'Invited Only',
        unset: 'Unset',
        entryCount: '%{count}',
        proposalOfferPrice: 'Proposal Offer Price',
        recordANote: 'Record a note'
      },
    }
  }
}
