const state = {
  showing: false,
}

const getters = {
  showing(state) {
    return state.showing
  },
}

const mutations = {
  show(state) {
    state.showing = true
  },

  hide(state) {
    state.showing = false
  }
}

const actions = {
  show({ commit }) {
    commit('show')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
