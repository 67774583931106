<template lang="pug">
form.o-direct-creator-audition-form
  Card.o-direct-creator-audition-form__card
    .o-direct-creator-audition-form__body
      .o-direct-creator-audition-form__row
        .o-direct-creator-audition-form__title
          LabelText(text="依頼するクリエイター")
        UserProfileUnit(:user="creator" :withLink="true")
      .o-direct-creator-audition-form__row
        InputSet(title="仕事のタイトル")
          Input(required type="text" name="creator_audition[name]" :value="audition_name" @input="handleInputTitle" placeholder="動画制作プラットフォームVideoWorksの紹介動画を頼めるクリエイターを募集")
      .o-direct-creator-audition-form__row
        .o-direct-creator-audition-form__title
          LabelText(text="サムネイル")
        AttachedThumbnail(
          :thumbnailURL="icon_url"
          :thumbnailName="icon_name"
          :thumbnailFile="icon"
          @existingThumbnail="setIconName"
          @attachedThumbnail="setCustomIcon"
          @setThumbnailURL="setIconUrl"
          @setFlashMessage="setShowAttachedThumbnailMessage"
        )
      .o-direct-creator-audition-form__row
        InputSet(title="制作の依頼形式" disablePointerEvents)
          RadioSet(required :values="offerTypes" :checkedValue="offer_type" @change="onChangeOfferType")
      .o-direct-creator-audition-form__row
        InputSet(title="動画の種類" disablePointerEvents)
          RadioSet(required :values="filmGenres" :checkedValue="film_genre" @change="onChangeFilmGenre")
      .o-direct-creator-audition-form__row
        InputSet(title="仕事の範囲 (複数選択可)" disablePointerEvents)
          TagCheckboxList(required ref="jobPartIdentier" :name="jobPartIdentifier.name" :tags="jobPartIdentifier.tags" :checkedTags="job_part_identifiers" @validate="onValidateJobPartIdentifier" @change="onChangeJobPartIdentifier")
      .o-direct-creator-audition-form__row
        .o-direct-creator-audition-form__title
          LabelText(text="金額の決め方")

          RequiredIcon.m-input-set__required(:valid="isValidPrice")
        InputPrice(
          :suggestionPrices="suggestionPrices"
          :suggestionPriceValue="suggestionPriceValue"
          :offerFeePercentage="plan.audition_fee_percentage"
          :budgetTypes="budgetTypes"
          :budget_type="budget_type"
          @validation="handleValidation"
          @input="handleInputPrice"
          @change="onChangeSuggestionPrice"
          @setBudgetType="onChangeBudgetType"
          ref="priceSuggestion"
        )
      .o-direct-creator-audition-form__row
        .o-direct-creator-audition-form__title
          LabelText(text="納品希望日")
          RequiredIcon.m-input-set__required(:valid="deliveryDateInputed")
        .o-direct-creator-audition-form__inputs
          DatePicker.o-direct-creator-audition-form__datepicker(:date="requested_deliverly_date" :minDate="minDate" @change="onChangeRequestedDeliveryDate" required ref="requestedDeliveryDate")
          Checkbox.o-direct-creator-audition-form__checkbox(title="納品日の交渉可能" :checked="deliverly_date_negotiable" name="creator_audition[deliverly_date_negotiable]" @change="onChangeDeliveryDateNegotiable")
      .o-direct-creator-audition-form__row
        .o-direct-creator-audition-form__title
          LabelText.o-creator-audition-form__label(text="撮影場所")
          RequiredIcon.m-input-set__required(v-if="shootingLocationRequired" :valid="shootingLocationInputed")
        .o-direct-creator-audition-form__inputs
          Textarea(
            :required="shootingLocationRequired"
            :value="shooting_location"
            :rows="2"
            name="creator_audition[shootingLocation]"
            placeholder="例) 東京都目黒区"
            size="l"
            maxlength="1000"
            @input="handleInputShootingLocation"
          )
      .o-direct-creator-audition-form__row
        InputSet(title="案件詳細")
          Textarea.o-direct-creator-audition-form__textarea-description(
            :value="service_description"
            name="creator_audition[service_description]"
            :rows="11"
            placeholder=`例）VideoWorksのリリースにあたりサービス紹介動画を作成したいと考えております。\nイベントで使用する為、サービス概要と使い方をわかりやすく説明する動画にしたいです。\n\n素材・構成は弊社で準備いたしますのが、構成のアドバイスからグラフィック作成、アニメーションまでお願いできればと思います。実写パートもありますので弊社オフィス(中目黒)での撮影をお願いいたします。\n・撮影場所：弊社オフィス・中目黒\n・撮影時間：１日(ご相談)\n・キャスティング、スタジオの手配：不要(弊社社員と弊社オフィス)`
            @input="handleInputServiceDescription"
            maxlength="1000"
          )
      .o-direct-creator-audition-form__row
        InputSet(title="納品までの簡単な制作スケジュール")
          Textarea.o-direct-creator-audition-form__textarea-schedule(
            required
            :value="production_schedule"
            name="creator_audition[production_schedule]"
            :rows="5"
            placeholder=`3/8〜12 クリエイター選定〜確定\n3/15〜 ヒアリング・素材提供\n4/1 初稿確認\n4/30 納品`
            @input="handleInputProductionSchedule"
          )
      .o-direct-creator-audition-form__row
        InputSet(title="制作する動画の尺・本数")
          Input(required type="text" :value="requested_movie_time_and_number" name="creator_audition[requested_movie_time_and_number]" placeholder="例）動画3本。30秒 / 60秒 / 120秒" @input="handleInputRequestedMovieTimeAndNumber")
      .o-direct-creator-audition-form__row
        .o-direct-creator-audition-form__title
          LabelText(text="募集の締切日")
          RequiredIcon.m-input-set__required(:valid="closingOnDateAndTimeInputed")
        .o-direct-creator-audition-form__inputs--date-and-time
          DatePicker.o-direct-creator-audition-form__datepicker(:date="closing_on_date" :minDate="minDate" @change="onChangeClosingOnDate" required ref="closingOnDate")
          TimeSelect.o-direct-creator-audition-form__time-select(:value="closing_on_time" name="creator_audition[closing_on_time]" required :byMinute="30" @change="onChangeClosingOnTime" ref="closingOnTime")
        HelpText.o-direct-creator-audition-form__help(message="※募集期間は3日間以上を推奨")
      .o-direct-creator-audition-form__row
        InputSet(title="選定ポイント")
          Textarea.o-direct-creator-audition-form__textarea-point(
            required
            :value="human_image"
            name="creator_audition[human_image]"
            :rows="5"
            placeholder=`選定の際に重視する点・お願いやご相談事項をご記入ください。\n例) 参考動画のテイストアニメーションの実績がある方\n      インタビュー撮影経験が豊富な方`
            @input="handleInputHumanImage"
          )
      .o-direct-creator-audition-form__row
        InputSet(title="公開範囲" disablePointerEvents)
          RadioSet(required :values="filteredpublicationScope" :checkedValue="publication_scope" @change="onChangePublicationScope")
    .o-direct-creator-audition-form__footer
      Button.o-direct-creator-audition-form__button(size="l" @click="cancel") キャンセル
      Button.o-direct-creator-audition-form__button(size="l" color="black" :disabled="!isValid" @click="toConfirm") 確認画面へ
  FlashMessage(
    :isShow="flashMessage.isShow"
    :text="flashMessage.text"
    :withAlert="flashMessage.withAlert"
    @changeFlashMessageVisibility="setShowMessageflg"
  )
</template>

<script>
import dayjs from 'dayjs'
import Button from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card'
import FlashMessage from 'src/components/atoms/FlashMessage'
import Checkbox from 'src/components/atoms/Form/Checkbox'
import DatePicker from 'src/components/atoms/Form/DatePicker'
import Input from 'src/components/atoms/Form/Input'
import Textarea from 'src/components/atoms/Form/Textarea'
import TimeSelect from 'src/components/atoms/Form/TimeSelect'
import HelpText from 'src/components/atoms/HelpText'
import LabelText from 'src/components/atoms/LabelText'
import RequiredIcon from 'src/components/atoms/RequiredIcon'
import UserProfileUnit from 'src/components/molecules/CreatorAudition/UserProfileUnit'
import InputSet from 'src/components/molecules/InputSet'
import RadioSet from 'src/components/molecules/RadioSet'
import TagCheckboxList from 'src/components/molecules/TagCheckboxList'
import AttachedThumbnail from 'src/components/organisms/CreatorAudition/AttachedThumbnail'
import InputPrice from 'src/components/organisms/InputPrice'
import budgetTypes from 'src/lib/creatorAudition/budgetTypes'
import filmGenres from 'src/lib/creatorAudition/filmGenres'
import suggestionPrices from 'src/lib/creatorAudition/jobOfferPriceRanges'
import jobParts from 'src/lib/creatorAudition/jobParts'
import offerTypes from 'src/lib/creatorAudition/offerTypes'
import publicationScopes from 'src/lib/creatorAudition/publicationScopes'
import { mapState, mapMutations } from 'vuex'
import 'dayjs/locale/ja'

export default {
  name: 'DirectCreatorAuditionForm',
  components: {
    Button,
    Card,
    FlashMessage,
    Checkbox,
    DatePicker,
    HelpText,
    Input,
    InputPrice,
    InputSet,
    UserProfileUnit,
    LabelText,
    RadioSet,
    RequiredIcon,
    TagCheckboxList,
    AttachedThumbnail,
    Textarea,
    TimeSelect
  },
  props: {
    isValid: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      offerTypes,
      filmGenres,
      budgetTypes,
      suggestionPrices,
      minDate: dayjs().locale('ja').format('YYYY-MM-DD'),
      closingOnDateAndTimeInputed: false,
      deliveryDateInputed: false,
      shootingLocationInputed: false,
      flashMessage: {
        isShow: false,
        withAlert: false,
        text: null,
      },
    }
  },
  computed: {
    ...mapState('JobOffer', [
      'audition_name',
      'offer_type',
      'film_genre',
      'job_part_identifiers',
      'budget_type',
      'offer_price',
      'offer_price_range',
      'requested_deliverly_date',
      'deliverly_date_negotiable',
      'service_description',
      'production_schedule',
      'requested_movie_time_and_number',
      'closing_on_date',
      'closing_on_time',
      'human_image',
      'publication_scope',
      'icon',
      'icon_name',
      'icon_url',
      'shooting_location'
    ]),
    ...mapState('Plan', {plan: state => state}),
    ...mapState('User', {creator: state => state}),
    jobPartIdentifier() {
      const tags = jobParts.map(val => {
        return { id: val.id, name: this.$i18n.t(`creatorAuditions.detail.jobParts.${val.id}`) }
      })

      return { name: 'creator_audition[job_part_identifiers][]', tags: tags }
    },
    filteredpublicationScope() {
      return publicationScopes.filter(data => data.value !== 'private')
    },
    priceSuggestion() {
      return this.budget_type === 'proposal'
    },
    suggestionPriceValue() {
      if (this.priceSuggestion) {
        return this.offer_price_range || ''
      } else {
        return this.offer_price || 0
      }
    },
    isValidPrice() {
      if (this.budget_type === 'proposal') {
        return this.offer_price_range !== null && suggestionPrices.map(({ id }) => id).includes(Number(this.offer_price_range))
      } else {
        return this.offer_price !== null && this.offer_price >= 1 && this.offer_price <= 999999999
      }
    },
    shootingLocationRequired() {
      return this.job_part_identifiers.includes('photography') || this.job_part_identifiers.includes('film_shooting')
    },
  },
  methods: {
    ...mapMutations('JobOffer', [
      'setAuditionName',
      'setOfferType',
      'setFilmGenre',
      'setJobPartIdentifiers',
      'setBudgetType',
      'setOfferPrice',
      'setOfferPriceRange',
      'setRequestedDeliveryDate',
      'setDeliveryDateNegotiable',
      'setServiceDescription',
      'setProductionSchedule',
      'setRequestedMovieTimeAndNumber',
      'setClosingOnDate',
      'setClosingOnTime',
      'setHumanImage',
      'setPublicationScope',
      'setIconName',
      'setCustomIcon',
      'setIconUrl',
      'setShootingLocation',
    ]),
    handleValidation() {
      this.$emit('validation')
    },
    handleInputTitle(e) {
      this.setAuditionName(e.target.value)
      this.$emit('validation')
    },
    handleInputShootingLocation(e) {
      this.shootingLocationInputed = !!e.target.value
      this.setShootingLocation(e.target.value)
      this.$emit('validation')
    },
    onChangeBudgetType(type) {
      this.setBudgetType(type)
    },
    handleInputPrice({ payload }) {
      if (payload === '') {
        this.setOfferPrice('')
      } else {
        this.setOfferPrice(Number(payload.replace(/,/g, '')))
      }
      this.$emit('validation')
    },
    onChangeRequestedDeliveryDate(date) {
      this.setRequestedDeliveryDate(date)
      this.deliveryDateInputed = true
      this.$emit('validation')
    },
    handleInputServiceDescription(e) {
      this.setServiceDescription(e.target.value)
      this.$emit('validation')
    },
    handleInputProductionSchedule(e) {
      this.setProductionSchedule(e.target.value)
      this.$emit('validation')
    },
    handleInputRequestedMovieTimeAndNumber(e) {
      this.setRequestedMovieTimeAndNumber(e.target.value)
      this.$emit('validation')
    },
    onChangeClosingOnDate(date) {
      this.setClosingOnDate(date)
      if(this.closing_on_time !== '') this.closingOnDateAndTimeInputed = true
      this.$emit('validation')
    },
    onChangeClosingOnTime(value) {
      this.setClosingOnTime(value)
      if(this.closing_on_date !== '') this.closingOnDateAndTimeInputed = true
      this.$emit('validation')
    },
    handleInputHumanImage(e) {
      this.setHumanImage(e.target.value)
      this.$emit('validation')
    },
    onChangeOfferType(e) {
      this.setOfferType(e.target.value)
      this.$emit('validation')
    },
    onChangeFilmGenre(e) {
      this.setFilmGenre(e.target.value)
      this.$emit('validation')
    },
    onValidateJobPartIdentifier() {
      this.$emit('validation')
    },
    onChangeJobPartIdentifier() {
      const selectedJobPartIdentifiers = this.$refs.jobPartIdentier.$children.filter(jobPartIdentifier => jobPartIdentifier.active).map(jobPartIdentifier => jobPartIdentifier.tag.id)

      this.setJobPartIdentifiers(selectedJobPartIdentifiers)
      this.$emit('validation')
    },
    onChangeSuggestionPrice({ payload }) {
      if (payload === '') {
        // 「価格帯を選択」を選んだ場合
        this.setOfferPriceRange(null)
      } else {
        this.setOfferPriceRange(payload)
      }
    },
    onChangeDeliveryDateNegotiable(e) {
      this.setDeliveryDateNegotiable(e.target.checked)
      this.$emit('validation')
    },
    onChangePublicationScope(e) {
      this.setPublicationScope(e.target.value)
      this.$emit('validation')
    },
    cancel() {
      this.$emit('cancel')
    },
    toConfirm() {
      this.$emit('confirm')
    },
    setShowAttachedThumbnailMessage(text) {
      this.setFlashMessage(true, text, true)
    },
    setFlashMessage(isShow, text, withAlert = false) {
      this.flashMessage = { isShow, text, withAlert }
    },
    setShowMessageflg(isShow) {
      this.flashMessage.isShow = isShow
    },
  }
}
</script>

<style lang="scss" scoped>
.o-direct-creator-audition-form {
  &__card {
    @include mq-down(tablet--under) {
      padding: 0 20px;
      box-shadow: none;
    }
  }
  // FIXME: override bootstrap-wrapper.scss
  label {
    font-weight: normal;
  }

  &__body {
    width: 100%;
  }

  &__row {
    width: 100%;
    margin-bottom: 24px;
    @include mq-up(tablet) {
      &:last-of-type {
        margin-bottom: 40px;
      }
    }

    &--short {
      width: 50%;
    }
  }
  &__footer {
    text-align: center;
    @include mq-up(tablet) {
      @include flex(center);
    }
  }
  &__button {
    @include mq-up(tablet) {
      &:first-of-type {
        margin-right: 20px;
      }
    }
    @include mq-down(tablet--under) {
      width: 100%;
      &:first-of-type {
        margin-bottom: 15px;
      }
    }
  }
  &__datepicker {
    width: 180px;
  }
  &__time-select {
    width: 100px;
    margin-left: 10px;
  }
  &__checkbox {
    margin: 0 0 0 20px;
    @include mq-down(tablet--under) {
      margin: 12px 0 0 0;
    }
  }

  // NOTE：Inputsetが使用できない部分用
  &__title {
    @include flex(start, center);
    margin-bottom: 10px;
  }
  &__inputs {
    @include mq-up(tablet) {
      @include flex(start, center);
    }
    &--date-and-time {
      @include flex(start, center);
    }
  }
  &__help {
    margin-top: 8px;
    line-height: 1;
  }
}
</style>
