const state = {
  id: null,
  canManageCreatorList: false,
  canCreateCreatorAudition: false,
  canInviteCreatorCandidate: false,
  canShareCreatorProfile: false,
  showInterviewValuation: false,
  locale: null
}

const getters = {
  id(state) {
    return state.id
  },

  canManageCreatorList(state) {
    return state.canManageCreatorList
  },

  canCreateCreatorAudition(state) {
    return state.canCreateCreatorAudition
  },

  canInviteCreatorCandidate(state) {
    return state.canInviteCreatorCandidate
  },

  canShareCreatorProfile(state) {
    return state.canShareCreatorProfile
  },

  showInterviewValuation(state) {
    return state.showInterviewValuation
  },

  locale(state) {
    return state.locale
  }
}

const mutations = {
  set(state, params) {
    state.id = params.id
    state.canManageCreatorList = params.canManageCreatorList
    state.canCreateCreatorAudition = params.canCreateCreatorAudition
    state.canInviteCreatorCandidate = params.canInviteCreatorCandidate
    state.canShareCreatorProfile = params.canShareCreatorProfile
    state.showInterviewValuation = params.showInterviewValuation
    state.locale = params.locale
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
