<template lang="pug">
  .a-review-rate
    .a-review-rate__stars
      .a-review-rate__star.a-review-rate__star--result(:style="{ width: reviewRatePercentage }") ★★★★★
      .a-review-rate__star ★★★★★
    //- 評価点がある場合は、数値(0以上5以下)が表示される
    .a-review-rate__text(v-if="rate !== null") {{ formattedRate }}
    //- 評価点がない場合は `-` が表示される
    .a-review-rate__text(v-else) -
</template>

<script>
export default {
  name: 'ReviewRate',
  props: {
    rate: {
      type: Number,
      required: false,
      default: null
    },
  },
  computed: {
    reviewRatePercentage() {
      return `${this.rate / 5 * 100}%`
    },
    formattedRate() {
      if (this.rate === null) return
      return this.rate.toFixed(2)
    },
  },
}
</script>

<style lang="scss" scoped>
.a-review-rate {
  @include flex;
  &__stars {
    position: relative;
    width: 70px;
    height: 14px;
  }
  // 星部分
  &__star {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    line-height: 14px;
    color: var(--color-mono-5);
    &--result {
      z-index: 1;
      overflow: hidden;
      color: var(--color-rate);
    }
  }
  &__text {
    margin: 1px 0 0 12px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.1;
    color: var(--color-mono-2);
  }
}
</style>
