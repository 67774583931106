<template lang='pug'>
  .t-single-column(:class="[headerStyleClass]")
    .t-single-column__header
      slot(name="header" )
    .t-single-column__main
      slot(name="main")
</template>

<script>
  export default {
    name: 'SingleColumn',
    props: {
      hasHeaderBorder: {
        type: Boolean,
        required: false,
        default: true
      },
      wideHeader: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    computed: {
      headerStyleClass(){
        return `${this.hasHeaderBorder ? '' : 't-single-column--without-header-border'} ${this.wideHeader ? 't-single-column--wide-header' : ''}`
      }
    }
  }
</script>
<style lang="scss">
  //stylesheets/collet/components/template/_single_column.scssを参照
</style>
