import axiosForPresignedUrl from '@axios'
import dayjs from 'dayjs'
import { serialize } from 'object-to-formdata'
import { getClientOfNotConvert } from 'src/lib/axios'
import { set } from 'vue'
const axios = getClientOfNotConvert()

export default {
  namespaced: true,
  state: {
    // room search
    roomId: null,
    creators: [],
    organizations: [],
    messageRooms: [],
    searchRoomName: null,

    // right side bar
    creatorAuditions: [],
    creator: {},
    works: [],

    // invitation creator auditions
    openedCreatorAuditions: [],
    invitationCreatorId: null,

    // messages
    messages:[],
    participantUsers: [],
    readMessageOldestCreatedAt: null,
    isLoadingMessages: true,

     // new message
    isCreatingMessage: false,
    newMessageText: '',
    mentionedUsers: [],
    isFileUploading: false,
    showUploadFileModal: false,
    newFileId: null,
    newFileName: null,
    newFileDataUrl: null,
    newFileMetadata: null,
    isCreatingRoom: false,

    // edit message
    isUpdatingMessage: false,

    // delete message
    isShowDeleteModal: false,
    deleteMessageId: null,
    isDeletingMessage: false,
    isDeleteFileOnly: false,

    // error
    messageRoomError: null,
  },
  getters: {
    activeRoom(state) {
      return state.messageRooms.find(room => room.id === state.roomId)
    },
    mentionedUserIds(state) {
      return state.mentionedUsers.map(user => user.id)
    },
    messageRoomsIds(state) {
      return state.messageRooms.map(room => room.id)
    },
    organizationIds(state) {
      return state.messageRooms.map(room => room.organization_id)
    },
    messageRoomsWithDetail(state) {
      if (!state.messageRooms.length) return []
      return state.messageRooms.map(room =>{
        const creator = state.creators.find(creator => creator.auth0_id === room.creator_auth_0_id)
        const organization = state.organizations.find(organization => String(organization.id) === room.organization_id)

        if(creator) {
          return {
            ...room,
            name: creator.full_name,
            creatorIcon: creator.user_icon,
          }
        } else if(organization) {
          return {
            ...room,
            name: organization.name,
            organizationIcon: organization.custom_icon_url?.url,
            organizationCanceled: organization.canceling_date,
          }
        }
      })
      .filter(room => room != null)
      .sort((a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? 1 : -1))
    },
    searchedRooms(state, getters) {
      if(state.searchRoomName === null || state.searchRoomName === '') return getters.messageRoomsWithDetail

      const regex = new RegExp(state.searchRoomName)
      return getters.messageRoomsWithDetail.filter(room => regex.test(room.name))
    },
    unreadMessagesAllCount(state) {
      let count = 0
      state.messageRooms.forEach(room => count += room.unread_messages)
      return count
    },
    unreadMentionedMessagesAllCount(state) {
      let count = 0
      state.messageRooms.forEach(room => count += room.unread_mentioned_messages)
      return count
    },
    visibleMessages(state, _getters, rootState) {
      if (state.messages.length === 0 || state.participantUsers.length === 0) return []
      return [...state.messages].filter(message => { // NOTE: 削除済みのメッセージを除外
        return !message.deleted_at
      }).map(message => { // NOTE: 表示に必要なプロパティを追加
        const user = state.participantUsers.find(user => user.auth0_id === message.auth_0_id)
        const mentionedUsers = state.participantUsers.filter(user => message.mentioned_auth_0_ids.includes(user.auth0_id))
        return {
          ...message,
          user,
          isMyPost: message.auth_0_id === rootState.Profile.profile.auth0_id,
          isLeaved: !!user?.unregistered_at,
          mentionedUsers,
        }
      })
    },
    mentionableUsers(state, _getters, rootState) {
      return state.participantUsers.filter(user => {
        return user.auth0_id !== rootState.Profile.profile.auth0_id && user?.unregistered_at == null
      })
    },
    isOrganizationCanceled(state, getters) {
      return !!getters.searchedRooms.find(room => room.id === state.roomId)?.organizationCanceled
    },
  },
  actions: {
    async createMessageFromWebSocket({state, commit, dispatch}, messageData) {
      if (state.roomId !== messageData.room_id) {
        dispatch('fetchMessageRooms')
        return
      }
      let file
      if (messageData.file_id) {
        const { data: fileData } = await dispatch('fetchMessageFile', messageData.file_id)
        file = fileData
      }

      // NOTE: 新しいメッセージをstoreに反映
      const user = state.participantUsers.find(user => user.auth0_id === messageData.auth_0_id)
      const newMessage = {
        ...messageData,
        file,
        user,
        isMyPost: true,
        isLeaved: !user,
        mentionedUsers: state.mentionedUsers,
      }
      commit('addMessage', newMessage)
      commit('setLastMessage', {
        roomId: newMessage.room_id,
        lastMessage: newMessage.updated_at,
        updated_at: newMessage.updated_at
      })
    },
    updateMessageFromWebSocket({state, commit}, messageData) {
      const message = state.messages.find(message => message.id === messageData.id)
      if (!message) return

      const file = messageData.file_id ? message.file : null
      commit('setMessageItem', {
        messageId: message.id,
        params: {
          ...message,
          ...messageData,
          file
        }
      })
    },
    async goToMessageRoom({dispatch}, {creatorId, roomPath}) {
      if (roomPath) window.location.href = roomPath
      try {
        const { data } = await dispatch('createMessageRoom', creatorId)
        window.location.href = `/message_rooms/${data.message_room.id}`
      } catch(error) {
        return Promise.reject(error)
      }
    },
    async createMessageRoom({ commit }, creatorId) {
      await commit('setIsCreatingRoom', true)
      try {
        const response = await axios.post('/api/v2/message_rooms', { creator_id: creatorId })
        return Promise.resolve(response)
      } catch (error) {
        commit('setError', error)
        return Promise.reject(error)
      } finally {
        await commit('setIsCreatingRoom', false)
      }
    },
    async fetchMessageRooms({ commit }) {
      try {
        const {data} = await axios.get('/api/v2/message_rooms')
        commit('setMessageRooms', data.message_rooms)
        return Promise.resolve
      } catch (error) {
        commit('setError', error)
        return Promise.reject(error)
      }
    },
    async fetchOrganizations({ commit }, organizationIds) {
      const {data} = await axios.get('/api/v2/message_rooms/organizations', {
        params: {
          organization_ids: organizationIds
        }
      })
      commit('setOrganizations', data.organizations)
    },
    async fetchCreators({ commit }) {
      const {data} = await axios.get('/api/v2/message_rooms/creators')
      commit('setCreators', data.creators)
    },
    async fetchCreator({ commit, state }) {
      const {data} = await axios.get(`/api/v2/message_rooms/${state.roomId}/creator`)
      if (data.review_point_average) data.review_point_average = Number(data.review_point_average)
      commit('setCreator', data)
    },
    async fetchCreatorAuditions({ commit, state }) {
      const {data} = await axios.get(`/api/v2/message_rooms/${state.roomId}/creator_auditions`)
      commit('setCreatorAuditions', data.creator_auditions)
    },
    async fetchWorks({ commit, state }) {
      const {data} = await axios.get(`/api/v2/message_rooms/${state.roomId}/works`)
      commit('setWorks', data.works)
    },
    async fetchMentionUsers({ commit, getters, rootState }) {
      const userIds =  [...getters.activeRoom.participant_auth_0_ids, rootState.Profile.profile.auth0_id]
      const {data} = await axios.get('/api/v2/auth0_users', { params: { auth0_ids: userIds } })
      commit('setMentionUsers', data.auth0_users)
    },
    async fetchMessages({ commit, state }, loadState) {
      const {data} = await axios.get(`/api/v2/message_rooms/${state.roomId}/messages`, {
        params: {
          limit: 20,
          latest: state.readMessageOldestCreatedAt, // NOTE: 新しいメッセージから古いメッセージを20件ずつ取得する
        }
      })

      commit('setMessagesOldestCreatedAt', data.oldest)

      if (data.data.length > 0) {
        // NOTE: データ取得前にルームを変更している場合には messages を反映しないようにする
        if (data.data[0].room_id != state.roomId) return
        commit('setMessages', [...data.data.reverse(), ...state.messages]) // NOTE: 新しく取得したメッセージを古い順に並び替えて追加
        loadState.loaded()
      } else {
        loadState.complete()
      }
    },
    addMention({ state, getters, commit }, userId) {
      const user = getters.mentionableUsers.find(user =>  user.id === parseInt(userId, 10))
      if (getters.mentionedUserIds.includes(userId)) return
      commit('setMention', [...state.mentionedUsers, user])
    },
    removeMention({ state, commit }, userId) {
      const newUsers = state.mentionedUsers.filter(user => user.id !== userId)
      commit('setMention', newUsers)
    },
    async createMessage({ commit, dispatch }, ) {
      commit('setIsCreatingMessage', true)

      try {
        // NOTE: メッセージ投稿
        await dispatch('postMessage' )
        commit('clearMessage')
        commit('setShowUploadFileModal', false)
        return Promise.resolve()
      } catch(error) {
        console.log({error})
        return Promise.reject()
      } finally {
        commit('setIsCreatingMessage', false)
      }
    },
    async postMessage({ state, getters }) {
        const baseParams = {
          message: state.newMessageText,
          mentioned_user_ids: getters.mentionedUserIds,
        }
        const params = state.newFileId ? { ...baseParams, file_id: state.newFileId, metadata: state.newFileMetadata } : baseParams
        return axios.post(`/api/v2/message_rooms/${state.roomId}/messages`, params)
    },
    async fetchMessageFile({ state }, fileId) {
      return axios.get(`/api/v2/message_rooms/${state.roomId}/files/${fileId}`)
    },
    async uploadFile({ state, commit, dispatch }, file) {
      commit('setNewFileName', file.name)
      commit('setIsFileUploading', true)
      commit('setShowUploadFileModal', true)

      try {
        const presign = await axiosForPresignedUrl.get('/api/v2/message_rooms/s3/params')
        const data = serialize({...presign.data.fields, file: file})
        const headers = {'content-type': 'multipart/form-data'}
        await axiosForPresignedUrl.post(presign.data.url, data, { headers })
        const fileId = presign.data.fields.key.match(/cache\/message_rooms\/(.+)/)[1]
        commit('setNewFileId', fileId)
        commit('setNewFileMetadata', {
            filename: state.newFileName,
            size: file.size,
            mime_type: file.type
        })

        await dispatch('loadFilePreview', file)
      }  catch(error) {
        return Promise.reject(error)
      } finally {
        commit('setIsFileUploading', false)
      }
    },
    async loadFilePreview({ commit }, file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      // NOTE: onloadイベントをPromiseでラップして、load処理を確実に完了させてから後続の処理を行う
      await new Promise(resolve => reader.onload = () => resolve())
      commit('setNewFileDataUrl', reader.result)
    },
    startEditMessage({ commit, getters }, messageId) {
      // NOTE: 編集キャンセル時に元のtextとmentionedUserに戻す必要があるためこれらの値を編集用のプロパティにコピーしている
      const editMessage = getters.visibleMessages.find(message => message.id === messageId)
      if (!editMessage) return

      commit('setMessageItem', {
        messageId,
        params: {
          editMessageText: editMessage.text,
          editMentionedUsers: editMessage.mentionedUsers,
        }
      })
    },
    cancelEditMessage({ commit }, messageId) {
      commit('setMessageItem', {
        messageId,
        params: {
          editMessageText: '',
          editMentionedUsers: []
        }
      })
    },
    addMentionForEditMessage({ commit, state, getters }, { messageId, userId }) {
      const editMessage = state.messages.find(message => message.id === messageId)
      const user = getters.mentionableUsers.find(user => user.id === parseInt(userId, 10))
      if (!editMessage) return

      commit('setMessageItem', {
        messageId,
        params: {
          editMentionedUsers: [...editMessage.editMentionedUsers, user],
        }
      })
    },
    removeMentionForEditMessage({ commit, state }, { messageId, userId }) {
      const editMessage = state.messages.find(message => message.id === messageId)
      const editUsers = editMessage.editMentionedUsers.filter(user => user.id !== userId)
      if (!editMessage) return

      commit('setMessageItem', {
        messageId,
        params: {
          editMentionedUsers: editUsers,
        }
      })
    },
    inputMessageForEditMessage({ commit }, { messageId, text }) {
      commit('setMessageItem', {
        messageId,
        params: {
          editMessageText: text
        }
      })
    },
    async updateMessage({ commit, dispatch, state }, messageId) {
      commit('setIsUpdgatingMessage', true)

      try {
        // NOTE: メッセージ更新
        const { data: messageData }  = await dispatch('patchMessage', messageId)

        // NOTE: 新しいメッセージをDOMに反映(自分のメッセージはWebSocketでは返却されない)
        const newMessages = state.messages.map(message => {
          if (message.id === messageData.id) {
            return {
              ...message,
              ...messageData,
              editMessageText: '',
              editMentionedUsers: [],
            }
          }
          return message
        })
        commit('setMessages', newMessages)
        return Promise.resolve()
      } catch(error) {
        return Promise.reject()
      } finally {
        commit('setIsUpdgatingMessage', false)
      }
    },
    async patchMessage({ state }, messageId) {
      const message = state.messages.find(message => message.id === messageId)
      const userIds = message.editMentionedUsers.map(user => user.id)
      const params = {
        message: message.editMessageText,
        mentioned_user_ids: userIds,
        message_type: message.message_type,
        file_id: message.file_id,
      }
      return axios.patch(`/api/v2/message_rooms/${state.roomId}/messages/${messageId}`, params)
    },
    // NOTE: アップロードファイルを削除する場合にメッセージ本体のファイル情報(file_id, message_type)も更新する
    async patchMessageWithDeletFile({ state }, messageId) {
      const message = state.messages.find(message => message.id === messageId)
      const params = {
        message: message.text,
        // NOTE: ファイルだけ削除する場合はメンションは変更されないので、
        //       message.editMentionedUsersは、undefinedなので使用しない
        mentioned_auth0_ids: message.mentioned_auth_0_ids,
        message_type: 'text',
        file_id: null,
      }
      return axios.patch(`/api/v2/message_rooms/${state.roomId}/messages/${messageId}`, params)
    },
    showDeleteModal({ commit }, messageId) {
      commit('setDeleteModal', { messageId, isShowing: true, isDeleteFileOnly: false })
    },
    showDeleteFileModal({ commit }, messageId) {
      commit('setDeleteModal', { messageId, isShowing: true, isDeleteFileOnly: true })
    },
    cancelDelete({ commit }) {
      commit('setDeleteModal', { messageId: null, isShowing: false, isDeleteFileOnly: false })
    },
    async deleteMessage({ commit, state, dispatch }, messageId) {
      const message = state.messages.find(message => message.id === messageId)
      try {
        if (message.message_type === 'file' && message.file) {
          await dispatch('deleteFile', message.file.id)
          // NOTE: メッセージ本文が空でファイルしかないメッセージの削除をする場合は、メッセージは更新しない
          if (message.text) await dispatch('patchMessageWithDeletFile', messageId)
        }

        const { data: messageData } = await axios.delete(`/api/v2/message_rooms/${state.roomId}/messages/${messageId}`)

        const newMessages = state.messages.map(message => {
          if (message.id === messageData.id) {
            return {
              ...message,
              ...messageData, // NOTE: レスポンスのメッセージ(messageData)はdeleted_atに値が入っているため既存のメッセージを上書きすることで一覧から非表示にできる
            }
          }
          return message
        })
        commit('setMessages', newMessages)
        return Promise.resolve()
      } catch {
        return Promise.reject()
      } finally {
        commit('setDeleteModal', { messageId: null, isShowing: false, isDeleteFileOnly: false })
      }
    },
    async deleteOnlyFileInMessage({ state, commit, dispatch }, messageId) {
      const message = state.messages.find(message => message.id === messageId)
      try {
        await dispatch('deleteFile', message.file.id)
        await dispatch('patchMessageWithDeletFile', messageId)
        const newMessages = state.messages.map(message => {
          if (message.id === messageId) {
            return {
              ...message,
              file: null
            }
          }
          return message
        })
        commit('setMessages', newMessages)
        return Promise.resolve()
      } catch {
        return Promise.reject()
      } finally {
        commit('setDeleteModal', { messageId: null, isShowing: false, isDeleteFileOnly: false })
      }
    },
    async clearUnreadMessage({ commit }, roomId) {
      try {
        await axios.put(`/api/v2/message_rooms/${roomId}/read_status`)
        commit('setUnreadMessage', {
          id: roomId,
          unread_messages: 0,
          unread_mentioned_messages:0
        })
      } catch {
        return Promise.reject()
      }
    },
    async deleteFile({ state }, fileId) {
      try {
        await axios.delete(`/api/v2/message_rooms/${state.roomId}/files/${fileId}`)
        return Promise.resolve()
      } catch {
        return Promise.reject()
      }
    },
    async fetchOpenedCreatorAuditions({ commit }) {
      try {
        const { data }  = await axios.get('/api/creators/creator_auditions')
        commit('setOpenedCreatorAuditions', data.creator_auditions)
      } catch (error) {
        commit('setError', error)
        return Promise.reject(error)
      }
    },
    async inviteJobOffer({commit, state}, jobOfferId) {
      try {
        await axios.post(`/api/creators/creator_auditions/${jobOfferId}/creator_candidates`, {
          creator_user_id: state.invitationCreatorId
        })
      } catch(error) {
        commit('setError', error)
        return Promise.reject(error)
      }
    }
  },
  mutations: {
    setRoomId(state, roomId) {
      global.activeRoomId = roomId
      state.roomId = roomId
    },
    setMessageRooms(state, messageRooms) {
      state.messageRooms.splice(0)
      state.messageRooms.push(...messageRooms)
    },
    setMessages(state, messages) {
      state.messages = messages
    },
    clearMessages(state) {
      state.messages = []
      state.participantUsers = []
      state.readMessageOldestCreatedAt = null
    },
    setIsLoadingMessages(state, bool) {
      state.isLoadingMessages = bool
    },
    setMessagesOldestCreatedAt(state, dateTime) {
      state.readMessageOldestCreatedAt = dateTime
    },
    setOrganizations(state, organizations) {
      state.organizations.splice(0)
      state.organizations.push(...organizations)
    },
    setCreators(state, creators) {
      state.creators.splice(0)
      state.creators.push(...creators)
    },
    setCreator(state, creator) {
      state.creator = creator
    },
    setSearchRoomName(state, name) {
      state.searchRoomName = name
    },
    setCreatorAuditions(state, creatorAuditions) {
      state.creatorAuditions.splice(0)
      state.creatorAuditions.push(...creatorAuditions)
    },
    setWorks(state, works) {
      state.works.splice(0)
      state.works.push(...works)
    },
    setOpenedCreatorAuditions(state, creatorAuditions) {
      creatorAuditions.forEach((data, index) => {
        set(state.openedCreatorAuditions, index, data)
      })
    },
    setMentionUsers(state, participantUsers) {
      state.participantUsers.splice(0)
      state.participantUsers.push(...participantUsers)
    },
    setError(state, error) {
      if (error) state.messageRoomError = error
    },
    setIsCreatingRoom(state, flg) {
        state.isCreatingRoom = flg
    },
    setIsCreatingMessage(state, bool) {
      state.isCreatingMessage = bool
    },
    setMention(state, users) {
      state.mentionedUsers = users
    },
    setMessage(state, message) {
      state.newMessageText = message
    },
    addMessage(state, message) {
      state.messages.push(message)
    },
    setUnreadMessage(state, {id, unread_messages, unread_mentioned_messages}) {
      const index = state.messageRooms.findIndex(room => room.id === id)
      if (index >= 0) {
        state.messageRooms[index].unread_messages = unread_messages
        state.messageRooms[index].unread_mentioned_messages = unread_mentioned_messages
      }
    },
    clearMessage(state) {
      state.newMessageText = ''
      state.mentionedUsers.splice(0)
      state.newFileId = null
      state.newFileName = null
      state.newFileDataUrl = null
      state.newFileMetadata = null
    },
    clearFileMeta(state) {
      state.newFileId = null
      state.newFileName = null
      state.newFileDataUrl = null
      state.newFileMetadata = null
    },
    setNewFileId(state, id) {
      state.newFileId = id
    },
    setNewFileName(state, fileName) {
      state.newFileName = fileName
    },
    setNewFileDataUrl(state, url) {
      state.newFileDataUrl = url
    },
    setNewFileMetadata(state, metadata) {
      state.newFileMetadata = metadata
    },
    setIsFileUploading(state, bool) {
      state.IsFileUploading = bool
    },
    setShowUploadFileModal(state, bool) {
      state.showUploadFileModal = bool
    },
    setMessageItem(state, { messageId, params }) {
      const index = state.messages.findIndex(message => message.id === messageId)

      if (index === -1) return
      state.messages.splice(index, 1, {
        ...state.messages[index],
        ...params
      })
    },
    setIsUpdgatingMessage(state, bool) {
      state.isUpdatingMessage = bool
    },
    setDeleteModal(state, {  messageId, isShowing, isDeleteFileOnly }) {
      state.deleteMessageId = messageId
      state.isShowDeleteModal = isShowing
      state.isDeleteFileOnly = isDeleteFileOnly
    },
    setInvitationCreatorId(state, id) {
      state.invitationCreatorId = id
    },
    setLastMessage(state, {roomId, lastMessage, updated_at}) {
      const index = state.messageRooms.findIndex(room => room.id === roomId)
      if (index >= 0) {
        state.messageRooms[index].last_message = lastMessage
        state.messageRooms[index].updated_at = updated_at
      }
    },
  }
}
