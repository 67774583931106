<template lang='pug'>
input.a-input(
              :class='sizeClass'
              :type='type'
              :value='value'
              :name='name'
              :placeholder='placeholderText'
              :required='required'
              :min='min'
              :max='max'
              :minlength='minlength'
              :maxlength='maxlength'
              :autocomplete='autocomplete'
              :disabled='disabled'
              :readonly='readonly'
              :pattern='pattern'
              @focus="onFocus"
              @blur="onBlur"
              @input='onInput'
              @change='onChange')
</template>

<script>
export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
      validator: (value) => {
        return ['text', 'tel', 'number', 'url', 'email', 'password', 'date', 'month'].includes(value)
      }
    },

    value: {
      type: [String, Number],
      required: false,
      default: null
    },

    name: {
      type: String,
      required: false,
      default: null
    },

    placeholder: {
      type: String,
      required: false,
      default: null
    },

    required: {
      type: Boolean,
      required: false,
      default: false
    },

    min: {
      type: [String, Number],
      required: false,
      default: null
    },

    max: {
      type: [String, Number],
      required: false,
      default: null
    },

    minlength: {
      type: [String, Number],
      required: false,
      default: 0
    },

    maxlength: {
      type: [String, Number],
      required: false,
      default: 255
    },

    autocomplete: {
      type: String,
      required: false,
      default: 'off'
    },

    disabled: {
      type: Boolean,
      required: false,
      default: null
    },

    readonly: {
      type: Boolean,
      required: false,
      default: null
    },

    pattern: {
      type: String,
      required: false,
      default: null
    },

    validationTiming: {
      type: [String, Boolean],
      required: false,
      default: 'input',
      validator: (value) => {
        return ['change', 'input', true, false].includes(value)
      }
    },

    size: {
      type: String,
      required: false,
      default: 'm',
      validator: (value) => {
        return ['s', 'm', 'l'].includes(value)
      }
    }
  },
  computed: {
    sizeClass(){
      return `a-input--${this.size}`
    },
    placeholderText(){
      return this.placeholder ? this.placeholder : this.required ? this.$t('atoms.input.placeholder') : ''
    }
  },
  methods: {
    checkValidity(){
      this.$el.removeAttribute('aria-invalid')
      if(!this.$el.checkValidity()){
        this.$el.setAttribute('aria-invalid', 'true')
        return false
      } else {
        return true
      }
    },
    validationMessage(){
      return this.$el.validationMessage
    },
    onFocus(e) {
      this.$emit('focus', e)
    },
    onBlur(e) {
      this.$emit('blur', e)
      if(this.validationTiming){
        this.checkValidity()
      }
    },
    onInput(e) {
      this.$emit('input', e)
      if(this.validationTiming === 'input'){
        this.checkValidity()
      }
    },
    onChange(e) {
      this.$emit('change', e)
      if(this.validationTiming === 'change' || this.validationTiming === true){
        this.checkValidity()
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  // stylesheets/collet/components/atoms/_input.scssを参照
</style>
