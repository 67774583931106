export default [
  {
    name: 'creator_audition[publicationScope]',
    value: 'public',
    title: '公開',
    description: 'クリエイターには、すべて公開されます。他の導入企業へは、貴社名が伏せられて公開されます。'
  },
  {
    name: 'creator_audition[publicationScope]',
    value: 'published_only_overview',
    title: '詳細非公開',
    description: 'クリエイターには、すべて公開されます。他の導入企業へは、貴社名、詳細情報を除き公開されます。'
  },
  {
    name: 'creator_audition[publicationScope]',
    value: 'private',
    title: '非公開',
    description: 'クリエイターには、すべて公開されます。他の導入企業へは、非公開になります。'
  }
]
