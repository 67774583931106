<template lang='pug'>
  .m-image-unit
    button.m-image-unit__clickable-area(@click="openModal" :style="{ width: thumbnailWidth }" v-if="thumbnailURL")
      i.m-image-unit__preview-icon.crevo-icon.crevo-search(v-if="disableSearchIcon")
      img.m-image-unit__thumbnail(:src="thumbnailURL")
    template(v-else)
      .m-image-unit__thumbnail.m-image-unit__thumbnail--no-image(:style="{ width: thumbnailWidth }")
        i.crevo-icon.crevo-job
    transition(name="fade")
      .m-image-unit__modal(v-if="modalShow")
        //- NOTE：clickaway使っており領域外クリックでModal消えるためアイコンのみ
        i.crevo-icon.crevo-close
        .m-image-unit__preview(v-on-clickaway="closeModal")
          .m-image-unit__inner
            object.m-image-unit__data.m-image-unit__data--document(:data="fileURL" v-if="isDocument")
            img.m-image-unit__data.m-image-unit__data--image(:src="fileURL" v-else :style="{'height': modalHeight}")
            .m-image-unit__attr(v-if="enableDownloadLink")
              | {{fileName}} ({{fileSize}})
              .m-image-unit__link(@click="download")
                i.crevo-icon.crevo-download
                .m-image-unit__download ダウンロード
</template>

<script>
import saveDownloadFile from 'src/lib/saveDownloadFile'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'ImageUnit',
  mixins: [clickaway],
  props: {
    thumbnailWidth: {
      type: String,
      required: false,
      default: '100%',
    },
    thumbnailURL: {
      type: String,
      required: false,
      default: null,
    },
    fileName: {
      type: String,
      required: false,
      default: null,
    },
    fileSize: {
      type: String,
      required: false,
      default: null,
    },
    fileURL: {
      type: String,
      required: false,
      default: null,
    },
    fileType: {
      type: String,
      required: true
    },
    disableSearchIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    enableDownloadLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modalShow: false,
      modalHeight: 'auto'
    }
  },
  computed: {
    isDocument() {
      return this.fileType === 'document'
    },
  },
  methods: {
    keyAction(e) {
      // NOTE：モーダル開いている時にescでモーダル閉じる
      if (e.key === 'Escape') this.modalShow = false
    },
    download() {
      saveDownloadFile(this.fileURL, this.fileName)
    },
    openModal() {
      this.modalShow = true
      window.addEventListener('keydown', this.keyAction)

      const image = new Image()
      image.src = this.fileURL
      image.onload = () => {
        if (image.width < image.height) this.modalHeight = `${window.innerHeight * 0.7}px`
      }
    },
    closeModal() {
      this.modalShow = false
      window.removeEventListener('keydown', this.keyAction)
    }
  },
}
</script>
<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .m-image-unit {
    &__clickable-area {
      position: relative;
      height: 100%;
      transition: .2s;
      &:active {
        opacity: .8;
      }
      @include mq-up(narrow-pc) {
        &:hover {
          opacity: .8;
        }
      }
    }
    &__preview-icon {
      @include flex(center);
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 50px;
      height: 50px;
      font-size: 20px;
      color: var(--color-mono-2);
      text-indent: 1px;
      background: var(--color-mono-c);
      border-radius: 100%;
      transform: translate(-50%, -50%);
    }
    &__thumbnail {
      @include flex(center);
      width: 100%;
      aspect-ratio: 16 / 9;
      border-radius: 5px;
      object-fit: cover;
      &--no-image {
        background: var(--color-mono-6);
        .crevo-job {
          font-size: 40px;
          color: var(--color-mono-c);
        }
      }
    }
    // モーダル
    &__modal {
      @include flex(center);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10000;
      width: 100%;
      height: 100%;
      background-color: rgba(17, 17, 17, .9);
      border: 0;
      border-radius: 0;

      .crevo-close {
        @include flex(center);
        position: absolute;
        top: 30px;
        right: 30px;
        width: 50px;
        height: 50px;
        font-size: 16px;
        color: var(--color-mono-9);
        cursor: pointer;
        background: var(--color-mono-2);
        border-radius: 100%;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, .15);
        transition: .2s;
        &:active {
          opacity: .8;
        }
        @include mq-up(narrow-pc) {
          &:hover {
            opacity: .8;
          }
        }

        @include mq-down(tablet--under) {
          top: 10px;
          right: 10px;
        }
      }
    }
    &__preview {
      @include flex(center);
      width: 84vw;
      max-width: 1068px;
    }
    &__attr {
      margin-top: 12px;
      font-size: 14px;
      color: var(--color-mono-5);

      @include mq-up(tablet) {
        @include flex;
        gap: 8px;
      }
    }
    &__link {
      @include flex;
      flex-shrink: 0;
      gap: 4px;
      cursor: pointer;

      @include mq-down(tablet--under) {
        margin-top: 8px;
      }
    }
    &__download {
      text-decoration: underline;

      &:active {
        text-decoration: none;
      }

      @include mq-up(tablet) {
        &:hover {
          text-decoration: none;
        }
      }
    }
    &__data {
      height: 100%;
      @include mq-down(tablet--under) {
        width: 100%;
        height: auto;
      }
      &--image {
        @include mq-up(tablet) {
          max-width: 56.25vw;
        }
      }
      &--document {
        width: 100%;
        height: 600px;

        @include mq-up(tablet) {
          min-width: 80vw;
        }

        @include mq-down(tablet--under) {
          width: 100vw;
          height: 70vh;
        }
      }
    }
  }
</style>

<style lang="scss">
/*
  Note：以下理由により、.modal-backdropはdisplay:none;としている。
  そのため、vue-thin-modal を直接利用するなど、
  本コンポーネントを使わない場合は別途利用箇所で.modal-backdropをdisplay: block;で定義しておく必要がある
  - デザイン上バツがつくのと背景色がthin-modalと異なる、clickawayで背景タップで閉じたい
  - 既存のbootstrapのCSSとぶつかる可能性がある
*/
.modal-backdrop {
  display: none;
}
</style>
