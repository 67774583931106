export default [
  {
    name: 'creator_audition[filmGenre]',
    value: 'animation',
    title: 'アニメーション',
    description: '様々なテイストのクリエイターから選択できます。'
  },
  {
    name: 'creator_audition[filmGenre]',
    value: 'live_action',
    title: '実写',
    description: 'ロケハンから撮影／編集まで範囲も自由に選べます。'
  },
  {
    name: 'creator_audition[filmGenre]',
    value: 'undecided',
    title: '表現自由',
    description: '表現方法をお任せしたい方、決まっていない方。'
  },
]
