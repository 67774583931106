<template lang="pug">
  Card.o-creator-audition-form
    Title(title="作りたい動画の詳細内容")
    .o-creator-audition-form__content
      .o-creator-audition-form__item
        InputSet(title="尺・本数")
          Input(required :name="inputJaName({name: 'requestedMovieTimeNumber'})" :value="requestedMovieTimeNumber" :placeholder="placeholderRequestedMovieTimeNumberJA" size="l" @input="handleInputRequestedMovieTimeNumber")
      .o-creator-audition-form__item(v-if="isEN")
        InputSet(title="【英訳】尺・本数")
          Input(required :name="inputEnName({name: 'requestedMovieTimeNumber'})" :value="requestedMovieTimeNumberEN" placeholder="英語の尺・本数" size="l" @input="handleInputRequestedMovieTimeNumberEN")
      .o-creator-audition-form__item
        InputSet(title="依頼詳細")
          Textarea(
            :name="inputJaName({name: 'serviceDescription'})"
            :value="serviceDescription"
            :placeholder="placeholderServiceDescriptionJA"
            size="l"
            @input="handleInputServiceDescription"
            :rows="11"
            maxlength="1000"
          )
      .o-creator-audition-form__item(v-if="isEN")
        InputSet(title="【英訳】依頼詳細")
          Textarea(
            :name="inputEnName({name: 'serviceDescription'})"
            :value="serviceDescriptionEN"
            placeholder="英語の依頼詳細"
            size="l"
            @input="handleInputServiceDescriptionEN"
            :rows="11"
            maxlength="1000"
          )
      .o-creator-audition-form__item
        InputReferenceMovies(
          :referenceMovies="referenceMovies"
          :withEN="isEN"
          @inputReferenceMovies="inputReferenceMovies"
        )
      .o-creator-audition-form__item.o-creator-audition-form__item--margin
        InputSet(title="納品までの簡単な制作スケジュール")
          Textarea(
            :name="inputJaName({name: 'productionSchedule'})"
            :value="productionSchedule"
            :placeholder="placeholderProductionScheduleJA"
            size="l"
            @input="handleInputProductionSchedule"
            :rows="5"
            maxlength="1000"
          )
      .o-creator-audition-form__item.o-creator-audition-form__item--margin(v-if="isEN")
        inputSet(title="【英訳】納品までの簡単な制作スケジュール")
          Textarea(
            :name="inputEnName({name: 'productionSchedule'})"
            :value="productionScheduleEN"
            placeholder="英語のスケジュール"
            size="l"
            @input="handleInputProductionScheduleEN"
            :rows="5"
            maxlength="1000"
          )
      button.o-creator-audition-form__accordion(type="button" @click="toggleModeDetails" :class="{ 'o-creator-audition-form__accordion--open': isOpen }")
        i.crevo-icon.crevo-triangle_bottom_fill
        span {{ accordionText }}
      transition(name="slide-fade")
        div.o-creator-audition-form__toggle(v-if="isOpen")
          .o-creator-audition-form__item
            InputSet(title="クライアント名")
              Input(:name="inputJaName({name: 'clientName'})" :value="clientName" :placeholder="placeholderClientNameJA" size="l" @input="handleInputClientName")
          .o-creator-audition-form__item(v-if="isEN")
            InputSet(title="【英訳】クライアント名")
              Input(:name="inputEnName({name: 'clientName'})" :value="clientNameEN" placeholder="英語のクライアント名" size="l" @input="handleInputClientNameEN")
          .o-creator-audition-form__item
            InputSet(title="受注ステータス")
              Select(:options="accuracyOfContracts" size="l" @change="handleChangeOfferStatus" :selected="accuracyOfContract")
          .o-creator-audition-form__item
            InputSet(title="配信媒体")
              div
                TagCheckboxList(ref="publishingMediaType" name="creator_audition[publishingMediaTypes][]" :tags="mediaTypeData" :checkedTags="publishingMediaTypes" @change="onChangePublishingMediaType")
                transition(name="fade")
                  .o-creator-audition-form__publishing-media(v-if="selectedOtherMediaType")
                    Input(:name="inputJaName({name: 'publishingMedia'})" :value="publishingMedia" placeholder="その他配信媒体" size="l" @input="handleInputPublishingMedia")
                    Input(:name="inputEnName({name: 'publishingMedia'})" :value="publishingMediaEN" placeholder="英語のその他配信媒体" size="l" @input="handleInputPublishingMediaEN" v-if="isEN")
          .o-creator-audition-form__item
            InputSet(title="動画を届けたい人・ターゲット")
              Textarea(
                :name="inputJaName({name: 'serviceTarget'})"
                :value="serviceTarget"
                :placeholder="placeholderServiceTargetJA"
                size="l"
                @input="handleInputServiceTarget"
                :rows="5"
                maxlength="1000"
              )
          .o-creator-audition-form__item(v-if="isEN")
            InputSet(title="【英訳】動画を届けたい人・ターゲット")
              Textarea(
                :name="inputEnName({name: 'serviceTarget'})"
                :value="serviceTargetEN"
                placeholder="英語の動画を届けたい人・ターゲット"
                size="l"
                @input="handleInputServiceTargetEN"
                :rows="5"
                maxlength="1000"
              )
</template>

<script>
import Card from 'src/components/atoms/Card'
import Input from 'src/components/atoms/Form/Input'
import Select from 'src/components/atoms/Form/Select'
import Textarea from 'src/components/atoms/Form/Textarea'
import Title from 'src/components/atoms/Title'
import InputSet from 'src/components/molecules/InputSet'
import TagCheckboxList from 'src/components/molecules/TagCheckboxList'
import InputReferenceMovies from 'src/components/organisms/CreatorAudition/InputReferenceMovies'
import accuracyOfContracts from 'src/lib/creatorAudition/accuracyOfContracts'
import { inputJaName,  inputEnName } from 'src/lib/creatorAudition/i18nInput'
import mediaTypes from 'src/lib/creatorAudition/mediaTypes'

export default {
  name: 'AuditionDetailForm',
  components: {
    Card,
    Input,
    Select,
    Textarea,
    Title,
    InputSet,
    TagCheckboxList,
    InputReferenceMovies,
  },
  props: {
    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    },
    isEN: {
      type: Boolean,
      required: false,
      default: false
    },
    requestedMovieTimeNumber: {
      type: String,
      required: false,
      default: null,
    },
    requestedMovieTimeNumberEN: {
      type: String,
      required: false,
      default: null,
    },
    serviceDescription: {
      type: String,
      required: false,
      default: null
    },
    serviceDescriptionEN: {
      type: String,
      required: false,
      default: null
    },
    referenceMovies: {
      type: Array,
      required: false,
      default: () => []
    },
    productionSchedule: {
      type: String,
      required: false,
      default: null,
    },
    productionScheduleEN: {
      type: String,
      required: false,
      default: null,
    },
    clientName: {
      type: String,
      required: false,
      default: null
    },
    clientNameEN: {
      type: String,
      required: false,
      default: null
    },
    accuracyOfContract: {
      type: String,
      required: false,
      default: null
    },
    publishingMediaTypes: {
      type: Array,
      required: false,
      default: () => []
    },
    serviceTarget: {
      type: String,
      required: false,
      default: null
    },
    serviceTargetEN: {
      type: String,
      required: false,
      default: null
    },
    publishingMedia: {
      type: String,
      required: false,
      default: null
    },
    publishingMediaEN: {
      type: String,
      required: false,
      default: null
    },
  },
  data() {
    return {
      isOpen: false,
      selectedOtherMediaType: false,
      accuracyOfContracts,
      placeholderRequestedMovieTimeNumberJA: '例）動画3本。30秒 / 60秒 / 120秒',
      placeholderServiceDescriptionJA: '例）VideoWorksのリリースにあたりサービス紹介動画を作成したいと考えております。\nイベントで使用する為、サービス概要と使い方をわかりやすく説明する動画にしたいです。\n\n素材・構成は弊社で準備いたしますのが、構成のアドバイスからグラフィック作成、アニメーションまでお願いできればと思います。実写パートもありますので弊社オフィス(中目黒)での撮影をお願いいたします。\n・撮影場所：弊社オフィス・中目黒\n・撮影時間：１日(ご相談)\n・キャスティング、スタジオの手配：不要(弊社社員と弊社オフィス)',
      placeholderProductionScheduleJA: '例) 3/8〜12 クリエイター選定〜確定\n3/15〜 ヒアリング・素材提供\n4/1 初稿確認\n4/30 納品',
      placeholderClientNameJA: '例）○○株式会社',
      placeholderServiceTargetJA: '例）30〜40代、大企業のサービス責任者'
    }
  },
  computed: {
    accordionText() {
      return this.isOpen ?  '追加の詳細情報を隠す' : '追加の詳細情報を表示'
    },
    mediaTypeData() {
      const filterdMediaTypes = mediaTypes.filter(data => { return !data.isOld })
      return filterdMediaTypes.map(data => {
        return {
          id: data.id,
          name: this.$t(`creatorAuditions.detail.mediaTypes.${data.id}`)
        }
      })
    },
  },
  mounted() {
    // NOTE：アコーディオン内のコンテンツのvalueがどれかしら中身入っていたらアコーディオンは表示しておく
    if (
      this.clientName !== null ||
      this.accuracyOfContract !== null ||
      this.publishingMediaTypes.length > 0 ||
      this.serviceTarget !== null
    ) {
      this.isOpen = true
      this.visiblePublishingMediaInput(this.publishingMediaTypes)
    }
  },
  methods: {
    inputJaName({ name }) {
      return inputJaName({ name, isAdmin: this.isAdmin })
    },
    inputEnName({ name }) {
      return inputEnName({ name })
    },
    handleInputRequestedMovieTimeNumber(e) {
      this.$emit('input', { type: 'requestedMovieTimeNumber', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputRequestedMovieTimeNumberEN(e) {
      this.$emit('input', { type: 'requestedMovieTimeNumberEN', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputServiceDescription(e) {
      this.$emit('input', { type: 'serviceDescription', payload: e.target.value })
    },
    handleInputServiceDescriptionEN(e) {
      this.$emit('input', { type: 'serviceDescriptionEN', payload: e.target.value })
    },
    inputReferenceMovies(data) {
      this.$emit('inputReferenceMovies', data)
    },
    handleInputProductionSchedule(e) {
      this.$emit('input', { type: 'productionSchedule', payload: e.target.value })
    },
    handleInputProductionScheduleEN(e) {
      this.$emit('input', { type: 'productionScheduleEN', payload: e.target.value })
    },
    handleInputClientName(e) {
      this.$emit('input', { type: 'clientName', payload: e.target.value })
    },
    handleInputClientNameEN(e) {
      this.$emit('input', { type: 'clientNameEN', payload: e.target.value })
    },
    handleChangeOfferStatus(e) {
      this.$emit('change', { type: 'accuracyOfContract', payload: e.target.value })
    },
    onChangePublishingMediaType() {
      const selectedMediaTypes = this.$refs.publishingMediaType.$children.filter(mediaType => mediaType.active).map(mediaType => mediaType.tag.id)

      this.visiblePublishingMediaInput(selectedMediaTypes)
      this.$emit('change', { type: 'publishingMediaType', payload: selectedMediaTypes })
    },
    visiblePublishingMediaInput(data) {
      // NOTE：その他を選択した場合にのみ自由入力欄を表示
      this.selectedOtherMediaType = data.includes('other')
      // NOTE：その他チェックを外した際に、入力欄の値を空にする
      if (!data.includes('other')) {
        this.$emit('input', { type: 'publishingMedia', payload: null })
        if (this.isEN) { this.$emit('input', { type: 'publishingMediaEN', payload: null }) }
      }
    },
    handleInputServiceTarget(e) {
      this.$emit('input', { type: 'serviceTarget', payload: e.target.value })
    },
    handleInputServiceTargetEN(e) {
      this.$emit('input', { type: 'serviceTargetEN', payload: e.target.value })
    },
    toggleModeDetails() {
      this.isOpen = !this.isOpen
    },
    handleInputPublishingMedia(e) {
      this.$emit('input', { type: 'publishingMedia', payload: e.target.value })
    },
    handleInputPublishingMediaEN(e) {
      this.$emit('input', { type: 'publishingMediaEN', payload: e.target.value })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styles/creator_auditions/components/organisms/creator-audition-edit-form';

// NOTE：以下はここでしか使用しないのでscoped内に直接定義
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: .3s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
</style>
