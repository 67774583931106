<template lang='pug'>
.a-date-picker(
  :class="datePickerStyle"
  :required="required"
)
  v-date-picker(v-model="selectedDate" :min-date="minDate" :max-date="maxDate")
    template(slot='header-title' slot-scope='props') {{props.yearLabel}}年{{props.monthLabel}}
    .a-date-picker__text(:class="{'a-date-picker__text--placeholder': this.selectedDate === ''}") {{ formatDate(date) }}
  button.a-date-picker__clear(@click="clearDate" v-if="!required && !disabled && this.selectedDate !== ''")
    i.crevo-icon.crevo-close
</template>

<script>
import dayjs from 'dayjs'
// NOTE：使用しているバージョンが2020年9月現在で最新のDatepickerを使用するとimport部分でエラーが発生してしまうため、一旦1.0.0-beta.22を使用
import VDatePicker from 'v-calendar/lib/components/date-picker.umd'
import 'dayjs/locale/ja'

export default {
  name: 'Datepicker',
  components: { VDatePicker },
  props: {
    date: {
      type: [String, Number, Date],
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: '2020年01月01日 (水)'
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    minDate: {
      type: String,
      required: false,
      default: null
    },
    maxDate: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: 'm',
      validator: (value) => {
        return ['s', 'm'].includes(value)
      }
    },
    shortDate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      selectedDate: this.date,
      checkError: false,
    }
  },
  computed: {
    datePickerStyle() {
      return `${this.datePickerSize} ${this.datePickerDisabled}`
    },
    datePickerSize() {
      return `a-date-picker--${this.size}`
    },
    datePickerDisabled() {
      if (this.disabled) return 'a-date-picker--disabled'
      else return null
    }
  },
  watch: {
    date(newVal) {
      this.selectedDate = newVal
      if (this.selectedDate === '' && this.required) this.isError = true
    }
  },
  methods: {
    formatDate() {
      if(this.disabled) this.selectedDate = ''
      if (this.selectedDate === '') return this.placeholder
      this.$emit('change', this.selectedDate)
      if (this.shortDate) {
        return dayjs(this.selectedDate).locale('ja').format('YYYY.MM.DD')
      } else {
        return dayjs(this.selectedDate).locale('ja').format('YYYY年MM月DD日 (dd)')
      }
    },
    clearDate() {
      this.selectedDate = ''
      this.$emit('change', this.selectedDate)
    },
  }
}
</script>

<style lang='scss' scoped>
// FIXME：inputだとs~lがあるが、今の所他のサイズ使う予定ないので一旦固定にする
.a-date-picker {
  // NOTE：デザイン上だとxボタンが無いので200pxだがそれだと幅ギリギリなので最短220pxを指定するようにする
  @include flex(between, center);
  border: solid 1px var(--color-mono-5);
  transition: .2s;

  &--m {
    height: 46px;
    padding: 0 13px 0 18px;
    border-radius: 3px;

    .a-date-picker__text {
      height: 44px;
    }
  }

  &--s {
    height: 36px;
    padding: 0 8px 0 12px;
    border-radius: 5px;

    .a-date-picker__text {
      height: 36px;
    }
  }

  &--disabled {
    background: var(--color-mono-7);
    border-color: var(--color-mono-7);
    .a-date-picker__text {
      color: var(--color-mono-4);
      cursor: not-allowed;
    }
  }
  &--error {
    color: var(--color-red);
    border-color: var(--color-red);
  }
  > span {
    display: block;
    width: 100%;
  }
  &__text {
    @include flex(start, center);
    width: 100%;
    height: 44px;
    padding-right: 4px;
    font-size: 14px;
    &--placeholder {
      color: var(--color-mono-5);
    }
  }
  &__clear {
    width: 24px;
    height: 24px;
    color: var(--color-mono-4);
    border: 0;
    border-radius: 100%;
    outline: 0;
    transition: .2s;
    &:hover {
      opacity: .8;
    }
    .crevo-icon {
      @include flex(center, center);
      font-size: 10px;
    }
  }
}
</style>

<style lang="scss">
// Custom v-date-picker
.vc-bg-blue-600 {
  background: var(--color-red);
}
</style>
