import jobParts from 'src/lib/creatorAudition/jobParts'

export default {
  computed: {
    jobPartsIdentifiers() {
      // NOTE: 用意された仕事の範囲に合致する項目のみを返す
      return jobParts.map(val => {
        const isActive = this.job_part_identifiers.includes(val.id)

        return { id: val.id, text: this.$i18n.t(`creatorAuditions.detail.jobParts.${val.id}`), active: isActive }
      })
    }
  }
}
