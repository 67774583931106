import axios from '@axios'

const state = {
  creator: null
}

const getters = {
  creator(state) {
    return state.creator
  }
}

const actions = {
  append({ commit }, params) {
    axios.post(`/api/favorites/creator_lists/${params.creatorListId}/picked_creators`, { creator_id: params.creatorId }).then(() => {
      commit('creatorLists/append', { creatorListId: params.creatorListId, creatorId: params.creatorId }, { root: true })
    })
  },

  remove({ commit }, params) {
    axios.delete(`/api/favorites/creator_lists/${params.creatorListId}/picked_creators/${params.creatorId}`).then(() => {
      commit('creatorLists/remove', { creatorListId: params.creatorListId, creatorId: params.creatorId }, { root: true })
    })
  }
}

const mutations = {
  set(state, creator) {
    state.creator = creator
  },

  unset(state) {
    state.creator = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
