<template lang="pug">
.creator-point
  .creator-point__img
    .creator-point__empty-star
      |★★★★★
    .creator-point__filled-star(:style="reviewPointStarStyle")
      |★★★★★
  .creator-point__text
    | {{ reviewPointAverage }}
</template>

<script>
export default {
  name: 'CreatorPoint',

  props: {
    reviewPointAverage: {
      type: String,
      required: true
    }
  },

  computed: {
    reviewPointStarStyle() {
      const point = this.reviewPointAverage

      // NOTE pointは「0~5」なので、100%にするために「x 20」する
      const width = point === '-' ? 0 : point * 20
      return { width: `${width}%` }
    },
  }
}
</script>

<style lang="scss" scoped>
.creator-point {
  display: flex;
  align-items: center;

  &__img {
    font-size: 14px;
    position: relative;
    display: inline-block;
  }

  &__empty-star {
    color: var(--color-mono-6);
  }

  &__filled-star {
    color: var(--color-rate);
    position: absolute;
    top: 0;
    overflow: hidden;
    white-space: nowrap;
  }

  &__text {
    font-size: 12px;
    margin-left: 6px;
  }
}
</style>
