import Airbrake from 'airbrake-js'

let airbrake

// NOTE：開発環境はAirbrakeを無効にしているが、それだとaxios.js内のPromise.rejectがundefinedで返ってくるので検証する際はif内のdevelopmentを外す
if (process.env.RAILS_ENV !== 'development' && process.env.RAILS_ENV !== 'test') {

  airbrake = new Airbrake({
    projectId: process.env.AIRBRAKE_PROJECT_ID + 'disable',
    // remove disable string to enable airbrake
    projectKey: process.env.AIRBRAKE_PROJECT_KEY + 'disable',
    environment: process.env.RAILS_ENV
  })

  airbrake.addFilter(notice => {
    notice.context.environment = process.env.RAILS_ENV
    notice.errors = notice.errors.filter(error => error.message !== 'Network Error')

    return notice
  })
} else {
  airbrake = {
    notify: (arg) => {
      console.log(arg)
    }
  }
}

export default airbrake
