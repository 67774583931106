<template lang="pug">
  .p-creator-audition-form
    PageHead(
      title="このクリエイターに仕事依頼"
      text="依頼したい仕事について教えてください。"
      note="※公開は平日10〜19時です。それ以外は翌営業日以降になります。"
    )
    transition(name="fade")
      .p-creator-audition-form__content
        .p-creator-audition-form__item
          DirectCreatorAuditionForm(ref="directJobOfferform" @validation="onValidation" @cancel="cancel" @confirm="toConfirm" :isValid="isValid")
</template>

<script>
import Button from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card'
import FormDescription from 'src/components/molecules/FormDescription'
import PageHead from 'src/components/molecules/PageHead'
import DirectCreatorAuditionForm from 'src/components/organisms/CreatorAudition/DirectCreatorAuditionForm'
import FormTemplate from 'src/components/templates/FormTemplate'
import { mapState } from 'vuex'

export default {
  name: 'JobOfferNew',
  components: {
    FormTemplate,
    FormDescription,
    PageHead,
    Card,
    Button,
    DirectCreatorAuditionForm
  },
  data() {
    return {
      isValid: true
    }
  },
  computed: {
    ...mapState('JobOffer', {jobOfferId: 'id'})
  },
  mounted () {
    this.onValidation()
  },
  methods: {
    onValidation() {
      if (
        this.$refs.directJobOfferform &&
        this.$refs.directJobOfferform.$refs.requestedDeliveryDate.selectedDate !== ''
        && this.$refs.directJobOfferform.$refs.closingOnDate.selectedDate !== ''
        && this.$refs.directJobOfferform.$refs.closingOnTime.selectedTime !== ''
        && this.$refs.directJobOfferform.$el.checkValidity()
      ) {
        this.isValid = true
      } else {
        this.isValid = false
      }
    },
    cancel() {
      history.back()
    },
    toConfirm() {
      this.$router.push({
        name: 'confirm'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~styles/creator_auditions/creator-audition-edit-form.scss';
.o-direct-creator-audition-form__footer {
  text-align: center;

  &__button {
    margin-right: 20px;
  }
}
</style>
