import axios from 'axios'
import airbrake from 'src/lib/airbrake'

if (process.env.NODE_ENV !== 'test') {
  axios.defaults.headers['X-CSRF-TOKEN'] = document.querySelector('[name="csrf-token"]').getAttribute('content')
  airbrake && axios.interceptors.response.use(
    response => response,
    async error => {
      if (/^Error: Network Error/.test(error)) {
        return Promise.reject(error)
      }
      if (500 <= error.response.status && error.response.status < 600) {
        return Promise.reject(error)
      }
      airbrake.notify(error)
    })
}

export default axios
