<template lang='pug'>
  table.m-information-table
    tr(v-for="info in information")
      th.m-information-table__head {{ info.head }}
      td.m-information-table__description {{ info.description }}
</template>

<script>
export default {
  name: 'InfoTable',
  props: {
    information: {
      type: Array,
      required: true
    },
  },
}
</script>

<style lang='scss' scoped>
  .m-information-table {
    width: 100%;
    border-collapse: separate;
    border: 1px solid var(--color-mono-7);
    border-radius: 3px;
    &__head, &__description {
      padding: 14px 18px;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.6;
      color: var(--color-mono-1);
      text-align: left;
      @media (max-width: $break-point--tablet--under) {
        display: block;
        padding: 9px 16px;
        margin-top: -1px;
      }
    }
    &__head {
      width: 180px;
      background: var(--color-mono-b);
      border-top: 1px solid var(--color-mono-7);
      @media (min-width: $break-point--tablet) {
        border-right: 1px solid var(--color-mono-7);
      }
      @media (max-width: $break-point--tablet--under) {
        width: 100%;
      }
    }
    &__description {
      white-space: pre-wrap;
      border-top: 1px solid var(--color-mono-7);
    }
    tr{
      &:first-child {
        @media (min-width: $break-point--tablet) {
          .m-information-table__head, .m-information-table__description {
            border-top: 0px;
          }
        }
      }
    }
  }
</style>
