<template lang="pug">
transition(name="fade" appear)
  .m-custom-modal
    .m-custom-modal__background(@click="closeModal")
    i.crevo-icon.crevo-close(v-if="canClose" @click="closeModal")
    .m-custom-modal__content(
      :class="{ 'm-custom-modal__content--margin-none': marginNone }"
      :style="{ maxWidth: customizedWidth }"
    )
      .m-custom-modal__heading(v-if="title")
        p.m-custom-modal__title {{ title }}
      .m-custom-modal__body
        slot(name="body")
      .m-custom-modal__footer
        slot(name="footer")
</template>
<script>
export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    canClose: {
      type: Boolean,
      required: false,
      default: true
    },
    customizedWidth: {
      type: String,
      required: false,
      default: '480px'
    },
    marginNone: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    closeModal() {
      if(this.canClose) this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.m-custom-modal {
  @include flex(center);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;

  .crevo-close {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 16px;
    color: var(--color-mono-9);
    cursor: pointer;
    transition: .2s;
    &:hover {
      opacity: .8;
    }
    @include mq-down(tablet--under) {
      top: 10px;
      right: 10px;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, .9);
  }

  &__content {
    z-index: 10001;
    display: grid;
    grid-template:
      "heading heading heading" auto
      ". . ."      40px
      ". body ."   auto
      ". . ."      36px
      ". footer ." auto
      ". . ."      40px
      /20px auto 20px;
    width: 100%;
    background-color: var(--color-mono-c);
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    @include mq-down(tablet--under) {
      grid-template:
        "heading heading heading" auto
        ". . ."      30px
        ". body ."   auto
        ". . ."      16px
        ". footer ." auto
        ". . ."      30px
        /15px auto 15px;
      width: calc(100vw - 40px);
    }

    &--margin-none {
      grid-template:
        "heading heading heading" auto
        ". body ."   auto
        ". footer ." auto
        /0 auto 0;

      @include mq-down(tablet--under) {
        grid-template:
          "heading heading heading" auto
          ". body ."   auto
          ". footer ." auto
          /0 auto 0;
      }
    }
  }

  &__heading {
    @include flex(center);
    grid-area: heading;
    height: 50px;
    padding: 0 20px;
    overflow: hidden;
    font-size: 14px;
    background: var(--color-mono-a);
    border-bottom: 1px solid var(--color-mono-7);
    border-radius: 5px 5px 0 0;
  }

  &__title {
    width: 100%;
    overflow: hidden;
    line-height: 1.2; // 1.1だと微妙に縦中央にならないので1.2
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__body {
    grid-area: body;
    font-size: 14px;
    text-align: center;
  }

  &__footer {
    grid-area: footer;
    text-align: center;
  }
}
</style>
