<template lang='pug'>
  li.basecamp-search__condition-item
    label.basecamp-search__condition-item__label
      input.basecamp-search__condition-item__input(type="checkbox" :value="item[0]" v-model='checked')
      .basecamp-search__condition-item__label-text {{ item[1] }}
</template>

<script>
import { mapMutations } from 'vuex'
const camelCase = require('camelcase')

export default {
  props: {
    formKey: {
      type: String,
      required: true
    },
    item: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      checked: false
    }
  },

  computed: {
    formData() {
      return this.$store.getters[`form/${camelCase(this.formKey)}`]
    }
  },

  mounted() {
    this.restore()
  },

  methods: {
    ...mapMutations('form', ['setCheckItems']),

    restore() {
      this.checked = this.formData.some((formItem) => {
        if (isNaN(this.item[0])) {
          return formItem === this.item[0]
        } else {
          return Number(formItem) === this.item[0]
        }
      })
    },

    uncheck() {
      this.checked = false
    }
  }
}
</script>

<style lang='scss' scoped>
  .basecamp-search {
    &__condition-item {
      font-size: 14px;

      &__label {
        display: flex;
        font-weight: normal;
        margin: 0;
        margin-right: 20px;
        cursor: pointer;
      }

      &__label-text {
        display: block;
      }

      &__input {
        margin-right: 5px;
      }
    }
  }
</style>
