<template lang="pug">
  button(:type="type" :class="buttonStyle" :disabled="isDisabled" @click="onClick" v-if="href === ''")
    slot {{ title }}
  a(:href="href" :class="buttonStyle" :disabled="isDisabled" :target="target" @click="onClick" v-else)
    slot {{ title }}
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'button',
      required: false
    },
    href: {
      type: String,
      default: '',
      required: false
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: false,
      default: 'm'
    },
    color: {
      type: String,
      required: false,
      default: 'normal'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledWithClick: {
      type: Boolean,
      required: false,
      default: false
    },
    target: {
      type: String,
      required: false,
      default: '_self'
    }
  },
  data() {
    return {
      disabledWith: false
    }
  },
  computed: {
    buttonStyle() {
      return `a-button ${this.buttonColor} ${this.buttonSize} ${this.buttonDisabled}`
    },
    buttonSize() {
      const sizes = ['s', 'm', 'l']

      return sizes.includes(this.size) ? `a-button--${this.size}` : ''
    },
    buttonColor() {
      const colors = ['special', 'primary', 'black', 'border', 'normal', 'blue', 'blue-border', 'warning']

      return colors.includes(this.color) ? `a-button--${this.color}` : ''
    },
    buttonDisabled() {
      return this.disabled ? 'a-button--disabled' : ''
    },
    isDisabled() {
      return (this.disabled || this.disabledWith)
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click', e)

      if(this.disabledWithClick) {
        this.disabledWith = true
        setTimeout(() => {
          this.disabledWith = false
        }, 3000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// stylesheets/collet/components/atoms/_button.scssを参照
</style>
