<template lang="pug">
  Card.o-confirmation-submit
    ul.o-confirmation-submit__notes(v-if="notes.length > 0")
      li.o-confirmation-submit__note(v-for="(note, index) in notes" :key="`confirmationNote${index}`")
        i.crevo-icon.crevo-exclamation_fill
        span.o-confirmation-submit__text {{ note }}
    .o-confirmation-submit__buttons
      Button.o-confirmation-submit__button.o-confirmation-back__button(:title="back" @click="backToForm" color="normal" size="l")
      Button.o-confirmation-submit__button.o-confirmation-apply__button.track_creator_audition_apply(:title="apply" @click="applyCreatorAudition" color="primary" :disabled="sending" size="l")
</template>

<script>
import Button from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card'

export default {
  name: 'ConfirmationSubmit',
  components: {
    Button,
    Card,
  },
  props: {
    notes: {
      type: Array,
      required: false,
      default: () => [
        '公開後は情報や金額の変更はできませんので、あらかじめご了承ください。',
        '仕事依頼の応募締め切り後、2ヶ月経過するとキャンセル扱いとなります。',
        '仕事依頼の公開は平日10〜19時です。それ以外は翌営業日以降の公開になります。'
      ]
    },
    sending: {
      type: Boolean,
      required: false,
      default: false,
    },
    back: {
      type: String,
      required: false,
      default: 'もどる',
    },
    apply: {
      type: String,
      required: false,
      default: '依頼を申請',
    },
  },
  methods: {
    backToForm() {
      this.$emit('cancel')
    },
    applyCreatorAudition() {
      this.$emit('submit')
    }
  }
}
</script>

<style lang="scss" scoped>
.o-confirmation-submit {
  @include mq-down(narrow-pc--under) {
    padding: 0;
    box-shadow: none;
  }
  &__bottom {
    padding: 60px 20px 50px;
  }
  &__notes {
    margin-bottom: 26px;
  }
  &__note {
    @include flex(start, start);
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.4;
    &:last-of-type {
      margin-bottom: 0;
    }
    .crevo-icon {
      align-self: flex-start;
      padding-top: 1px;
      margin-right: 10px;
      color: var(--color-red);
    }
  }
  &__buttons {
    @include mq-up(tablet) {
      @include flex(center);
    }
  }
  &__button {
    width: 210px;
    @include mq-down(tablet--under) {
      width: 100%;
    }
    &:first-of-type {
      @include mq-up(tablet) {
        margin-right: 20px;
      }
      @include mq-down(tablet--under) {
        margin-bottom: 16px;
      }
    }
  }
}
</style>
