<template lang="pug">
  .p-creator-audition-confirm
    PageHead(
      title="入力内容を確認"
      text="入力内容をご確認ください。事務局が内容を確認後、公開されます。"
    )
    .p-creator-audition-confirm__content
      AuditionRequirements.p-creator-audition-confirm__aside(
        v-if="isPC"
        :deliveryDate="requested_deliverly_date"
        :price="offer_price"
        :priceRangeId="offerRriceRange"
        :limitDate="closingOn"
        :negotiable="deliverly_date_negotiable"
        :budgetType="budget_type"
      )
      .p-creator-audition-confirm__main
        Card.p-creator-audition-confirm__detail
          ConfirmationSummary(
            :summary="summary"
            :priceRangeId="offerRriceRange"
            :negotiable="deliverly_date_negotiable"
            :limitDate="closingOn"
            :deliveryDate="requested_deliverly_date"
            :budgetType="budget_type"
            :requestedCreator="creator"
            :price="offer_price"
          )
          AuditionDetail(
            :feePercent="plan.audition_fee_percentage"
            :humanImage="human_image"
            :information="information"
            :priceRangeId="offerRriceRange"
            :productionSchedule="production_schedule"
            :scopes="jobPartsIdentifiers"
            :serviceDescription="service_description"
            :price="offer_price"
            :budgetType="budget_type"
          )
        ConfirmationSubmit(
          @cancel="backToForm"
          @submit="applyCreatorAudition"
        )
    FlashMessage(
      :isShow="isShowingFlashMessage"
      :text="flashMessageText"
      :withAlert="true"
      :hideTime="8000"
      @changeFlashMessageVisibility="changeFlashMessageVisibility"
    )
</template>

<script>
import dayjs from 'dayjs'
import Card from 'src/components/atoms/Card'
import FlashMessage from 'src/components/atoms/FlashMessage'
import PageHead from 'src/components/molecules/PageHead'
import AuditionDetail from 'src/components/organisms/CreatorAudition/AuditionDetail'
import AuditionRequirements from 'src/components/organisms/CreatorAudition/AuditionRequirements'
import ConfirmationSubmit from 'src/components/organisms/CreatorAudition/ConfirmationSubmit'
import ConfirmationSummary from 'src/components/organisms/CreatorAudition/ConfirmationSummary'
import jobParts from 'src/lib/creatorAudition/jobParts'
import { thumbnails } from 'src/lib/creatorAudition/thumbnails'
import { UAParser } from 'ua-parser-js'
import { mapState, mapActions } from 'vuex'
import 'dayjs/locale/ja'

const deviceType = new UAParser().getDevice().type

export default {
  name: 'JobOfferConfirm',
  components: {
    Card,
    FlashMessage,
    PageHead,
    AuditionDetail,
    AuditionRequirements,
    ConfirmationSubmit,
    ConfirmationSummary,
  },
  props: {
    offerType: {
      type: String,
      required: true,
      validator: value => {
        return ['repeat', 'direct'].includes(value)
      }
    }
  },
  data() {
    return {
      isPC: deviceType !== 'mobile' && deviceType !== 'tablet',
      isShowingFlashMessage: false,
      flashMessageText: '',
      thumbnails: thumbnails
    }
  },
  computed: {
    ...mapState('JobOffer', [
      'id',
      'organization_name',
      'audition_name',
      'offer_type',
      'film_genre',
      'job_part_identifiers',
      'budget_type',
      'offer_price',
      'offer_price_range',
      'requested_deliverly_date',
      'deliverly_date_negotiable',
      'service_description',
      'production_schedule',
      'requested_movie_time_and_number',
      'closing_on_date',
      'closing_on_time',
      'human_image',
      'publication_scope',
      'icon_url',
      'icon',
      'icon_name',
      'shooting_location',
    ]),
    ...mapState('Plan', {plan: state => state}),
    ...mapState('User', {creator: state => state}),
    offerRriceRange() {
      return this.offer_price_range ? Number(this.offer_price_range) : null
    },
    closingOn() {
      const closingOnDateString = dayjs(this.closing_on_date).locale('ja').format('YYYY-MM-DD')
      const closingOnTimeString = `${this.closing_on_time}:00`

      return new Date(`${closingOnDateString}T${closingOnTimeString}+09:00`)
    },
    information() {
      return [
        { head: '撮影場所', description: this.shooting_location },
        { head: '動画の尺・本数', description: this.requested_movie_time_and_number }
      ].filter(info => info.description)
    },
    selectedThumbnail() {
      if (this.icon_name) {
        const foundThumbnail = this.thumbnails.find(data => data.name === this.icon_name)
        return foundThumbnail.image
      } else {
        return this.icon_url
      }
    },
    summary() {
      return {
        category: this.film_genre,
        title: this.audition_name,
        isLock: true,
        corporate: this.organization_name,
        publication_scope: this.publication_scope,
        offer_type: this.offer_type,
        thumbnail: this.selectedThumbnail,
      }
    },
    jobPartsIdentifiers() {
      return jobParts.map(val => {
        const isActive = this.job_part_identifiers.includes(val.id)

        return { id: val.id, text: this.$i18n.t(`creatorAuditions.detail.jobParts.${val.id}`), active: isActive }
      })
    },
  },
  created () {
    // NOTE：リロード時はstateが初期値になるので、入力フォームに戻す
    if(!this.requested_deliverly_date) {
      this.$router.go(-1)
    }
  },
  methods: {
    ...mapActions('JobOffer', { createJobOffer: 'create' }),
    async applyCreatorAudition() {
      try {
        const params = {
          closing_on: this.closingOn,
          creator_ids: [this.creator.id]
        }
        await this.createJobOffer(params)

        this.$router.push({ name: 'complete' })
      } catch ({response}) {
        this.isShowingFlashMessage = true
        if (response.status === 422) {
          this.flashMessageText = '送信に失敗しました。入力内容をご確認ください。'
        } else {
          this.flashMessageText = '送信に失敗しました。再度お試しいただき改善されない場合は画面右下より事務局までお問い合わせください。'
        }
      }
    },
    backToForm() {
      this.$router.push({
        name: 'form'
      })
    },
    changeFlashMessageVisibility(visibility) {
      this.isShowingFlashMessage = visibility
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~styles/creator_auditions/creator-audition-confirm.scss';
</style>

<style lang="scss">
// これを無効化しておかないとstickyが効かない
html, body {
  overflow-x: inherit!important;
}
</style>
