<template lang="pug">
  Card.o-creator-audition-form
    Title(title="募集要項・備考")
    .o-creator-audition-form__content
      .o-creator-audition-form__item
        .o-creator-audition-form__title
          LabelText.o-creator-audition-form__label(text="募集の締切日")
          RequiredIcon.m-input-set__required(:valid="isClosingOn")
        .o-creator-audition-form__inputs--date-and-time
          DatePicker.o-creator-audition-form__datepicker(
            ref="closingOnDate"
            :date="closingOnDate"
            :minDate="minDate"
            required
            @change="onChangeClosingOnDate"
          )
          TimeSelect.o-creator-audition-form__time-select(
            ref="closingOnTime"
            :value="closingOnTime"
            name="creator_audition[closingOnTime]"
            :byMinute="30"
            required
            @change="onChangeClosingOnTime"
          )
        HelpText.o-creator-audition-form__help(message="※募集期間は3日間以上を推奨")
      .o-creator-audition-form__item
        InputSet(title="募集する範囲" :disablePointerEvents="true")
          RadioSet(required :values="recruitmentScopes" :checkedValue="recruitmentScope" @change="onChangeRecruitmentScope")
      .o-creator-audition-form__item
        InputSet(title="公開する範囲" :disablePointerEvents="true")
          RadioSet(required :values="filteredpublicationScope" :checkedValue="publicationScope" @change="onChangePublicationScope")
      .o-creator-audition-form__item
        InputSet(title="クリエイターの選定ポイント")
          Textarea(
            required
            :name="inputJaName({name: 'humanImage'})"
            :value="humanImage"
            :rows="5"
            size="l"
            :placeholder="placeholderHumanImageJA"
            @input="handleInputHumanImage"
            maxlength="1000"
          )
      .o-creator-audition-form__item(v-if="isEN")
        InputSet(title="【英訳】クリエイターの選定ポイント")
          Textarea(
            required
            :name="inputEnName({name: 'humanImage'})"
            :value="humanImageEN"
            :rows="5"
            size="l"
            placeholder="英語のクリエイターの選定ポイント"
            @input="handleInputHumanImageEN"
            maxlength="1000"
          )
      .o-creator-audition-form__item(v-if="isAdmin")
        InputSet(title="留意事項")
          Textarea(
            :name="inputJaName({name: 'otherConditions'})"
            :value="otherConditions"
            :rows="5"
            size="l"
            placeholder="本件は納期が短めのお仕事です。状況により、募集が早く終了する場合もありますので、ご興味のある方はお早めにご応募ください。"
            @input="handleInputOtherConditions"
            maxlength="1000"
          )
      .o-creator-audition-form__item(v-if="isEN")
        InputSet(title="【英訳】留意事項")
          Textarea(
            :name="inputEnName({name: 'otherConditions'})"
            :value="otherConditionsEN"
            :rows="5"
            size="l"
            placeholder="英語の留意事項"
            @input="handleInputOtherConditionsEN"
            maxlength="1000"
          )
      .o-creator-audition-form__footer
        template(v-if="!isAdmin")
          Button.o-creator-audition-form__footer-button.o-creator-audition-form__footer-button--draft.track_creator_audition_save_draft(size="l" @click="onSave") 下書き保存
          Button.o-creator-audition-form__footer-button.track_creator_audition_to_confirm(size="l" color="black" :disabled="!isValidAuditionForm" @click="toConfirm") 確認画面へ
          TextLink.o-creator-audition-form__delete-audition.track_creator_audition_delete_draft(v-if="auditionId" @click="deleteDraft") 下書きを削除
        Button.o-creator-audition-form__footer-button(v-if="canPublish" size="l" :disabled="!isValidAuditionForm" color="special" @click="publish") 依頼を公開
</template>

<script>
import dayjs from 'dayjs'
import Button from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card'
import DatePicker from 'src/components/atoms/Form/DatePicker'
import Textarea from 'src/components/atoms/Form/Textarea'
import TimeSelect from 'src/components/atoms/Form/TimeSelect'
import HelpText from 'src/components/atoms/HelpText'
import LabelText from 'src/components/atoms/LabelText'
import RequiredIcon from 'src/components/atoms/RequiredIcon'
import TextLink from 'src/components/atoms/TextLink'
import Title from 'src/components/atoms/Title'
import InputSet from 'src/components/molecules/InputSet'
import RadioSet from 'src/components/molecules/RadioSet'
import { inputJaName, inputEnName } from 'src/lib/creatorAudition/i18nInput'
import publicationScopes from 'src/lib/creatorAudition/publicationScopes'
import 'dayjs/locale/ja'

export default {
  name: 'AuditionRequirementsForm',
  components: {
    Button,
    Card,
    Title,
    InputSet,
    RadioSet,
    DatePicker,
    Textarea,
    TimeSelect,
    HelpText,
    LabelText,
    TextLink,
    RequiredIcon,
  },
  props: {
    isEN: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    auditionId: {
      type: String,
      required: false,
      default: null,
    },
    isValidAuditionForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    canPublish: {
      type: Boolean,
      required: false,
      default: false,
    },
    closingOnDate: {
      type: String,
      required: true,
    },
    closingOnTime: {
      type: String,
      required: true,
    },
    recruitmentScope: {
      type: String,
      required: false,
      default: 'public',
    },
    publicationScope: {
      type: String,
      required: false,
      default: 'public',
    },
    humanImage: {
      type: String,
      required: false,
      default: null,
    },
    humanImageEN: {
      type: String,
      required: false,
      default: null,
    },
    otherConditions: {
      type: String,
      required: false,
      default: null,
    },
    otherConditionsEN: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      minDate: dayjs().locale('ja').format('YYYY-MM-DD'),
      recruitmentScopes: [
        {
          name: 'creator_audition[recruitmentScope]',
          value: 'public',
          title: '公開',
          description: 'NDAを締結した全クリエイターが閲覧・応募できます。',
        },
        {
          name: 'creator_audition[recruitmentScope]',
          value: 'invitation_only',
          title: '招待制',
          description:
            'NDAを締結した全クリエイターの中からあなたが招待したクリエイターのみが閲覧・応募できます。',
        },
      ],
      publicationScopes,
      placeholderHumanImageJA:
        '選定の際に重視する点・お願いやご相談事項をご記入ください。\n例) 参考動画のテイストアニメーションの実績がある方\n   インタビュー撮影経験が豊富な方',
    }
  },
  computed: {
    filteredpublicationScope() {
      // NOTE：非公開は事務局のみ選択できれば良いので管理者以外の場合は非公開は選択出来ないようにする
      return this.isAdmin
        ? publicationScopes
        : publicationScopes.filter((data) => data.value !== 'private')
    },
    isClosingOn() {
      return this.closingOnDate != '' && this.closingOnTime != ''
    },
  },
  methods: {
    isValid() {
      return (
        this.$refs.closingOnDate.selectedDate !== '' &&
        this.$refs.closingOnTime.selectedTime !== ''
      )
    },
    inputJaName({ name }) {
      return inputJaName({ name, isAdmin: this.isAdmin })
    },
    inputEnName({ name }) {
      return inputEnName({ name })
    },
    onChangeClosingOnDate(date) {
      const formatDate = dayjs(date).locale('ja').format('YYYY-MM-DD')
      this.$emit('change', { type: 'closingOnDate', payload: formatDate })
      this.$emit('validation')
    },
    onChangeClosingOnTime(time) {
      this.$emit('change', { type: 'closingOnTime', payload: time })
      this.$emit('validation')
    },
    onChangeRecruitmentScope(e) {
      this.$emit('change', {
        type: 'recruitmentScope',
        payload: e.target.value,
      })
      this.$emit('validation')
    },
    onChangePublicationScope(e) {
      this.$emit('change', {
        type: 'publicationScope',
        payload: e.target.value,
      })
      this.$emit('validation')
    },
    handleInputHumanImage(e) {
      this.$emit('input', { type: 'humanImage', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputHumanImageEN(e) {
      this.$emit('input', { type: 'humanImageEN', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputOtherConditions(e) {
      this.$emit('input', { type: 'otherConditions', payload: e.target.value })
    },
    handleInputOtherConditionsEN(e) {
      this.$emit('input', {
        type: 'otherConditionsEN',
        payload: e.target.value,
      })
    },
    onSave() {
      this.$emit('save')
    },
    toConfirm() {
      this.$emit('toConfirm')
    },
    deleteDraft() {
      this.$emit('deleteDraft')
    },
    publish() {
      this.$emit('publish')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~styles/creator_auditions/components/organisms/creator-audition-edit-form";
</style>
