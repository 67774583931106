export default {
  data() {
    return {
      showMoveTopButton: false
    }
  },

  mounted() {
    this.addObserver()
  },

  methods: {
    addObserver() {
      const formElement = this.$refs.form.$el
      const formElementObserver = new IntersectionObserver(changes => {
        for (const change of changes) {
          const rect = change.target.getBoundingClientRect()

          // 画面から form が消えたら表示する
          if (rect.top + rect.height < 0) {
            this.showMoveTopButton = true
          } else {
            this.showMoveTopButton = false
          }
        }
      })

      formElementObserver.observe(formElement)
    }
  }
}
