import axios from '@axios'
import { camelcaseKeys, snakecaseKeys } from 'src/lib/caseConverter'

// NOTE: 既存の axios のふるまいを変えないために instance を生成している
const instance = axios.create()

instance.interceptors.response.use(response => {
  return { ...response, data: camelcaseKeys(response.data) }
}, error => {
  return Promise.reject(error)
})

instance.interceptors.request.use(request => {
  return { ...request, data: snakecaseKeys(request.data) }
})

export default instance

export const getClientOfNotConvert = () => {
  const instance = axios.create()
  instance.interceptors.response.use(
    response => Promise.resolve(response),
    error => Promise.reject(error)
  )
  return instance
}
