import { fetchAddress } from 'src/lib/PostalCodeClient'

export default {
  namespaced: true,
  state: {
    postCode: null,
    address: null,
    addressError: null
  },
  actions: {
    async fetchAddress({ state }) {
      return await fetchAddress(state.postCode)
    }
  },
  mutations: {
    setPostCode(state, postCode) {
      if (postCode) state.postCode = postCode
    },
    setAddress(state, address) {
      if (address) state.address = address
    },
    setAddressError(state, error) {
      if(error) state.addressError = error
    },
  }
}
