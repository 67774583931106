<template lang="pug">
modal(:name="name")
  transition(name="fade")
    .m-modal
      //- NOTE：clickaway使っており領域外クリックでModal消えるためアイコンのみ
      i.crevo-icon.crevo-close(v-if="showCloseIcon")
      .m-modal__content(v-on-clickaway="closeModal" :style="customizedWidth")
        .m-modal__heading(v-if="title")
          p.m-modal__title {{ title }}
        .m-modal__body
          slot(name="body")
        .m-modal__footer
          slot(name="footer")
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'MoleculesModal',
  mixins: [clickaway],
  props: {
    name: {
      type: String,
      required: false,
      default: 'modal'
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    showCloseIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 600
    }
  },
  computed: {
    customizedWidth() {
      return `max-width: ${this.maxWidth}px`
    }
  },
  methods: {
    closeModal() {
      this.$modal.pop()
    }
  }
}
</script>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.m-modal {
  @include flex(center);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 17, 17, .9);
  border: 0;
  border-radius: 0;

  .crevo-close {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 16px;
    color: var(--color-mono-9);
    cursor: pointer;
    transition: .2s;
    &:hover {
      opacity: .8;
    }
  }

  &__content {
    z-index: 10001;
    display: grid;
    grid-template:
      "heading heading heading" auto
      ". . ."      40px
      ". body ."   auto
      ". . ."      36px
      ". footer ." auto
      ". . ."      40px
      /20px auto 20px;
    width: 100%;
    background-color: var(--color-mono-c);
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    @include mq-up(tablet) {
      grid-template:
        "heading heading heading" auto
        ". . ." 40px
        ". body ." auto
        ". . ." 20px
        ". footer ." auto
        ". . ." 20px / 15px auto 15px;
    }
    @include mq-down(tablet--under) {
      grid-template:
        "heading heading heading" auto
        ". . ."      30px
        ". body ."   auto
        ". . ."      20px
        ". footer ." auto
        ". . ."      20px /15px auto 15px;
      width: calc(100vw - 40px);
    }
  }

  &__heading {
    @include flex(center);
    grid-area: heading;
    height: 50px;
    padding: 0 20px;
    overflow: hidden;
    font-size: 14px;
    background: var(--color-mono-a);
    border-bottom: 1px solid var(--color-mono-7);
    border-radius: 5px 5px 0 0;
  }

  &__title {
    width: 100%;
    overflow: hidden;
    line-height: 1.2; // 1.1だと微妙に縦中央にならないので1.2
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__body {
    grid-area: body;
    font-size: 14px;
    text-align: center;
  }

  &__footer {
    grid-area: footer;
    text-align: center;
  }
}
</style>

<style lang="scss">
/*
  Note：以下理由により、.modal-backdropはdisplay:none;としている。
  そのため、vue-thin-modal を直接利用するなど、
  本コンポーネントを使わない場合は別途利用箇所で.modal-backdropをdisplay: block;で定義しておく必要がある
  - デザイン上バツがつくのと背景色がthin-modalと異なる、clickawayで背景タップで閉じたい
  - 既存のbootstrapのCSSとぶつかる可能性がある
*/
.modal-backdrop {
  display: none;
}
</style>
