const state = {
  creatives: [],
  index: 0,
  creator: null,
}

const getters = {
  creatives(state) {
    return state.creatives
  },

  creator(state) {
    return state.creator
  },

  index(state) {
    return state.index
  }
}

const actions = {
}

const mutations = {
  set(state, params) {
    state.creatives = params.creatives
    state.creator = params.creator
    state.index = params.index
  },

  increase(state) {
    if (state.index < state.creatives.length - 1) {
      state.index = state.index + 1
    }
  },

  decrease(state) {
    if (state.index > 0) {
      state.index = state.index - 1
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
