<template lang="pug">
  header.organism-organization-header
    img.organism-organization-header__logo(:src="logoSrc")
</template>

<script>
import logoImage from '@images/logo/brand_basic_horizontal.svg'
export default {
  name: 'OrganizationHeader',
  props: {
    logoSrc: {
      type: String,
      required: false,
      default: logoImage
    }
  }
}
</script>

<style lang="scss" scoped>
.organism-organization-header {
  display: grid;
  grid-template:
    ".   .  ." 1fr
    ". logo ." 20px
    ".   .  ." 1fr
    / 1fr auto 1fr;
  height: 100%;
  background-color: var(--color-mono-c);

  &__logo{
    grid-area: logo;
    align-self: center;
    justify-self: center;
    width: auto;
    height: 100%;
  }
}
</style>
