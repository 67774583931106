import axios from '@axios'

export default {
  namespaced: true,
  state: {
    specialFeatures: [],
    specialFeaturesError: null
  },
  actions: {
    async fetchSpecialFeatures({ commit }) {
      try {
        const response = await axios.get('/api/v2/dashboard/special_features')
        commit('setSpecialFeatures', response.data.special_features)
      } catch(err) {
        commit('setError', err)
      }
    },
  },
  mutations: {
    setSpecialFeatures(state, specialFeatures) {
      if(specialFeatures) state.specialFeatures = specialFeatures
    },
    setError(state, error) {
      if(error) state.specialFeaturesError = error
    }
  }
}
