<template lang='pug'>
  span
    template(v-if='profileImageUrl')
      img.u-user-avatar-initial(:class='sizeClassName' :src='profileImageUrl')
    template(v-else)
      i.u-user-avatar-initial(:class='sizeClassName' :style="initialIconStyle") {{lastNameInitial}}
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: false,
      default: null
    },
    thumbnailUrl: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      sizeClassName: `u-user-avatar--${this.size}`,
      initialIconStyle: {
        backgroundColor: this.iconBackgroundColor
      },
    }
  },
  computed: {
    profileImageUrl() {
      return this.thumbnailUrl || this.user?.profile_image_url || this.user?.profileImageUrl
    },
    iconBackgroundColor() {
      return this.user?.icon_background_color || this.user?.iconBackgroundColor
    },
    lastNameInitial() {
      return this.user?.last_name_initial || this.user?.lastNameInitial
    }
  }
}
</script>

<style lang="scss" scoped>
  // NOTE：vue-app配下で同じcomponentがあるのでstyleは一旦流用
  @import '~styles/common/object/utility/user-avatar.scss'
</style>
