<template lang='pug'>
component.m-input-set(:is="element" :class="helpBottom ? 'm-input-set--layout-help-bottom' : ''")
  LabelText.m-input-set__title(:text='title')
  RequiredIcon.m-input-set__required(v-if="required && withRequiredIcon" :valid="validity && validity.valid")
  HelpText.m-input-set__help(:message='helpMessage' v-if='helpMessage')
  .m-input-set__inputs(:class="columnsClass")
    slot
  transition(name="m-input-set__validation-message")
    p.m-input-set__validation-message(v-if="validationMessage")
      i.crevo-icon.crevo-exclamation_fill
      |{{validationMessage}}
</template>

<script>
import HelpText from 'src/components/atoms/HelpText'
import LabelText from 'src/components/atoms/LabelText'
import RequiredIcon from 'src/components/atoms/RequiredIcon'

export default {
  name: 'InputSet',
  components: { LabelText, HelpText, RequiredIcon },

  props: {
    title: {
      type: String,
      required: true
    },

    helpMessage: {
      type: String,
      required: false,
      default: null
    },

    // NOTE: 新規で追加する場合は helpMessageは基本下に配置するので helpBottom: true にする
    helpBottom: {
      type: Boolean,
      required: false,
      default: false
    },

    disablePointerEvents: {
      type: Boolean,
      required: false,
      default: false
    },

    // 必須アイコン不要なときはfalseにする
    withRequiredIcon: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data(){
    return {
      validity: null,
      validationMessage: null,
      required: false
    }
  },
  computed: {
    element(){
      return this.disablePointerEvents ? 'span' : 'label'
    },
    columnsClass(){
      // TODO: slotは必ず渡す運用なのでslotがない場合を考慮したロジックを削除する(他も同様)
      return this.$slots.default && this.$slots.default.length > 1 ? `m-input-set__inputs--${this.$slots.default.length}-columns` : ''
    }
  },
  mounted(){
    const addEventListener = elm => {

      if(!elm.validity){
        elm.childNodes.forEach(node => {
          addEventListener(node)
        })
        return false
      }

      elm.addEventListener('blur', () => {
        this.checkValidity()
      })
      elm.addEventListener('input', () => {
        this.checkValidity()
      })
      elm.addEventListener('change', () => {
        this.$nextTick()
        .then(() => {
          this.checkValidity()
        })
        //IE11対応 わざと遅延させないとvalidateStateが書き換わらないので待たせる
      })
    }

    this.$slots.default.forEach(slot => {
      //マウント時にslot内にrequiredな子孫がいないか判定する
      this.checkRequired(slot.elm)
      addEventListener(slot.elm)
    })
  },
  methods: {
    //elmentがrequiredか判定する関数
    checkRequired(elm){

      //渡された要素の子要素がrequiredの可能性があるので再帰的に処理する
      if(!elm.validity){
        elm.childNodes.forEach(node => {
          this.checkRequired(node)
        })
        return false
      }

      //要素がrequired属性を持っていれば、このInputSetを必須表現にする
      if(elm.required){
        this.required = true
        this.$el.setAttribute('aria-required', 'true')
      }
    },
    //現在InputSet内のコンポーネントがvalidか判定する関数
    checkValidity(){

      //子孫要素にinvalidな要素があったか判定結果を関数内で共有するための変数
      //invalidな要素が1つでもあればtrueに書き換えられる
      let invalid = false

      const check = elm => {

        //既に子孫にinvalidな要素があると判定されているので、falseを返して判定処理を止める
        if(invalid){
          return false
        }

        //要素がvalidityプロパティを持っていない = input系要素ではないが、elmの子要素がinput系要素の可能性があるので再帰処理する
        if(!elm.validity){
          elm.childNodes.forEach(node => {
            check(node)
          })

          //このelm自体はinvalidか判定する必要がないので、falseを返して判定処理を止める
          return false
        }

        //elmが判定対象（input系）の場合のみ処理が続行される
        this.validity = elm.validity
        this.validationMessage = elm.validationMessage
        this.$el.removeAttribute('aria-invalid')

        if(!this.validity.valid){
          //elmがinvalidの時、変数をtrueに書き換えてその後の処理を止め、DOMの状態をaria-invalid = trueにする
          invalid = true
          this.$el.setAttribute('aria-invalid', 'true')
        }
      }

      this.$slots.default.forEach(slot => {
        check(slot.elm)
      })
    }
  }
}
</script>

<style lang='scss' scoped>
  // stylesheets/collet/components/molecules/_input_set.scssを参照
</style>
