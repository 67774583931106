<template lang='pug'>
  search-condition-template(:categoryName='categoryName' :isActive='isActive' @apply='apply' @cancel='cancel' @cancellationAll='cancellationAll')
    .basecamp-search__condition-groups(:class="'s-' + categories.length")
      search-condition-group(v-for="(category, index) in categories" :category='category' ref='checkboxGroups' :key='categoryName + index')
</template>

<script>
import { mapMutations } from 'vuex'
import SearchConditionGroup from './search-condition-group.vue'
import SearchConditionTemplate from './search-condition-template.vue'

export default {
  components: {
    'search-condition-group': SearchConditionGroup,
    'search-condition-template': SearchConditionTemplate
  },

  props: {
    categoryName: {
      type: String,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },

  computed: {
    inputForm() {
      return this.$flatten(this.categories.map(category => category.key).map(key => this.$store.state.form[key]))
    },
    isActive() {
      return this.inputForm.length > 0
    }
  },

  methods: {
    ...mapMutations('form', { formSetCheckItems: 'setCheckItems' }),

    formRecovery() {
      this.$refs.checkboxGroups.forEach((checkboxGroup) => checkboxGroup.restore())
    },

    cancellationAll() {
      this.$refs.checkboxGroups.forEach((checkboxGroup) => checkboxGroup.clear())
    },

    cancel() {
      this.formRecovery()
    },

    collectCheckItems() {
      const obj = {}
      this.$refs.checkboxGroups.forEach((checkboxGroup) => {
        const key = checkboxGroup.category.key
        if (Array.isArray(obj[key])) {
          obj[key] = obj[key].concat(checkboxGroup.values())
        } else {
          obj[key] = checkboxGroup.values()
        }
      })

      return obj
    },

    apply() {
      this.formSetCheckItems({...this.collectCheckItems()})
      this.$emit('search')
    }
  }
}
</script>

<style lang="scss" scoped>
  @mixin generateTemplate($column-count,$row-count) {
    display: grid;
    grid-auto-flow: row;
    gap: if($column-count < 3, 40px, 20px);
    grid-template-columns: repeat($column-count, max-content);
    grid-template-rows: repeat($row-count, min-content);
  }

  .basecamp-search {
    &__condition-groups {
      // 1-4ブロックはブロック数と同じ列数にする
      @for $i from 1 through 4 {
        &.s-#{$i} {
          @include generateTemplate($i,1);
        }
      }

      // 5ブロック以上は2行4列にする
      &.s-5,
      &.s-6,
      &.s-7,
      &.s-8 {
        @include generateTemplate(4,2);
      }
    }
  }
</style>
