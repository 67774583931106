import axios from '@axios'
import * as sweetalert2 from 'sweetalert2'
export default {
  namespaced: true,
  state: {
    userRecommendations: [],
    userRecommendationsError: null,
    userRecommendationsLoading: true
  },
  actions: {
    async removeUserRecommendation({ commit }, recommendation) {
      sweetalert2.fire({
        title: '指定の通知を削除しますか？',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'いいえ',
        confirmButtonText: 'はい'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const {id, type} = recommendation
            const { data } = await axios.delete(`/api/v2/dashboard/user_recommendations/${id}?type=${type}`)
            console.log(data)
            sweetalert2.fire(
              '',
              '通知を削除しました。',
              'success'
            ).then(() => {
              window.location.reload()
            })
          }catch (err) {
            commit('setError', err)
          }
        }
      })
    },
    async fetchUserRecommendations({ commit }) {
      try {
        const { data } = await axios.get('/api/v2/dashboard/user_recommendations')
        console.log('data', data)
        commit('setUserRecommendations', data.user_recommendations)
      } catch (err) {
        commit('setError', err)
      }
    }
  },
  mutations: {
    setUserRecommendations(state, userRecommendations) {
      state.userRecommendations = userRecommendations
      state.userRecommendationsLoading = false
    },
    setError(state, error) {
      state.userRecommendationsError = error
      state.userRecommendationsLoading = false
    }
  }
}
