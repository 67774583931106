<template lang='pug'>
  .o-job-offer-summary
    img.o-job-offer-summary__visual(:src="summaryThumbnail")
    section.o-job-offer-summary__content
      .o-job-offer-summary__labels
        Label.o-job-offer-summary__label(color="green" :text="categoryLabel")
        Label.o-job-offer-summary__label(color="green" :text="offerTypeLabel")
      h1.o-job-offer-summary__title
        i.crevo-icon.crevo-lock_fill(v-if="summary.isLock")
        | {{ summary.title }}
      p.o-job-offer-summary__corporate-name {{ summary.corporate }}
      ul.o-job-offer-summary__statuses
        //- TODO：依頼詳細時に依頼状況をここに入れる
        li.o-job-offer-summary__status(v-if="summary.numberOfApplication")
          | 応募数：
          b {{ summary.numberOfApplication }}人
        li.o-job-offer-summary__status
          | 公開範囲：
          b {{ publicationScopeLabel }}
</template>
<script>
import defaultImage from '@images/common/img-noproject.png'
import Label from 'src/components/atoms/Label'

export default {
  name: 'JobOfferSummary',
  components: { Label },
  props: {
    // TODO：ここObjectで渡す必要ないのであとで解体
    summary: {
      type: Object,
      required: true
    }
  },
  computed: {
    categoryLabel() {
      const categories = {
        animation: 'アニメーション',
        live_action: '実写',
        undecided: '表現自由'
      }

      return categories[this.summary.category]
    },
    publicationScopeLabel() {
      const labels = { public: '公開', published_only_overview: '詳細非公開', private: '非公開' }
      return labels[this.summary.publication_scope]
    },
    offerTypeLabel() {
      const offerTypes = {
        package: '全体依頼',
        part: '部分依頼'
      }

      return offerTypes[this.summary.offer_type]
    },
    summaryThumbnail() {
      return this.summary.thumbnail ? this.summary.thumbnail : defaultImage
    }
  },
}
</script>
<style lang='scss' scoped>
.o-job-offer-summary {
  width: 100%;
  @include mq-up(tablet) {
    @include flex(start, start);
  }
  &__visual {
    width: 100px;
    height: 100px;
    margin-right: 16px;
    overflow: hidden;
    border-radius: 3px;
    object-fit: cover;
    @include mq-down(tablet--under) {
      // NOTE：親要素で左右に20pxずつ余白設けているが、サムネイルが100vw必要なので100vw指定してネガティブマージンで調整
      width: 100vw;
      height: calc(100vw * 100 / 375); // 375pxに対して160pxで可変
      margin-right: 0;
      margin-left: -20px; // 親要素でpaddingかけてるのでここだけネガティブマージン設定
    }
  }
  &__content {
    // 親要素の幅 - 画像の幅 - 画像右横のマージン
    width: calc(100% - 100px - 16px);
    @include mq-down(tablet--under) {
      width: 100%;
      margin-top: 24px;
    }
  }
  &__labels {
    margin-bottom: 10px;
    @include mq-down(tablet--under) {
      margin-bottom: 14px;
    }
  }
  &__label {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
  &__title {
    margin-bottom: 6px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
    @include mq-down(tablet--under) {
      font-size: 20px;
    }
    .crevo-icon {
      float: left;
      margin: 5px 6px 0 0;
      font-size: 15px;
      @include mq-down(tablet--under) {
        margin-top: 7px;
      }
    }
  }
  &__corporate-name {
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 1.1;
    color: var(--color-mono-3);
  }
  &__statuses {
    @include flex(start, start);
  }
  &__status {
    margin-right: 14px;
    font-size: 12px;
    line-height: 1.4;
  }
}
</style>
