const formattedNumberComma = number => {
  return String(number).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

const formattedNumberCommaWithYen = number => {
  if (number === null) return ''

  const intNumber = parseInt(number, 10)

  return '¥' + formattedNumberComma(intNumber)
}

export { formattedNumberComma, formattedNumberCommaWithYen }
