import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import organizations from '../../store/organizations/modules/organizations'
import user from '../../store/user/modules/user'
import creatorAudition from './modules/creatorAudition'

Vue.use(Vuex)

const store = new Store({
  modules: {
    creatorAudition,
    organizations,
    user
  },
  getters: {
    offerFeePercentage: (state) => {
      // 仕事依頼が保存済みの場合はcreatorAudition.audition_rate を使い 未保存の場合はorganizations.organization_audition_rateを使う
      const rate = (state.creatorAudition.audition_rate ?? state.organizations.organization_audition_rate)

      return rate * 100
    }
  },
  strict: process.env.NODE_ENV !== 'production',
})

export default store
