<template lang="pug">
.o-amount-breakdown
  ul
    li.o-amount-breakdown__item
      .o-amount-breakdown__label {{ $t('amountBreakdown.budget') }}
      .o-amount-breakdown__text {{ budget }}
    template(v-if="isCreator")
      li.o-amount-breakdown__item
        .o-amount-breakdown__label {{ $t('amountBreakdown.referralFeeRate', { rate: referralFeeRate }) }}
        .o-amount-breakdown__text {{ formattedReferralFee }}
      li.o-amount-breakdown__item
        .o-amount-breakdown__label.o-amount-breakdown__label--bold {{ $t('amountBreakdown.reward') }}
        .o-amount-breakdown__text.o-amount-breakdown__text--bold {{ reward }}
    template(v-else)
      li.o-amount-breakdown__item
        .o-amount-breakdown__label 依頼手数料({{ matchingFeeRate }}%)
        .o-amount-breakdown__text {{ formattedMatchingFee }}
      li.o-amount-breakdown__item
        .o-amount-breakdown__label.o-amount-breakdown__label--bold 請求金額(税抜)
        .o-amount-breakdown__text.o-amount-breakdown__text--bold {{ billingAmount }}
</template>

<script>
import jobOfferPriceRanges from 'src/lib/creatorAudition/jobOfferPriceRanges'
import { formattedNumberCommaWithYen } from 'src/lib/utility.js'

export default {
  name: 'AmountBreakdown',
  props: {
    budgetType: {
      type: String,
      required: true
    },
    offerPrice: {
      type: Number,
      required: false,
      default: 0
    },
    offerPriceRangeId: {
      type: Number,
      required: false,
      default: 0
    },
    matchingFeeRate: { // 依頼企業向け紹介手数料率
      type: Number,
      required: false,
      default: 0
    },
    referralFeeRate: { // クリエイター向け依頼手数料率
      type: Number,
      required: false,
      default: 0
    },
    isCreator: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    priceRange () {
      return jobOfferPriceRanges.find(item => item.id === this.offerPriceRangeId)
    },
    budget() {
      if (this.budgetType == 'specify') {
        return formattedNumberCommaWithYen(this.offerPrice)
      } else {
        return this.priceRange ? this.formatPriceRange(this.priceRange.from, this.priceRange.to) : '-'
      }
    },
    referralFee() {
      return this.calcFee(this.offerPrice, this.referralFeeRate)
    },
    formattedReferralFee() {
      return this.formattedFee(this.referralFee, this.referralFeeRate)
    },
    reward() {
      return this.formattedAmount(this.offerPrice - this.referralFee, this.calcReward)
    },
    matchingFee() {
      return this.calcFee(this.offerPrice, this.matchingFeeRate)
    },
    formattedMatchingFee() {
      return this.formattedFee(this.matchingFee, this.matchingFeeRate)
    },
    billingAmount() {
      return this.formattedAmount(this.offerPrice + this.matchingFee, this.calcBillingAmmount)
    },
  },
  methods: {
    formattedFee(fee, rate) {
      if (this.budgetType == 'specify') {
        return formattedNumberCommaWithYen(fee)
      } else {
        if (!this.priceRange) { return '-' }
        if (rate === 0) { return '¥0' }

        const from = this.calcFee(this.priceRange.from, rate)
        const to = this.calcFee(this.priceRange.to, rate)
        return this.formatPriceRange(from, to)
      }
    },
    formattedAmount(amount, calcFunc) {
      if (this.budgetType == 'specify') {
        return formattedNumberCommaWithYen(amount)
      } else {
        if (!this.priceRange) { return '-' }

        const from = calcFunc(this.priceRange.from)
        const to = calcFunc(this.priceRange.to)
        return this.formatPriceRange(from, to)
      }
    },
    calcFee(price, rate) {
      return Number.isInteger(price) ? Math.floor(price * rate / 100) : null
    },
    calcBillingAmmount (price) {
      return Number.isInteger(price) ? price + this.calcFee(price, this.matchingFeeRate) : null
    },
    calcReward (price) {
      return Number.isInteger(price) ? price - this.calcFee(price, this.referralFeeRate) : null
    },
    formatPriceRange(from, to) {
      return `${formattedNumberCommaWithYen(from)} 〜 ${formattedNumberCommaWithYen(to)}`
    }
  }
}
</script>

<style lang="scss" scoped>
.o-amount-breakdown {
  width: 100%;
  padding: 48px 54px 40px;
  background: var(--color-mono-b);
  border-radius: 5px;
  @include mq-down(tablet--under) {
    padding: 26px 24px;
  }
  &__item {
    margin-bottom: 22px;
    line-height: 1.1;
    @include flex(between, center);
    @include mq-down(tablet--under) {
      margin-bottom: 14px;
      line-height: 1.3;
    }
    &:last-of-type {
      padding-top: 30px;
      margin: 30px 0 0;
      border-top: solid 1px var(--color-mono-3);
      @include mq-down(tablet--under) {
        padding-top: 22px;
        margin: 22px 0 0;
      }
    }
  }
  &__label {
    font-size: 16px;
    &--bold {
      font-size: 18px;
      font-weight: bold;
      @include mq-down(tablet--under) {
        font-size: 16px;
      }
    }
  }
  &__text {
    padding-left: 28px;
    font-size: 16px;
    text-align: right;
    @include mq-down(tablet--under) {
      font-size: 18px;
    }
    &--bold {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>
