import axios from '@axios'
import _ from 'lodash'

const state = {
  token: null,
  shareAdmissions: []
}

const getters = {
  token(state) {
    return state.token
  },

  shareAdmissions(state) {
    return state.shareAdmissions
  }
}

const baseURL = creatorId => { return `/api/creators/${creatorId}/share_admissions` }
const actions = {
  load({ commit }, creatorId) {
    axios.get(baseURL(creatorId)).then(response => {
      const shareAdmissions = response.data.share_admissions
      commit('setShareAdmissions', shareAdmissions)
    })
  },

  create({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      axios.post(baseURL(params.creatorId), params).then(response => {
        const shareAdmission = response.data.share_admission
        commit('setToken', shareAdmission.token)
        commit('setShareAdmissions', getters.shareAdmissions.concat(shareAdmission))
        resolve(shareAdmission.token)
      }).catch(error => {
        reject(error.response.data.messages)
      })
    })
  },

  destroy({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      axios.delete(`${baseURL(params.creatorId)}/${params.token}`).then(() => {
        const shareAdmissions = _.reject(getters.shareAdmissions, ['token', params.token])
        commit('setShareAdmissions', shareAdmissions)
        return resolve()
      }).catch(errors => {
        return reject(errors)
      })
    })
  }
}

const mutations = {
  setToken(state, token) {
    state.token = token
  },

  setShareAdmissions(state, shareAdmissions) {
    state.shareAdmissions = _.orderBy(shareAdmissions, ['created_at'], ['desc'])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
