<template lang="pug">
  .basecamp-search__condition-group
    .basecamp-search__condition-header {{category.label}}
    ul.basecamp-search__condition-item-list(:class="'s-' + columnCount")
      search-condition-checkbox(v-for="item in category.items" :item='item' :formKey='category.key' ref='checkboxes', :key='item[0]')

</template>

<script>
import SearchConditionCheckbox from './search-condition-checkbox.vue'

export default {
  components: {
    'search-condition-checkbox': SearchConditionCheckbox
  },

  props: {
    category: {
      type: Object,
      required: true
    }
  },

  computed: {
    columnCount() {
      const itemsCount = this.category.items.length
      let count = Math.floor(itemsCount / 6)
      if (itemsCount % 6 > 0) {
        count++
      }
      return count
    }
  },

  methods: {
    restore() {
      this.$refs.checkboxes.forEach((checkbox) => checkbox.restore())
    },

    clear() {
      this.$refs.checkboxes.forEach((checkbox) => checkbox.uncheck())
    },

    values() {
      return this.$refs.checkboxes.filter((checkbox) => checkbox.checked).map((checkbox) => checkbox.item[0])
    }
  }
}
</script>

<style lang='scss' scoped>
  @mixin generateTemplate($column-count,$row-count){
    grid-area: condition-container;
    display: grid;
    grid-auto-flow: row;
    gap: 6px;
    grid-template-columns: repeat($column-count, max-content);
    grid-template-rows: repeat($row-count, min-content);
  }

  .basecamp-search {
    &__condition-group {
      word-break: keep-all;

      &::last-child {
        margin-right: 0;
      }
    }

    &__condition-header {
      margin-bottom: 8px;
      font-size: 14px;
      color: var(--color-mono-1);
      font-weight: bold;
    }

    &__condition-item-list {
      list-style-type: none;

      @for $i from 1 through 4 {
        &.s-#{$i} {
          @include generateTemplate($i, 6);
        }
      }
    }
  }
</style>
