<template lang="pug">
  .a-title {{ title }}
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.a-title {
  padding: 2px 0 0 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
  color: var(--color-mono-1);
  border-left: 4px solid var(--color-mono-5);
}
</style>
