<template lang="pug">
  .o-audition-detail
    .o-audition-detail__item(v-if="humanImage")
      Title.o-audition-detail__caption(:title="$t('creatorAuditions.detail.humanImage')")
      .o-audition-detail__text {{ humanImage }}
    .o-audition-detail__item(v-if="isDisplayRequestedCreator")
      Title.o-audition-detail__caption(title="依頼するクリエイター")
      UserProfileUnit(:user="requestedCreator" color="white")
    .o-audition-detail__item(v-if="information.length > 0")
      Title.o-audition-detail__caption(:title="$t('creatorAuditions.detail.information')")
      InfoTable(:information="information")
    .o-audition-detail__item(v-if="serviceDescription")
      Title.o-audition-detail__caption(:title="$t('creatorAuditions.detail.serviceDescription')")
      .o-audition-detail__text {{ serviceDescription }}
    .o-audition-detail__item(v-if="productionSchedule")
      Title.o-audition-detail__caption(:title="$t('creatorAuditions.detail.productionSchedule')")
      .o-audition-detail__text {{ productionSchedule }}
    .o-audition-detail__item(v-if="owner")
      Title.o-audition-detail__caption(title="担当者")
      UserProfileUnit(:user="owner" color="white")
      .o-audition-detail__note ※ 同じ組織のユーザーにのみ表示されます。
    .o-audition-detail__item
      Title.o-audition-detail__caption(:title="$t('creatorAuditions.detail.scopes')")
      ScopeOfItemList(:values="scopes")
    .o-audition-detail__item.o-audition-detail__item--reference-movie(v-if="referenceMovies.length > 0")
      Title.o-audition-detail__caption(:title="$t('creatorAuditions.detail.referenceMovies')")
      ul.o-audition-detail__references
        li.o-audition-detail__reference(v-for="(reference, index) in referenceMovies")
          VideoUnit.o-audition-detail__video(v-if="isReferenceMovieUrl(reference.url)" :autoplay="true" :embedURL="reference.embedURL" :thumbnailURL="reference.thumbnailURL" :withRadius="true")
          .o-audition-detail__video.o-audition-detail__video--no-video(v-else)
            i.crevo-icon.crevo-link
          a.a-text-link.o-audition-detail__link(:href="reference.url" target="_blank")
            span {{ reference.url }}
            i.crevo-icon.crevo-exlink
          .o-audition-detail__text.o-audition-detail__text--reference(v-if="reference.reason") {{ reference.reason }}
    .o-audition-detail__item
      Title.o-audition-detail__caption(:title="$t('creatorAuditions.detail.breakdown')")
      AmountBreakdown(
        :budgetType="budgetType"
        :offerPrice="price"
        :offerPriceRangeId="priceRangeId"
        :matchingFeeRate="feePercent"
        :referralFeeRate="referralFeeRate"
        :isCreator="isCreator"
      )
</template>

<script>
import TextLink from 'src/components/atoms/TextLink'
import Title from 'src/components/atoms/Title'
import ScopeOfItemList from 'src/components/molecules/CreatorAudition/ScopeOfItemList'
import UserProfileUnit from 'src/components/molecules/CreatorAudition/UserProfileUnit'
import InfoTable from 'src/components/molecules/InfoTable'
import VideoUnit from 'src/components/molecules/VideoUnit'
import AmountBreakdown from 'src/components/organisms/AmountBreakdown'
import isPlayableVideoUrl from 'src/lib/playableMovieUrl'

export default {
  name: 'AuditionDetail',
  components: {
    InfoTable,
    ScopeOfItemList,
    TextLink,
    Title,
    UserProfileUnit,
    VideoUnit,
    AmountBreakdown
  },
  props: {
    information: {
      type: Array,
      required: false,
      default: () => []
    },
    serviceDescription: {
      type: String,
      required: false,
      default: null
    },
    productionSchedule: {
      type: String,
      required: false,
      default: null
    },
    humanImage: {
      type: String,
      required: false,
      default: null
    },
    referenceMovies: {
      type: Array,
      required: false,
      default: () => []
    },
    priceRangeId: {
      type: Number,
      required: false,
      default: null,
    },
    price: {
      type: Number,
      required: false,
      default: 0,
    },
    feePercent: {
      type: Number,
      required: true,
    },
    budgetType: {
      type: String,
      required: true,
    },
    scopes: {
      type: Array,
      required: true
    },
    requestedCreator: { // NOTE：仕事依頼詳細の概要でのみ使用
      type: Object,
      required: null,
      default: null
    },
    owner: { // NOTE：仕事依頼詳細の概要でのみ使用
      type: Object,
      required: false,
      default: null
    },
    workflowState: { // NOTE：仕事依頼詳細の概要でのみ使用
      type: String,
      required: false,
      default: null
    },
    referralFeeRate: {
      type: Number,
      required: false,
      default: null,
    },
    isCreator: {
      type: Boolean,
      required: false,
      default: false
    },
    isCustomerSupport: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isDisplayRequestedCreator() {
      return this.requestedCreator && this.isCustomerSupport && this.workflowState === 'posted'
    }
  },
  methods: {
    isReferenceMovieUrl(url) {
      return isPlayableVideoUrl(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.o-audition-detail {
  &__item:not(:last-of-type) {
    margin-bottom: 36px;
    @include mq-down(tablet--under) {
      margin-bottom: 30px;
    }
    &--reference-movie {
      margin-bottom: 18px;
    }
  }
  &__caption {
    margin-bottom: 16px;
  }
  &__text {
    overflow: hidden;
    font-size: 14px;
    line-height: 1.6;
    overflow-wrap: break-word;
    white-space: pre-wrap;

    &--reference {
      margin-top: 4px;
    }
  }
  &__references {
    @include mq-up(tablet) {
      @include flex(start, start);
      flex-wrap: wrap;
    }
  }
  &__reference {
    @include mq-up(tablet) {
      // PCの時は左右の余白が40pxあるので半分で計算
      width: calc(50% - 20px);
      margin-bottom: 18px;
      &:nth-of-type(2n) {
        margin-left: 40px;
      }
    }
    @include mq-down(tablet--under) {
      width: 100%;
      margin-bottom: 22px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &__video {
    margin-bottom: 12px;
    @include mq-down(tablet--under) {
      margin-bottom: 8px;
    }

    &--no-video {
      @include flex(center);
      width: 100%;
      aspect-ratio: 16 / 9;
      background: var(--color-mono-6);
      border-radius: 5px;
      object-fit: cover;
      .crevo-icon {
        font-size: 40px;
        color: var(--color-mono-c);
      }
    }
  }
  &__link {
    @include flex(start, start);
    overflow: hidden;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    .crevo-icon {
      margin-top: -1px;
      margin-left: 4px;
      font-size: 12px;
    }
  }
  &__note {
    margin-top: 12px;
    font-size: 12px;
    color: var(--color-mono-3);
  }
}
</style>
