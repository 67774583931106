<template lang="pug">
  Card.o-coordinator-information(:class="{'o-coordinator-information--with-side-bar': withSideBar }")
    template(v-if="withSideBar")
      .o-coordinator-information__title ご相談窓口
      .o-coordinator-information__description 操作方法・制作内容などVideoWorksに関するご相談は下記フォームよりお問い合わせください。
      .o-coordinator-information__reception-annotation ※通常3営業日以内にご返信させていただきます。
      .o-coordinator-information__buttons
        Button.track_dashboard_static_open_chat(title="お問い合わせフォーム" href="https://co-llet.com/contact" target="_blank")
    template(v-else)
      Title(title="依頼について専任コーディネーターに相談する(受付：平日10:00 - 19:00)")
      ul.o-coordinator-information__list
        li.o-coordinator-information__item
          button.o-coordinator-information__link.track_dashboard_static_open_chat(href="https://co-llet.com/contact" target="_blank")
            img.o-coordinator-information__image(:src="ChatImage")
            .o-coordinator-information__content
              .o-coordinator-information__caption お問い合わせフォーム
              .o-coordinator-information__text 所要時間1分。どんなご相談でもお気軽にお声がけください。
            i.crevo-icon.crevo-triangle_right_fill
</template>

<script>
import ChatImage from '@images/dashboard/img-chat.png'
import MobileImage from '@images/dashboard/img-mobile.png'
import Button from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card'
import Title from 'src/components/atoms/Title'

export default {
  name: 'CoordinatorInformation',
  components: { Button, Card, Title },
  props: {
    withSideBar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ChatImage: ChatImage,
      MobileImage: MobileImage,
      phoneNumber: '050-1742-5680'
    }
  },
  methods: {
    showHubspot() {
      window.HubSpotConversations.widget.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.o-coordinator-information {
  padding: 45px 50px;
  @include mq-down(tablet--under) {
    padding: 30px 24px;
  }
  &--with-side-bar {
    padding: 24px 20px 10px;
    @include mq-down(tablet--under) {
      padding: 22px 24px 18px;
    }
  }
  &__title {
    margin-bottom: 18px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.1;
    color: var(--color-mono-1);
  }
  &__list {
    margin-top: 20px;
  }
  &__item {
    @include mq-up(tablet) {
      width: 400px;
    }
  }
  &__link {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 22px 38px 22px 24px;
    text-decoration: none;
    border: 1px solid var(--color-mono-6);
    border-radius: 5px;
    transition: .2s;
    &:active {
      opacity: .8;
    }
    @include mq-up(narrow-pc) {
      &:hover {
        opacity: .8;
      }
    }
    @include mq-up(tablet) {
      @include flex(between);
    }
    @include mq-down(tablet--under) {
      @include flex(start, start);
      padding: 18px 30px 18px 20px;
    }
    .crevo-triangle_right_fill {
      position: absolute;
      top: 50%;
      right: 24px;
      font-size: 14px;
      color: var(--color-mono-6);
      transform: translateY(-50%);
      @include mq-down(tablet--under) {
        right: 20px;
        font-size: 12px;
      }
    }
  }
  &__image {
    width: 80px;
    margin-right: 20px;
    @include mq-down(tablet--under) {
      width: 32px;
      margin-right: 16px;
    }
  }
  &__content {
    flex: 1;
    color: var(--color-navy);
  }
  &__caption {
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.4;
  }
  &__text {
    font-size: 14px;
    line-height: 1.4;
  }
  &__description {
    padding-top: 16px;
    font-size: 14px;
    line-height: 1.6;
    border-top: 1px solid var(--color-mono-8);
    @include mq-down(tablet--under) {
      padding-top: 20px;
    }
  }
  &__reception-annotation {
    margin: 4px 0 12px;
    font-size: 12px;
    color: var(--color-mono-3);
  }
  &__buttons {
    .a-button {
      width: 100%;
      margin-bottom: 10px;
      @include mq-down(tablet--under) {
        margin-bottom: 12px;
      }
    }
  }
}
</style>
