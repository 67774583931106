import axios from '@axios'

const state = {
  all: []
}

const getters = {
  all(state) {
    return state.all
  }
}

const actions = {
  load({ commit }) {
    axios.get('/api/creator_lists').then(response => {
      commit('set', response.data.creator_lists)
    })
  },

  create({ commit }, params) {
    return axios.post('/api/favorites/creator_lists', { picked_creator_list: params}).then(response => {
      commit('appendList', response.data)
    })
  }
}

const mutations = {
  set(state, creatorLists) {
    state.all = creatorLists
  },

  appendList(state, params) {
    state.all.unshift(params)
  },

  append(state, params) {
    const list = state.all.find(list => list.id === params.creatorListId)
    list.creator_ids.push(params.creatorId)
  },

  remove(state, params) {
    const list = state.all.find(list => list.id === params.creatorListId)
    const index = list.creator_ids.findIndex(creator_id => creator_id === params.creatorId)
    list.creator_ids.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
