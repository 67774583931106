<template lang='pug'>
  .a-popover(:class="{'a-popover--show': isShowPopOver}")
    slot
</template>
<script>


export default {
  name: 'Popover',
  props: {
    isShowPopOver: {
      type: Boolean,
      required: true,
      default: false
    }
  },
}
</script>
<style lang='scss' scoped>
.a-popover {
  min-width: 180px;
  pointer-events: none;
  background: var(--color-mono-c);
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
  opacity: 0;
  transition: opacity .3s;

  &--show {
    pointer-events: auto;
    opacity: 1;
  }
}
</style>
