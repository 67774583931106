<template lang='pug'>
transition(name='fade')
  .add-list-modal__wrapper(v-if='creator')
    .add-list-modal__container
      .add-list-modal__header
        .add-list-modal__title お気に入りクリエイターに追加
      .add-list-modal__body
        .add-list-modal__body-top
          .add-list-modal__body-top-text
            .add-list-modal__description クリエイターをお気に入りして他の組織メンバーと共有します
            .add-list-modal__annotation ※マイメニューの「お気に入り」から確認できます。
          transition(name='fade')
            button.add-list-modal__create-new-list(v-if='!showingCreateForm' @click='showCreateForm')
              i.add-list-modal__create-new-list-icon.crevo-icon.crevo-add_fill
              span.add-list-modal__create-new-list-text グループ作成
        creator-list-create-form(:showing="showingCreateForm" @hide='hideCreateForm')
        ul.add-list-modal__creator-lists
          creator-list(v-for='list in orderedCreatorLists' :key='list.id' :id='list.id' :title='list.title' :creatorIds='list.creator_ids' :creatorId='creator.id')
    .add-list-modal__user-info
      img.add-list-modal__user-icon(:src='creator.profile_image_url')
      .add-list-modal__user-text
        .add-list-modal__user-name {{creator.display_name}}
        .add-list-modal__user-jobs {{creator.creator_job_types}}
    .add-list-modal__background(@click='hide')
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import CreatorList from './creator-list'
import CreatorListCreateForm from './creator-list-create-form'

export default {
  components: {
    'creator-list': CreatorList,
    'creator-list-create-form': CreatorListCreateForm
  },
  data() {
    return {
      showingCreateForm: false
    }
  },
  computed: {
    ...mapGetters('addListModal', ['creator']),
    ...mapGetters('creatorLists', { creatorLists: 'all' }),
    orderedCreatorLists() {
      return [...this.creatorLists].sort((a, b) => b.id - a.id)
    }
  },
  methods: {
    ...mapMutations('addListModal', { hide: 'unset' }),
    showCreateForm() {
      this.showingCreateForm = true
    },

    hideCreateForm() {
      this.showingCreateForm = false
    }
  }
}
</script>

<style lang='scss' scoped>
.add-list-modal {
  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
    z-index: 10000;
    transition: .3s;

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 400px;
    background-color: var(--color-mono-c);
    border-radius: 4px;
    z-index: 2;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: var(--color-mono-a);
    border-bottom: 1px solid var(--color-mono-7);
  }

  &__title {
    font-size: 14px;
    color: var(--color-mono-1);
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 25px 30px 20px;
    min-height: 0;
  }

  &__body-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 25px;
  }

  &__body-top-text {
    letter-spacing: -.3px;
    color: var(--color-mono-4);
  }

  &__description {
    font-size: 12px;
  }

  &__annotation {
    font-size: 11px;
  }

  &__create-new-list {
    color: var(--color-text-link);
    background-color: transparent;
    outline: none;
    appearance: none;
    border: 0;
    transition: .3s;

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }
  }

  &__create-new-list-icon {
    display: inline-flex;
    font-size: 10px;
  }

  &__create-new-list-text {
    font-size: 12px;
    letter-spacing: -.3px;
  }

  &__creator-lists {
    margin-top: 13px;
    overflow: auto;
  }

  &__user-info {
    display: flex;
    align-items: center;
    width: 500px;
    height: 80px;
    margin-top: 15px;
    padding: 20px;
    border-radius: 5px;
    background-color: var(--color-mono-c);
    z-index: 10000;
  }

  &__user-icon {
    height: 40px;
    width: 40px;
    margin-right: 13px;
    border-radius: 20px;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }

  &__user-text {
    flex: 1;
  }

  &__user-name {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-mono-1);
  }

  &__user-jobs {
    font-size: 12px;
    color: var(--color-mono-3);
  }

  &__background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-mono-1);
    opacity: .7;
  }
}
</style>
