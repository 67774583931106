<template lang="pug">
  Card.o-audition-requirements
    dl.o-audition-requirements__item
      dt.o-audition-requirements__title 募集期限
      dd.o-audition-requirements__description {{ formattedLimitDate }}JST
    dl.o-audition-requirements__item
      dt.o-audition-requirements__title
        | 納品希望日
      dd.o-audition-requirements__description.o-audition-requirements__description--deliverly-date
        span.o-audition-requirements__negotiable(v-if="negotiable") 交渉可
        span {{ formattedDeliveryDate }}
    dl.o-audition-requirements__item(v-if="contractStatus")
      dt.o-audition-requirements__title 受注ステータス
      dd.o-audition-requirements__description {{ contractStatus }}
    dl.o-audition-requirements__item.o-audition-requirements__item--price
      dt.o-audition-requirements__title
        | 募集金額
        br
        | (税抜)
      dd.o-audition-requirements__description {{ formattedPrice }}
</template>

<script>
import 'dayjs/locale/ja'
import dayjs from 'dayjs'
import Card from 'src/components/atoms/Card'
import jobOfferPriceRanges from 'src/lib/creatorAudition/jobOfferPriceRanges'
import { formattedNumberComma } from 'src/lib/utility.js'

export default {
  name: 'AuditionRequirements',
  components: {
    Card
  },
  props: {
    deliveryDate: {
      type: [Date, String],
      required: true,
    },
    limitDate: {
      type: [Date, String],
      required: true,
    },
    negotiable: {
      type: Boolean,
      required: true,
    },
    contractStatus: {
      type: String,
      required: false,
      default: '',
    },
    price: {
      type: Number,
      required: false,
      default: null
    },
    priceRangeId: {
      type: Number,
      required: false,
      default: null
    },
    budgetType: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedLimitDate() {
      return dayjs(this.limitDate).locale('ja').format('MM/DD(dd) HH:mm')
    },
    formattedDeliveryDate() {
      return dayjs(this.deliveryDate).locale('ja').format('MM/DD(dd)')
    },
    formattedPrice() {
      if (this.budgetType === 'specify') {
        return `¥${formattedNumberComma(this.price)}`
      } else {
        const matchedRange = jobOfferPriceRanges.find(item => item.value === this.priceRangeId)
        return matchedRange.label
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.o-audition-requirements {
  width: 250px;
  padding: 26px 20px 20px;
  @include mq-down(narrow-pc--under) {
    width: 100%;
  }
  @include mq-down(tablet--under) {
    border-radius: 5px;
  }
  &__item {
    @include flex(between, center);
    margin-bottom: 18px;
    &:last-of-type {
      margin-bottom: 0;
    }
    &--price {
      padding: 12px 14px;
      margin-top: 22px;
      line-height: 1.3;
      color: var(--color-red);
      background: rgba(#e60012, .1);
      border-radius: 5px;
      @include mq-down(tablet--under) {
        padding: 11px 12px;
      }
    }
  }
  &__title {
    font-size: 13px;
    &--negotiable {
      @include flex(start, center);
    }
  }
  &__description {
    font-size: 13px;
    font-weight: bold;
    text-align: right;
    &--deliverly-date {
      @include flex(end);
    }
  }
  &__negotiable {
    padding: 4px;
    margin: -1px 4px 0 0;
    font-size: 10px;
    line-height: 1.1;
    color: var(--color-mono-2);
    border: 1px solid var(--color-mono-2);
    border-radius: 5px;
    transform: scale(.8);
  }
  &__status {
    padding: 44px 0 16px;
    font-size: 16px;
    font-weight: bold;
    color: var(--color-mono-4);
    text-align: center;
  }
}
</style>
