<template lang='pug'>
  .m-info-panel
    .m-info-panel__icon
      img(:src="iconPath")
    h3.m-info-panel__title
      | {{ title }}
    .m-info-panel__description
      template(v-if="isCreator")
        span {{ $t('creatorAuditions.detail.infoPanel.toCreatorAttention') }}
      template(v-if="isCreator && description")
        br
      template(v-if="description") {{ description }}
</template>

<script>
import icon from '@images/common/icon_i.png'

export default {
  name: 'InfoPanel',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    isCreator: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      iconPath: icon
    }
  }
}
</script>

<style lang="scss" scoped>
  .m-info-panel {
    display: grid;
    grid-template:
      " .    .   .      .      . "  40px
      " .  icon  .    title    . "  auto
      " .  icon  .      .      . "  8px
      " .  icon  . description . "  auto
      " .    .   .      .      . "  40px
      / 40px 60px 20px auto 30px;
    width: 100%;
    background: var(--color-mono-b);
    border-radius: 5px;

    @include mq-down(tablet--under) {
      grid-template:
        " .      .      . "  28px
        " .    title    . "  auto
        " .      .      . "  14px
        " . description . "  auto
        " .      .      . "  24px
        / 28px auto 24px;
    }

    &__icon {
      @include flex(center);
      grid-area: icon;
      width: 60px;
      height: 60px;
      color: var(--color-mono-2);
      background: var(--color-mono-7);
      border-radius: 100px;

      @include mq-down(tablet--under) {
        display: none;
      }
    }

    &__title {
      grid-area: title;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.1;
      color: var(--color-mono-2);
    }

    &__description {
      grid-area: description;
      overflow: hidden;
      font-size: 14px;
      line-height: 1.6;
      color: var(--color-mono-1);
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }
  }
</style>
