<template lang='pug'>
  span.a-label-text {{ text }}
</template>

<script>
export default {
  name: 'LabelText',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang='scss' scoped>
// stylesheets/collet/components/atoms/_label_text.scssを参照
</style>
