import axios from '@axios'
import dayjs from 'dayjs'
import { serialize } from 'object-to-formdata'
import 'dayjs/locale/ja'
import isPlayableVideoUrl from 'src/lib/playableMovieUrl'

export default {
  namespaced: true,
  state: {
    audition_id: '',
    is_en: false,
    audition_name: null,
    audition_name_en: null,
    icon_url: null, // サムネイル取得用
    icon: null, // 独自サムネイルをアップする際に使用
    icon_name: null, // 既存サムネイルをアップする際に使用
    offer_type: 'package',
    film_genre: 'animation',
    job_part_identifiers: [],
    budget_type: 'proposal',
    offer_price: null,
    offer_price_range: null,
    audition_rate: null,
    delivery_of_goods: null,
    delivery_of_goods_en: null,
    requested_deliverly_date: '',
    shooting_location: null,
    shooting_location_en: null,
    deliverly_date_negotiable: false,
    requested_movie_time_and_number: null,
    requested_movie_time_and_number_en: null,
    service_description: null,
    service_description_en: null,
    reference_movies: [],
    production_schedule: null,
    production_schedule_en: null,
    client_name: null,
    client_name_en: null,
    accuracy_of_contract: null,
    publishing_media_types: [],
    publishing_media: null,
    publishing_media_en: null,
    service_target: null,
    service_target_en: null,
    closing_on_date: '',
    closing_on_time: '',
    recruitment_scope: 'public',
    publication_scope: 'public',
    human_image: null,
    human_image_en: null,
    other_conditions:
      '■本件は、依頼主との直接契約になります。\n　仕事内容や条件面は、依頼主とクリエイター間での調整となります。ご注意ください。\n■受注前のため、発注を確約できるものではありません。\n　(勝者なしでクローズとなる可能性があります)',
    other_conditions_en: null,
    workflow_state: 'draft',
    videoInfos: [],
    creatorAuditionError: null,
    creatorAuditionLoading: true,
    isCreatorAuditionSet: false, // NOTE: 入力画面を開いたときに1度だけAPIからデータを取得するためのフラグ
    referrerOfForm: null, // NOTE: 入力画面へ遷移したときのreferrer
    isToConfirmClicked: false, // NOTE: 入力画面から確認画面へ遷移するときに「確認画面へ」ボタンをクリックして遷移したかどうかを判別する
  },
  actions: {
    async fetchCreatorAudition({ state, commit }) {
      try {
        const { data } = await axios.get(
          `/api/v2/creator_auditions/${state.audition_id}/edit`
        )
        commit('setCreatorAudition', data.creator_audition)
      } catch (err) {
        commit('setError', err)
      }
    },
    async fetchAdminCreatorAuditionWithTranslations({ commit, state }) {
      try {
        const { data } = await axios.get(
          `/api/v2/admin/creator_auditions/${state.audition_id}/edit`
        )

        commit(
          'setAdminCreatorAuditionWithTranslations',
          data.creator_audition
        )
      } catch (err) {
        commit('setError', err)
      }
    },
    fetchVideoInfos({ commit, state }) {
      state.reference_movies.map((movie) => {
        if (isPlayableVideoUrl(movie.url)) {
          axios.get(`/api/v2/video_info?url=${movie.url}`).then((res) => {
            commit('addVideoInfo', {
              reason: movie.reasonJA,
              thumbnailURL: res.data.video_info.thumbnail_url,
              url: res.data.video_info.url,
              embedURL: res.data.video_info.embed_url,
            })
          })
        } else {
          commit('addVideoInfo', {
            reason: movie.reasonJA,
            thumbnailURL: null,
            url: movie.url,
            embedURL: null,
          })
        }
      })
    },
    async saveDraftCreatorAudition({ state }) {
      const params = toSaveAndApplyCreatorAuditionParams(state)
      const formData = serialize(params, {
        indices: false,
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
        allowEmptyArrays: true,
      })

      if (state.audition_id === '') {
        return await axios.post('/api/v2/creator_auditions', formData, {
          headers: { 'content-type': 'multipart/form-data' },
        })
      } else {
        return await axios.put(
          `/api/v2/creator_auditions/${state.audition_id}`,
          formData,
          { headers: { 'content-type': 'multipart/form-data' } }
        )
      }
    },
    async deleteDraftCreatorAudition({ state }) {
      return await axios.delete(
        `/api/v2/creator_auditions/${state.audition_id}`
      )
    },
    async updateCreatorAudition({ state }, { updateStatus }) {
      const params = toUpdateCreatorAuditionParams(state, updateStatus)
      const formData = serialize(params, {
        indices: false,
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
        allowEmptyArrays: true,
      })
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status === 422,
      }

      return await axios.put(
        `/api/v2/admin/creator_auditions/${state.audition_id}`,
        formData,
        config
      )
    },
    async applyCreatorAudition({ state }) {
      const params = toSaveAndApplyCreatorAuditionParams(state)
      const formData = serialize(params, {
        indices: false,
        nullsAsUndefineds: true,
        booleansAsIntegers: true,
        allowEmptyArrays: true,
      })
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        validateStatus: (status) =>
          (status >= 200 && status < 300) || status === 422,
      }
      if (state.audition_id === '') {
        // NOTE：新規の場合はaudition_idが無いのでまず下書きAPIを一回叩いてから申請APIを投げる
        const { data } = await axios.post(
          '/api/v2/creator_auditions',
          formData,
          config
        )
        const newAuditionId = data.creator_audition.id
        return await axios.post(
          `/api/v2/creator_auditions/${newAuditionId}/request`,
          formData,
          config
        )
      } else {
        return await axios.post(
          `/api/v2/creator_auditions/${state.audition_id}/request`,
          formData,
          config
        )
      }
    },
  },
  getters: {
    auditionId: (state) => {
      return state.audition_id
    },
    closingOn: (state) => {
      return toISO8601(state.closing_on_date, state.closing_on_time)
    },
    backUrl: (state) => {
      const isFormUrl =
        state.referrerOfForm &&
        (state.referrerOfForm.match(/\/basecamp\/job_offers\/\d+\/edit/) !==
          null ||
          state.referrerOfForm.includes('/basecamp/job_offers/new'))
      // NOTE: 遷移元URLが編集or新規作成画面のURLだった場合は自社依頼に遷移させる
      return isFormUrl
        ? '/basecamp/job_offers/histories'
        : state.referrerOfForm
    },
  },
  mutations: {
    setCreatorAudition(state, audition) {
      state.audition_name = audition.audition_name
      state.icon_url = audition.icon_url
      state.icon_name = audition.icon_name
      state.offer_type = audition.offer_type
      state.film_genre = audition.film_genre
      state.job_part_identifiers = audition.job_part_identifiers
      state.budget_type = audition.budget_type
      state.offer_price = audition.offer_price
      state.offer_price_range = audition.offer_price_range
        ? parseInt(audition.offer_price_range, 10)
        : null
      state.audition_rate = audition.audition_rate
      state.delivery_of_goods = audition.delivery_of_goods
      state.requested_deliverly_date = audition.requested_deliverly_date ?? ''
      state.shooting_location = audition.shooting_location
      state.deliverly_date_negotiable = audition.deliverly_date_negotiable
      state.requested_movie_time_and_number =
        audition.requested_movie_time_and_number
      state.service_description = audition.service_description
      state.reference_movies = audition.reference_movies.map(
        ({ url, reason }) => ({ url, reasonJA: reason })
      )
      state.production_schedule = audition.production_schedule
      state.client_name = audition.client_name
      state.accuracy_of_contract = audition.accuracy_of_contract
      state.publishing_media_types = audition.publishing_media_types
      state.publishing_media = audition.publishing_media
      state.service_target = audition.service_target
      if (audition.closing_on !== null) {
        state.closing_on_date = dayjs(audition.closing_on)
          .locale('ja')
          .format('YYYY-MM-DD')
        state.closing_on_time = dayjs(audition.closing_on)
          .locale('ja')
          .format('HH:mm')
      } else {
        state.closing_on_date = ''
        state.closing_on_time = ''
      }
      state.recruitment_scope = audition.recruitment_scope
      state.publication_scope = audition.publication_scope
      state.human_image = audition.human_image
      state.other_conditions = audition.other_conditions
      state.creatorAuditionError = audition.creatorAuditionError
    },
    setAdminCreatorAuditionWithTranslations(state, audition) {
      state.audition_name = audition.ja.audition_name
      state.icon_url = audition.icon_url
      state.film_genre = audition.film_genre
      state.job_part_identifiers = audition.job_part_identifiers
      state.budget_type = audition.budget_type
      state.offer_type = audition.offer_type
      state.offer_price = audition.offer_price
      state.offer_price_range = audition.offer_price_range
        ? parseInt(audition.offer_price_range, 10)
        : null
      state.audition_rate = audition.audition_rate
      state.delivery_of_goods = audition.ja.delivery_of_goods
      state.requested_deliverly_date = audition.requested_deliverly_date ?? ''
      state.shooting_location = audition.ja.shooting_location
      state.deliverly_date_negotiable = audition.deliverly_date_negotiable
      state.requested_movie_time_and_number =
        audition.ja.requested_movie_time_and_number
      state.service_description = audition.ja.service_description
      state.reference_movies = audition.reference_movies.map((movie) => ({
        url: movie.url,
        reasonJA: movie.reason_ja,
        reasonEN: movie.reason_en,
      }))
      state.production_schedule = audition.ja.production_schedule
      state.client_name = audition.ja.client_name
      state.accuracy_of_contract = audition.accuracy_of_contract
      state.publishing_media_types = audition.publishing_media_types
      state.publishing_media = audition.ja.publishing_media
      state.service_target = audition.ja.service_target
      if (audition.closing_on !== null) {
        state.closing_on_date = dayjs(audition.closing_on)
          .locale('ja')
          .format('YYYY-MM-DD')
        state.closing_on_time = dayjs(audition.closing_on)
          .locale('ja')
          .format('HH:mm')
      } else {
        state.closing_on_date = ''
        state.closing_on_time = ''
      }
      state.recruitment_scope = audition.recruitment_scope
      state.publication_scope = audition.publication_scope
      state.human_image = audition.ja.human_image
      state.other_conditions = audition.ja.other_conditions
      state.workflow_state = audition.workflow_state

      if (audition.en === null) {
        state.is_en = false
      } else {
        state.is_en = true
        state.audition_name_en = audition.en.audition_name
        state.delivery_of_goods_en = audition.en.delivery_of_goods
        state.shooting_location_en = audition.en.shooting_location
        state.requested_movie_time_and_number_en =
          audition.en.requested_movie_time_and_number
        state.service_description_en = audition.en.service_description
        state.production_schedule_en = audition.en.production_schedule
        state.client_name_en = audition.en.client_name
        state.service_target_en = audition.en.service_target
        state.human_image_en = audition.en.human_image
        state.other_conditions_en = audition.en.other_conditions
        state.publishing_media_en = audition.en.publishing_media
      }
    },
    setAudiitonId(state, id) {
      state.audition_id = id
    },
    setIsEN(state, isEN) {
      state.is_en = isEN
    },
    setAuditionId(state, audition_id) {
      state.audition_id = audition_id
    },
    setAuditionName(state, name) {
      state.audition_name = name
    },
    setAuditionNameEN(state, name) {
      state.audition_name_en = name
    },
    setCustomIcon(state, data) {
      state.icon = data
    },
    setIconName(state, name) {
      state.icon_name = name
    },
    setOfferPrice(state, price) {
      state.offer_price = price
    },
    setDeliveryOfGoods(state, goods) {
      state.delivery_of_goods = goods
    },
    setDeliveryOfGoodsEN(state, goods) {
      state.delivery_of_goods_en = goods
    },
    setBudgetType(state, type) {
      state.budget_type = type
    },
    setOfferType(state, type) {
      state.offer_type = type
    },
    setFilmGenre(state, genre) {
      state.film_genre = genre
    },
    setJobPartIdentifiers(state, identifier) {
      state.job_part_identifiers = identifier
    },
    setOfferPriceRange(state, price) {
      state.offer_price_range = price
    },
    setRequestedDeliverlyDate(state, date) {
      state.requested_deliverly_date = date
    },
    setShootingLocation(state, location) {
      state.shooting_location = location
    },
    setShootingLocationEN(state, location) {
      state.shooting_location_en = location
    },
    setDeliveryDateNegotiable(state, negotiable) {
      state.deliverly_date_negotiable = negotiable
    },
    setRequestedMovieTimeNumber(state, data) {
      state.requested_movie_time_and_number = data
    },
    setRequestedMovieTimeNumberEN(state, data) {
      state.requested_movie_time_and_number_en = data
    },
    setServiceDescription(state, description) {
      state.service_description = description
    },
    setServiceDescriptionEN(state, description) {
      state.service_description_en = description
    },
    setReferenceMovies(state, referenceMovie) {
      // NOTE：中身が空の配列があったら削除して整形
      const filterdReferenceMovie = referenceMovie.filter(
        (data) => data.url !== '' || data.reasonJA !== ''
      )
      state.reference_movies = filterdReferenceMovie
    },
    setProductionSchedule(state, schedule) {
      state.production_schedule = schedule
    },
    setProductionScheduleEN(state, schedule) {
      state.production_schedule_en = schedule
    },
    setClientName(state, name) {
      state.client_name = name
    },
    setClientNameEN(state, name) {
      state.client_name_en = name
    },
    setAccuracyOfContract(state, status) {
      state.accuracy_of_contract = status
    },
    setPublishingMediaTypes(state, types) {
      state.publishing_media_types = types
    },
    setPublishingMedia(state, publishingMedia) {
      state.publishing_media = publishingMedia
    },
    setPublishingMediaEN(state, publishingMedia) {
      state.publishing_media_en = publishingMedia
    },
    setServiceTarget(state, target) {
      state.service_target = target
    },
    setServiceTargetEN(state, target) {
      state.service_target_en = target
    },
    setClosingOnDate(state, date) {
      state.closing_on_date = date
    },
    setClosingOnTime(state, time) {
      state.closing_on_time = time
    },
    setRecruitmentScope(state, scope) {
      state.recruitment_scope = scope
    },
    setPublicationScope(state, scope) {
      state.publication_scope = scope
    },
    setHumanImage(state, humanImage) {
      state.human_image = humanImage
    },
    setHumanImageEN(state, humanImage) {
      state.human_image_en = humanImage
    },
    setOtherConditions(state, otherConditions) {
      state.other_conditions = otherConditions
    },
    setOtherConditionsEN(state, otherConditions) {
      state.other_conditions_en = otherConditions
    },
    setCreatorAuditionLoading(state, loading) {
      state.creatorAuditionLoading = loading
    },
    setIsCreatorAuditionSet(state, bool) {
      state.isCreatorAuditionSet = bool
    },
    setReferrerOfForm(state, referrer) {
      state.referrerOfForm = referrer
    },
    setIsToConfirmClicked(state, bool) {
      state.isToConfirmClicked = bool
    },
    setError(state, error) {
      if (error) state.creatorAuditionsError = error
    },
    addVideoInfo(state, movie) {
      state.videoInfos.push(movie)
    },
    setVideoInfos(state, videoInfos) {
      state.videoInfos = videoInfos
    },
    setIconURL(state, url) {
      state.icon_url = url
    },
  },
}

const toISO8601 = (date, time) => {
  if (date === '' || time === '') return null

  return `${date}T${time}:00+09:00`
}

const toSaveAndApplyCreatorAuditionParams = (state) => {
  return {
    creator_audition: {
      icon: state.icon,
      icon_name: state.icon_name,
      audition_name: state.audition_name,
      offer_type: state.offer_type,
      film_genre: state.film_genre,
      job_part_identifiers: state.job_part_identifiers,
      budget_type: state.budget_type,
      offer_price: state.offer_price,
      offer_price_range: state.offer_price_range,
      delivery_of_goods: state.delivery_of_goods,
      requested_deliverly_date: state.requested_deliverly_date,
      shooting_location: state.shooting_location,
      deliverly_date_negotiable: state.deliverly_date_negotiable,
      requested_movie_time_and_number: state.requested_movie_time_and_number,
      service_description: state.service_description,
      reference_movies: state.reference_movies.map(({ url, reasonJA }) => ({
        url,
        reason: reasonJA,
      })),
      production_schedule: state.production_schedule,
      client_name: state.client_name,
      accuracy_of_contract: state.accuracy_of_contract,
      publishing_media_types: state.publishing_media_types,
      publishing_media: state.publishing_media,
      closing_on: toISO8601(state.closing_on_date, state.closing_on_time),
      service_target: state.service_target,
      recruitment_scope: state.recruitment_scope,
      publication_scope: state.publication_scope,
      human_image: state.human_image,
      other_conditions: state.other_conditions,
    },
  }
}

const toUpdateCreatorAuditionParams = (state, updateStatus) => {
  const enParams = state.is_en
    ? {
        audition_name: state.audition_name_en,
        delivery_of_goods: state.delivery_of_goods_en,
        shooting_location: state.shooting_location_en,
        requested_movie_time_and_number:
          state.requested_movie_time_and_number_en,
        service_description: state.service_description_en,
        production_schedule: state.production_schedule_en,
        client_name: state.client_name_en,
        service_target: state.service_target_en,
        human_image: state.human_image_en,
        other_conditions: state.other_conditions_en,
        publishing_media: state.publishing_media_en,
      }
    : null

  return {
    creator_audition: {
      update_status: updateStatus,
      language_codes: state.is_en ? ['ja', 'en'] : ['ja'],
      icon: state.icon_name ? null : state.icon,
      icon_name: state.icon_name,
      offer_type: state.offer_type,
      film_genre: state.film_genre,
      job_part_identifiers: state.job_part_identifiers,
      budget_type: state.budget_type,
      offer_price: state.offer_price,
      offer_price_range: state.offer_price_range,
      requested_deliverly_date: state.requested_deliverly_date,
      deliverly_date_negotiable: state.deliverly_date_negotiable,
      reference_movies: state.reference_movies.map(
        ({ url, reasonJA, reasonEN }) =>
          state.is_en
            ? { url, reason_ja: reasonJA, reason_en: reasonEN }
            : { url, reason_ja: reasonJA }
      ),

      accuracy_of_contract: state.accuracy_of_contract,
      publishing_media_types: state.publishing_media_types,
      closing_on: toISO8601(state.closing_on_date, state.closing_on_time),
      recruitment_scope: state.recruitment_scope,
      publication_scope: state.publication_scope,
      ja: {
        audition_name: state.audition_name,
        delivery_of_goods: state.delivery_of_goods,
        shooting_location: state.shooting_location,
        requested_movie_time_and_number: state.requested_movie_time_and_number,
        service_description: state.service_description,
        production_schedule: state.production_schedule,
        client_name: state.client_name,
        service_target: state.service_target,
        human_image: state.human_image,
        other_conditions: state.other_conditions,
        publishing_media: state.publishing_media,
      },
      en: enParams,
    },
  }
}
