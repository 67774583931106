export default [
  { id: 'tv_cm'           , isOld: false },
  { id: 'web_ad'          , isOld: false },
  { id: 'website'         , isOld: false },
  { id: 'traffic'         , isOld: false },
  { id: 'event_signage'   , isOld: false },
  { id: 'other'           , isOld: false },
  // NOTE：以下は配信媒体の古いデータ用
  { id: 'corporate_site'  , isOld: true  },
  { id: 'service_site'    , isOld: true  },
  { id: 'facebook'        , isOld: true  },
  { id: 'youtube'         , isOld: true  },
  { id: 'twitter'         , isOld: true  },
  { id: 'vimeo'           , isOld: true  },
  { id: 'appstore'        , isOld: true  },
  { id: 'event'           , isOld: true  },
  { id: 'digital_signage' , isOld: true  },
  { id: 'sales_material'  , isOld: true  },
  { id: 'newsletter'      , isOld: true  },
]
