<template lang='pug'>
transition(name='fade')
  .portfolio-modal(v-if='creative')
    button.portfolio-modal__left(@click='prev()' :class='canDecrease || "portfolio-modal__left--disabled"')
      i.crevo-icon.crevo-arrow2_left
    .portfolio-modal__container
      .portfolio-modal__header
        a.portfolio-modal__creator(:href="creator.portfolio_path" target="_blank")
          user-icon.portfolio-modal__creator-icon(:user='creator' size='l')
          .portfolio-modal__creator-name {{creator.display_name}}
          .portfolio-modal__creator-job-types(v-if='creator.creator_job_types') {{ `（${creator.creator_job_types}）` }}
        .portfolio-modal__actions
          button.portfolio-modal__add-list-button(v-if='canManageCreatorList' @click='showAddListModal(creator)' :class='listIconClassByListState')
            add-list-icon.track_portfolios_add_list_icon(:isAdded='isAdded')
      .portfolio-modal__body
        .portfolio-modal__creative-file-area(@click.right.prevent="banDownload")
          video.portfolio-modal__creative-file.portfolio-modal__creative-file--video(preload="auto" autoplay controls controlslist="nodownload" :src='creative.file_uri' v-if='creative.file_type === "movie" && !hideVideo')
          audio.portfolio-modal__creative-file.portfolio-modal__creative-file--audio(:src='creative.file_uri' autoplay controls controlslist="nodownload" v-else-if='creative.file_type === "audio"')
          .portfolio-modal__creative-file__iframe-wrapper(v-else-if='creative.embed_url')
            iframe.portfolio-modal__creative-file__iframe(
              :src='`${creative.embed_url}?autoplay=1&rel=0&showinfo=0`',
              frameborder='0',
              data-key='value'
            )
          img.portfolio-modal__creative-file(:src='creative.thumbnail_url' v-else-if='creative.thumbnail_url')
          .portfolio-modal__creative-file.portfolio-modal__creative-file--other(v-html="creative.file_icon" v-else)
        .portfolio-modal__creative-info
          a.portfolio-modal__creative-title(target='_blank' :href="creative.portfolio_url") {{ creative.title }}
          .portfolio-modal__creative-description {{ creative.description }}
          dl.portfolio-modal__creative-detail
            .portfolio-modal__creative-specializations
              dt.portfolio-modal__creative-detail-label 担当領域：
              dd.portfolio-modal__creative-detail-value {{ creative.specialization_names.join(" / ") }}
            .portfolio-modal__creative-related-url(v-if='creative.related_url')
              dt.portfolio-modal__creative-detail-label 関連URL：
              dd.portfolio-modal__creative-detail-value
                a.portfolio-modal__link(:href="creative.related_url" target='_blank') {{ creative.related_url }}
            .portfolio-modal__creative-tags(v-if='creative.free_word_tag_list && creative.free_word_tag_list.length > 0')
              dt.portfolio-modal__creative-detail-label タグ：
              dd.portfolio-modal__creative-detail-value {{ creative.free_word_tag_list.join(" / ") }}
    button.portfolio-modal__right(@click='next()' :class='canIncrease || "portfolio-modal__right--disabled"')
      i.crevo-icon.crevo-arrow2_right
    .portfolio-modal__background(@click='hide')
      i.crevo-icon.crevo-close.portfolio-modal__close
</template>

<script>
import AddListIcon from 'vue-app/libs/components/creator_list/add-list-icon.vue'
import UserIcon from 'vue-app/libs/components/user-icon.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    'user-icon': UserIcon,
    'add-list-icon': AddListIcon
  },

  data() {
    return {
      hideVideo: false // NOTE: IE は video の src を切り替えても読み込んでくれないため
    }
  },

  computed: {
    ...mapGetters('PortfolioModal', { creatives: 'creatives', index: 'index', selectedCreator: 'creator' }),
    ...mapGetters('creatorLists', { creatorLists: 'all' }),
    ...mapGetters('currentUser', ['canManageCreatorList']),

    creative() {
      return this.creatives[this.index]
    },

    creator() {
      // NOTE クリエイター検索と異なり、作品検索では、作品毎のクリエイター情報を参照する必要があるため、分岐させている
      return this.creative.creator ? this.creative.creator : this.selectedCreator
    },

    isAdded() {
      return this.$flatten(this.creatorLists.map(list => list.creator_ids)).includes(this.creator.id)
    },

    listIconClassByListState() {
      return this.isAdded ? 'portfolio-modal__add-list-button--is-added' : ''
    },

    canDecrease() {
      return this.index > 0
    },

    canIncrease() {
      return this.index < this.creatives.length - 1
    }
  },

  methods: {
    ...mapMutations('addListModal', { setAddListModal: 'set' }),
    ...mapMutations('PortfolioModal', ['set', 'increase', 'decrease']),

    next() {
      this.hideVideo = true
      this.increase()
      this.$nextTick(() => this.hideVideo = false)
    },

    prev() {
      this.hideVideo = true
      this.decrease()
      this.$nextTick(() => this.hideVideo = false)
    },

    showAddListModal(creator) {
      this.setAddListModal(creator)
      this.hide()
    },

    hide() {
      this.set({ creatives: [], creator: null, index: 0 })
    },

    // 右クリックでの作品のダウンロードを無効
    banDownload() {
      return false
    }
  }
}
</script>

<style lang='scss'>
// FIXME: 仮実装。本来 user-icon 側に入れるべき
.u-user-avatar-initial {
  object-fit: cover;
}
</style>

<style lang='scss' scoped>
.portfolio-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  transition: .3s;

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }

  &__left,
  &__right {
    z-index: 2;
    font-size: 30px;
    color: var(--color-mono-c);
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    opacity: .8;
    transition: .3s opacity;
    appearance: none;

    &:hover {
      opacity: 1;
    }
  }

  &__left.portfolio-modal__left--disabled,
  &__right.portfolio-modal__right--disabled {
    pointer-events: none;
    cursor: auto;
    opacity: .5;
  }

  &__container {
    z-index: 2;
    width: 820px;
    min-height: 200px; /* 仮設定 */
    margin: 0 40px;
    background-color: var(--color-mono-c);
    border-radius: 5px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px;
  }

  &__creator {
    display: flex;
    align-items: center;
  }

  &__creator-icon {
    margin-right: 14px;
  }

  &__creator-name {
    font-size: 14px;
    color: var(--color-mono-1);
  }

  &__creator-job-types {
    color: var(--color-mono-1);
  }

  &__creative-file-area {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 60vh;
    background-color: var(--color-mono-1);
  }

  &__creative-file {
    max-width: 100%;
    min-height: 30vh;

    &--audio,
    &--other {
      width: 100%;
      margin: 50px;
    }

    &--audio {
      outline: none;
    }

    &--video {
      max-width: 820px;
      max-height: 60vh; // 親要素のmax-heightに合わせる
      outline: none;
    }

    &__iframe-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      // NOTE: レスポンシブな Iframe にするための対応
      //       https://github.com/crevo-inc/crevo/pull/10199#discussion_r287316678
      padding-top: calc(9 / 16 * 100%);
    }

    &__iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &--other {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10vh;
      color: var(--color-mono-c);
    }
  }

  &__actions {
    display: flex;
    font-size: 18px;
    color: var(--color-mono-4);

    > .crevo-icon:not(:last-child) {
      margin-right: 30px;
    }
  }

  &__add-list-button {
    color: var(--color-mono-4);
    background-color: transparent;
    border: 0;
    outline: 0;
    appearance: none;
    transition: .3s;

    &--is-added {
      opacity: 1;
    }
  }

  &__creative-info {
    padding: 30px;
  }

  &__creative-title {
    font-size: 15px;
    font-weight: bold;
    color: var(--color-mono-1);

    &:hover,
    &:active,
    &:focus {
      color: var(--color-mono-1);
    }
  }

  &__creative-description {
    @include lineclamp;
    max-height: 3.5em;
    margin-top: 5px;
    font-size: 12px;
    line-height: 1.8;

    &:before {
      @include lineclamp-before($right: 3px, $width: 1em);
      bottom: 3px;
    }

    &:after {
      @include lineclamp-after($right: -.7em, $width: 1em);
      height: 3.5em;
    }
  }

  &__creative-detail {
    margin: 0;
    font-size: 11px;

    > div {
      display: flex;

      &:not(first-child) {
        margin-top: 10px;
      }
    }
  }

  &__creative-detail-label {
    min-width: 60px;
    margin-right: 4px;
    font-weight: bold;
  }

  &__creative-detail-value {
    word-break: break-all;
  }

  &__link {
    color: var(--color-text-link);
    transition: .3s;

    &:hover,
    &:visited,
    &:active,
    &:focus {
      color: var(--color-text-link);
    }

    &:hover,
    &:active,
    &:focus {
      opacity: .8;
    }
  }

  &__background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-mono-1);
    opacity: .7;
  }

  &__close {
    position: fixed;
    top: 30px;
    right: 30px;
    font-size: 18px;
    color: var(--color-mono-c);
    opacity: .7;
    transition: .3s opacity;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
