import axios from '@axios'

export default {
  namespaced: true,
  state: {
    auditionId: null,
    budget_type: 'proposal',
    client_name: null,
    contract_type: null,
    delivery_of_goods: null,
    human_image: null,
    invited_creators: [],
    job_part_identifiers: [],
    matching_fee_rate: null,
    offer_price: null,
    offer_price_range: null,
    other_conditions: null,
    owner: null,
    payment_day: null,
    production_schedule: null,
    publishing_media_types: [],
    publishing_media: null,
    reference_movie_ids: [],
    referral_fee_rate: null,
    requested_deliverly_date: null,
    requested_movie_time_and_number: null,
    reward: null,
    shooting_location: null,
    service_description: null,
    service_target: null,
    winner: null,
    workflow_state: null,
    referenceMovies: [],
    overviewLoading: true,
    overviewError: null,
    organization_id: null,
  },
  actions: {
    async fetchOverview({ state, commit }) {
      try {
        const { data } = await axios.get(`/api/v2/creator_auditions/${state.auditionId}/summary`)
        commit('setOverview', data.creator_audition)
      } catch(err) {
        commit('setError', err)
      }
    },
    fetchReferenceMovies({ state, commit }) {

      state.reference_movie_ids.forEach(videoId => {
        axios.get(`/api/v2/reference_movies/${videoId}`)
          .then(res => {
            commit('setReferenceMovies', res.data.reference_movie)
          })
          .catch(err => {
            commit('setError', err)
          })
      })
    },
  },
  getters: {
    requestedCreator: state => {
      return state.invited_creators.length > 0 ? state.invited_creators[0] : null
    },
    sortedReferenceMovies: state => {
      return [...state.referenceMovies].sort((a, b) => a.id - b.id )
    }
  },
  mutations: {
    setOverview(state, audition) {
      state.budget_type = audition.budget_type
      state.client_name = audition.client_name
      state.contract_type = audition.contract_type
      state.delivery_of_goods = audition.delivery_of_goods
      state.human_image = audition.human_image
      state.invited_creators = audition.invited_creators
      state.job_part_identifiers = audition.job_part_identifiers
      state.matching_fee_rate = audition.matching_fee_rate
      state.offer_price = audition.offer_price
      state.offer_price_range = audition.offer_price_range ? parseInt(audition.offer_price_range, 10) : null
      state.other_conditions = audition.other_conditions
      state.owner = audition.owner
      state.payment_day = audition.payment_day
      state.production_schedule = audition.production_schedule
      state.publishing_media_types = audition.publishing_media_types
      state.publishing_media = audition.publishing_media
      state.reference_movie_ids = audition.reference_movie_ids
      state.referral_fee_rate = audition.referral_fee_rate
      state.requested_deliverly_date = audition.requested_deliverly_date
      state.requested_movie_time_and_number = audition.requested_movie_time_and_number
      state.reward = audition.reward
      state.service_description = audition.service_description
      state.service_target = audition.service_target
      state.usage_fee = audition.usage_fee
      state.winner = audition.winner
      state.workflow_state = audition.workflow_state
      state.overviewError = audition.overviewError
      state.organization_id = audition.organization_id
      state.shooting_location = audition.shooting_location
    },
    setAuditionId(state, id) {
      state.auditionId = id
    },
    setReferenceMovies(state, { id, url, reason, thumbnail_url, embed_url }) {
      const data = {
        id,
        url,
        reason,
        thumbnailURL: thumbnail_url,
        embedURL: embed_url,
      }

      state.referenceMovies.push(data)
    },
    setOverviewLoading(state, loading) {
      state.overviewLoading = loading
    },
    setError(state, err) {
      state.overviewError = err
    }
  }
}
