<template lang='pug'>
  .basecamp-search__select-type(:style='selectTypeStyle')
    .basecamp-search__selected(@click.stop='toggleList', v-on-clickaway='hideList') {{ selectedName }}
    //- FIXME：vue-app配下はアイコンフォント読み込めないので対応必要
    .basecamp-search__caret
    ul.basecamp-search__main-types(:style='listStyle')
      li.basecamp-search__main-type(v-for='item in list', :item='item' @click.stop='select(item.identifier)') {{ item.name }}
</template>

<script>
import { mapActions } from 'vuex'
import selectList from '../mixins/select-list'

export default {
  mixins: [ selectList ],

  props: {
    defaultLabel: {
      type: String,
      required: true
    },
    list: {
      type: Array,
      required: true
    },
    selected: {
      type: Object,
      required: false,
      default: null
    },
    selectTypeWidth: {
      type: Number,
      required: true
    },
  },

  computed: {
    selectedName() {
      return this.selected ? this.selected.name : this.defaultLabel
    },

    selectTypeStyle() {
      return {
        width: `${this.selectTypeWidth}px`
      }
    }
  },

  methods: {
    ...mapActions('inputItems', { loadInputItems: 'load' }),
  }
}
</script>

<style lang="scss" scoped>
  .basecamp-search {
    &__select-type {
      position: relative;
      border-top-left-radius: 28px;
      border-bottom-left-radius: 28px;
      background-color: var(--color-mono-a);
      text-align: left;
    }

    &__selected {
      padding: 18px 22px 16px;
      font-size: 14px;
      color: var(--color-mono-2);
      border-right: 1px solid var(--color-mono-6);
      cursor: pointer;
    }

    &__caret {
      position: absolute;
      top: 25px;
      right: 18px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 4.5px 0 4.5px;
      border-color: var(--color-mono-2) transparent transparent transparent;
      pointer-events: none;
    }

    &__main-types {
      position: absolute;
      top: 10px;
      left: 5px;
      z-index: 10;
      max-height: 355px;
      padding: 10px;
      background-color: var(--color-mono-c);
      border-radius: 3px;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
      overflow-y: scroll;
      list-style-type: none;
      text-align: left;
      transition: .3s;
    }

    &__main-type {
      padding: 11px 17px;
      font-size: 13px;
      color: var(--color-mono-1);
      border-radius: 3px;
      transition: .3s background-color;
      cursor: pointer;

      &:hover {
        background-color: var(--color-mono-a);
      }
    }
  }
</style>
