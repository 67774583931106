<template lang='pug'>
  .t-form(:class="templateClass")
    .t-form__title
      slot(name="title")
    .t-form__description
      slot(name="description")
    .t-form__body
      slot(name="body")
    .t-form__footer
      slot(name="footer")
</template>
<script>

export default {
  name: 'FormTemplate',
  props: {
    color: {
      type: String,
      required: false,
      default: 'normal'
    }
  },
  computed: {
    templateClass() {
      return `${this.backgroundColor}`
    },

    backgroundColor() {
      const colors = ['normal', 'gray']

      return colors.includes(this.color) ? `t-form-background-color--${this.color}` : ''
    }
  }
}
</script>
<style lang="scss">
.t-form {
  display: grid;
  /*
  TODO: headerを追加する
        本来ならテンプレートとしてheaderがないといけない
        Rails側のviewのheaderを使っているので、headerをなくしている
  */
  grid-template:
    " .      .      . "  80px
    " .    title    . "  auto
    " .      .      . "  20px
    " . description . "  auto
    " .      .      . "  60px
    " .     body    . "  auto
    " .      .      . "  50px
    " .    footer   . "  auto
    " .      .      . "  minmax(50px, 1fr)
    / minmax(20px, 1fr) minmax(300px, 920px) minmax(20px, 1fr);

  &__title {
    grid-area: title;
    font-size: 26px;
    text-align: center;
  }

  &__description {
    grid-area: description;
    font-size: 14px;
    line-height: 1.8;
    text-align: center;
  }

  &__body {
    grid-area: body;
  }

  &__footer {
    grid-area: footer;
  }

  &-background-color {
    &--normal {
      background: var(--color-mono-c);
    }

    &--gray {
      background: var(--color-mono-9);
    }
  }
}
</style>
