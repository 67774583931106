<template lang='pug'>
  .m-user-profile-unit(:class="profileClass")
    a.m-user-profile-unit__body.m-user-profile-unit__body--link(
      v-if="withLink"
      @click="openCreatorProfile"
    )
      UserIcon.m-user-profile-unit__thumbnail(:user="user" :size="thumbnailSize")
      p.m-user-profile-unit__name {{ userName }}
    .m-user-profile-unit__body(v-else)
      UserIcon.m-user-profile-unit__thumbnail(:user="user" :size="thumbnailSize")
      p.m-user-profile-unit__name {{ userName }}
</template>
<script>
import UserIcon from 'src/components/atoms/UserIcon'
import { UAParser } from 'ua-parser-js'

const deviceType = new UAParser().getDevice().type

export default {
  name: 'UserProfileUnit',
  components: { UserIcon },
  props: {
    user: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: 'gray'
    },
    withLink: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isMobile: deviceType === 'mobile'
    }
  },
  computed: {
    profileClass() {
      return ['gray', 'white'].includes(this.color) ? `m-user-profile-unit--${this.color}` : ''
    },
    thumbnailSize() {
      if (this.color === 'gray' && this.isMobile) { // NOTE：背景グレー・モバイル用
        return '32px'
      } else if (this.color === 'gray' && !this.isMobile) { // NOTE：背景グレー・PC用
        return '50px'
      } else { // NOTE：背景ホワイト(PC、SP共通)
        return '32px'
      }
    },
    userName() {
      return this.user.name || this.user.display_name
    },
  },
  methods: {
    openCreatorProfile() {
      window.open(this.user.creator_portfolio_url)
    }
  }
}
</script>

<style lang='scss' scoped>
// stylesheets/collet/components/molecules/_user_profile_unit.scssを参照
</style>
