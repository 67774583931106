import profileSelectionItems from 'src/creator_onboarding/api/profileSelectionItems'

const state = {
  creatorJobTypes: [],
  skills: [],
  countries: [],
  prefectures: []
}

const getters = {
  creatorJobTypes(state) {
    return state.creatorJobTypes
  },

  skills(state) {
    return state.skills
  },

  countries(state) {
    return state.countries
  },

  prefectures(state) {
    return state.prefectures
  }
}

const actions = {
  load({ commit }) {
    return new Promise(resolve => {
      profileSelectionItems.fetchAll().then(response => {
        commit('set', response)

        resolve()
      })
    })
  }
}

const mutations = {
  set(state, inputItems) {
    state.creatorJobTypes = inputItems.creator_job_types
    state.skills = inputItems.skills
    state.countries = inputItems.countries
    state.prefectures = inputItems.prefectures
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
