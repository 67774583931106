import axios from '@axios'
import { serialize } from 'object-to-formdata'

const asCreator = profile => profile.roles.includes('creator') || profile.roles.includes('director') || profile.roles.includes('voice_actor')
const asOperator =  profile => profile.roles.includes('operator')

export default {
  namespaced: true,
  state: {
    profile: null,
    profileError: null,
  },
  getters: {
    isCreator: (state) => {
      if (!state.profile || !asCreator(state.profile)) return false
      // NOTE：オペレーター権限も持っている場合はoperator権限を優先
      return (!state.profile.roles.includes('operator'))
    },
    isOperator: (state) => {
      if (!state.profile || !asOperator(state.profile)) return false
      // NOTE：オペレーター権限がある場合
      return asOperator(state.profile)
    },
    isCustomerSupport: (state) => {
      if (!state.profile) return false
      return state.profile.roles.includes('customer_support')
    },
    // NOTE：新規登録ユーザーかどうか判定
    isNewUser: (state) => {
      if (!state.profile) return false
      return ['checked_or_later_job_offer_count', 'applied_job_offer_count', 'project_count'].every((key) => state.profile[key] === 0)
    },
    organizationId: (state) => {
      return state.profile?.organization?.id
    },
  },
  actions: {
    async fetchProfile({ commit }) {
      try {
        const response = await axios.get('/api/v2/profile')
        const formatResponsse = response.data
        commit('setProfile', formatResponsse)
      } catch(err) {
        commit('setError', err)
      }
    },

    async patchProfile({ commit, state }) {
      const headers = {'Content-Type': 'multipart/form-data'}
      const formData = serialize({
        user: {
          first_name: state.profile.first_name,
          last_name: state.profile.last_name,
          gender: state.profile.gender,
          language_id: state.profile.language_id,
          profile_image: state.profile.profile_image,
        }
      },{
        nullsAsUndefineds: false,
        booleansAsIntegers: true,
        allowEmptyArrays: true
      })
      try {
        await axios.patch('/api/v2/profile', formData, { headers })
      } catch(error) {
        commit('setError', error)
        return Promise.reject(error)
      }
    },

    updateProfileParam({ commit }, payload) {
      commit('setProfileParam', payload)
    }
  },
  mutations: {
    setProfile(state, { profile }) {
      if(profile) state.profile = profile
    },
    setError(state, error) {
      if(error) state.profileError = error
    },
    setProfileParam(state, {key, value}) {
      state.profile[key] = value
    }
  }
}
