<template lang='pug'>
  .a-tooltip(v-if="isTouchDevice")
    .a-tooltip__target(@click="showTooltip")
      slot
    transition(name="fade")
      .a-tooltip__text-box(v-if="isShow" v-on-clickaway="hideTooltip" :style="tooltipLeftStyle" :class="{'a-tooltip__text-box--bottom': showBottom}")
        span.a-tooltip__text-content {{text}}
        br
        a.a-tooltip__text-link(:href="linkUrl" v-if="linkUrl") {{linkText}}
  .a-tooltip(v-else)
    .a-tooltip__target(@mouseover="showTooltip")
      slot
    transition(name="fade")
      .a-tooltip__text-box(v-if="isShow" v-on-clickaway="hideTooltip" :style="tooltipLeftStyle" :class="{'a-tooltip__text-box--bottom': showBottom}")
        span.a-tooltip__text-content {{text}}
        br
        a.a-tooltip__text-link(:href="linkUrl" v-if="linkUrl") {{linkText}}
</template>

<script>
import { UAParser } from 'ua-parser-js'
import { mixin as clickaway } from 'vue-clickaway'
const deviceType = new UAParser().getDevice().type

export default {
  mixins: [clickaway],
  props: {
    text: {
      type: String,
      required: true
    },
    linkUrl: {
      type: String,
      required: false,
      default: null
    },
    linkText: {
      type: String,
      required: false,
      default: null
    },
    showBottom: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isTouchDevice: deviceType === 'mobile' || deviceType === 'tablet',
      isMobile: deviceType === 'mobile',
      tooltipLeft: 0,
      isShow: false,
    }
  },
  computed: {
    tooltipLeftStyle() {
      return { left: this.tooltipLeft + 'px' }
    }
  },
  methods: {
    showTooltip(e) {
      if (this.isMobile) {
        // NOTE: 240pxはSPのときのtext-boxのwidth
        console.log(e.clientX + 240 > window.innerWidth)
        this.tooltipLeft =  e.clientX + 240 > window.innerWidth ? `-${(window.innerWidth / 3) + 20}` : -10
      } else {
        // NOTE: 200pxはtext-boxのwidth
        const tooltipClientX = e.clientX + 200
        // NOTE: 中央に配置するため (tooltip__text-boxのwidth 200px - tooltip__targetのwidth 14px) / 2 を計算
        this.tooltipLeft = tooltipClientX > window.innerWidth ? -93 : -6
      }
      this.isShow = true
    },
    hideTooltip() {
      this.isShow = false
      this.tooltipLeft = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.a-tooltip {
  position: relative;
  font-size: 14px;

  &__target {
    cursor: pointer;
  }

  &__text-box {
    position: absolute;
    bottom: 24px;
    z-index: 100;
    width: 200px;
    padding: 14px 16px;
    font-weight: normal;
    line-height: 1.4;
    background: rgba(0, 0, 0, .9);
    @include mq-down(tablet--under) {
      width: 240px;
    }
    &--bottom {
      top: 24px;
      bottom: auto;
    }
  }

  &__text-content {
    color: var(--color-mono-c);
    text-align: left;
  }
  &__text-link {
    display: inline;
    color: var(--color-mono-c);
    text-align: left;
    text-decoration: underline;
    cursor: pointer;

    &:active {
      text-decoration: none;
    }
    @include mq-up(tablet) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}
</style>
