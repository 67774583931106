<template lang="pug">
ul.m-scope-of-list
  li.m-scope-of-list__item(:class="{ 'm-scope-of-list__item--active': v.active }" v-for='v in values') {{ v.text }}
</template>

<script>
export default {
  name: 'ScopeOfItemList',
  props: {
    values: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.m-scope-of-list {
  @include flex(start, stretch);
  flex-wrap: wrap;
  overflow: hidden;
  background: var(--color-mono-c);
  border: 1px solid var(--color-mono-7);
  border-radius: 3px;
  &__item {
    @include flex(center, center);
    height: auto;
    padding: 16px 4px;
    font-size: 13px;
    line-height: 1.4;
    color: var(--color-mono-4);
    text-align: center;
    background: var(--color-mono-c);
    box-shadow: 0 0 0 1px var(--color-mono-7);
    @include mq-up(tablet) {
      width: calc(20%);
    }
    @include mq-down(tablet--under) {
      width: 50%;
    }
    &--active {
      font-weight: bold;
      color: #398070;
      background: #e7f8f4;
    }
  }
}
</style>
