<template lang='pug'>
  .a-loading-icon
    .a-loading-icon__image
</template>

<script>
export default {
  name: 'LoadingIcon'
}
</script>

<style lang='scss' scoped>
.a-loading-icon {
  margin: 20px 0;

  &__image {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    text-indent: -9999px;
    border-top: 2px solid var(--color-mono-9);
    border-right: 2px solid var(--color-mono-9);
    border-bottom: 2px solid var(--color-mono-9);
    border-left: 2px solid rgba(17, 17, 17, .4);
    border-radius: 50%;
    transform: translateZ(0);
    animation: loadAuditions 1.1s infinite linear;
    @include mq-down(tablet--under) {
      width: 40px;
      height: 40px;
    }
  }
}

@keyframes loadAuditions {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
