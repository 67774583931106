import messageRoom from 'src/messageRooms/store/modules/messageRooms'
import Profile from 'src/store/profile/modules/profile'
import Vue from 'vue'
import creator from 'vue-app/creators/profile/store/modules/creator'
import creatorAudition from 'vue-app/creators/profile/store/modules/creator-audition'
import creatorAuditionInviteModal from 'vue-app/creators/profile/store/modules/creator-audition-invite-modal'
import currentUser from 'vue-app/creators/profile/store/modules/current-user'
import profileShareModal from 'vue-app/creators/profile/store/modules/profile-share-modal'
import shareAdmission from 'vue-app/creators/profile/store/modules/share-admission'
import imagePath from 'vue-app/libs/image_path'
import addListModal from 'vue-app/libs/store/modules/creator_list/add-list-modal'
import creatorLists from 'vue-app/libs/store/modules/creator_list/creator-lists'
import tooltip from 'vue-app/libs/store/modules/tooltip'
import Vuex, { Store } from 'vuex'

Vue.use(Vuex)
Vue.mixin(imagePath)

const store = new Store({
  modules: {
    Profile,
    creator,
    addListModal,
    creatorLists,
    creatorAudition,
    creatorAuditionInviteModal,
    profileShareModal,
    tooltip,
    shareAdmission,
    currentUser,
    messageRoom
  },
  strict: process.env.NODE_ENV !== 'production'
})

export default store
