<template lang="pug">
  Card.o-creator-audition-form
    Title(title="概要情報")
    .o-creator-audition-form__content
      .o-creator-audition-form__item
        InputSet(title="仕事依頼のタイトル")
          Input(required :name="inputJaName({name: 'name'})" :value="auditionName" :placeholder="placeholderAuditionNameJA" size="l" @input="handleInputTitle")
      .o-creator-audition-form__item(v-if="isEN")
        InputSet(title="【英訳】仕事依頼のタイトル")
          Input(required :name="inputEnName({name: 'name'})" :value="auditionNameEN" placeholder="英語の仕事依頼のタイトル" size="l" @input="handleInputTitleEN")
      .o-creator-audition-form__item
        LabelText.o-creator-audition-form__label(text="サムネイル")
        AttachedThumbnail(
          :thumbnailURL="thumbnailURL"
          :thumbnailName="thumbnailName"
          :thumbnailFile="thumbnailFile"
          @existingThumbnail="setExistingThumbnail"
          @attachedThumbnail="setAttachedThumbnail"
          @setThumbnailURL="setThumbnailURL"
          @setFlashMessage="setFlashMessage"
        )
      .o-creator-audition-form__item
        InputSet(title="依頼形式" :disablePointerEvents="true")
          RadioSet(required :values="offerTypes" :checkedValue="offerType" @change="onChangeOfferType")
      .o-creator-audition-form__item
        InputSet(title="動画の種類" :disablePointerEvents="true")
          RadioSet(required :values="filmGenres" :checkedValue="filmGenre" @change="onChangeFilmGenre")
      .o-creator-audition-form__item
        InputSet(title="仕事の範囲 (複数選択可)" :disablePointerEvents="true")
          TagCheckboxList(required ref="jobPartIdentier" :name="jobPartIdentifiersData.name" :tags="jobPartIdentifiersData.tags" :checkedTags="jobPartIdentifiers" @validate="onValidateJobPartIdentifier" @change="onChangeJobPartIdentifier")
        DescriptionJobPartsModal.o-creator-audition-form__descrption-modal
      .o-creator-audition-form__item
        .o-creator-audition-form__title
          LabelText.o-creator-audition-form__label(text="金額の決め方")
          RequiredIcon.m-input-set__required(:valid="isValidPrice")
        .o-creator-audition-form__annotation(v-if="isProhibitedEditingAfterChecking")
          span ※公開後は金額を変更できません
        .o-creator-audition-form__input-price
          InputPrice(
            :suggestionPrices="jobOfferPriceRanges"
            :suggestionPriceValue="suggestionPriceValue"
            :offerFeePercentage="offerFeePercentage"
            :budgetTypes="budgetTypes"
            :budget_type="budgetType"
            :disabled="isProhibitedEditingAfterChecking"
            @validation="handleValidation"
            @input="handleInputPrice"
            @change="onChangeSuggestionPrice"
            @setBudgetType="onChangeBudgetType"
            ref="priceSuggestion"
          )
      .o-creator-audition-form__item
        InputSet(title="納品形式")
          Input(:name="inputJaName({name: 'deliveryOfGoods'})" :value="deliveryOfGoods" :placeholder="placeholderDeliveryOfGoods" size="l" @input="handleInputDeliveryOfGoods")
      .o-creator-audition-form__item(v-if="isEN")
        InputSet(title="【英訳】納品形式")
          Input(:name="inputEnName({name: 'deliveryOfGoods'})" :value="deliveryOfGoodsEN" placeholder="英語の納品形式" size="l" @input="handleInputDeliveryOfGoodsEN")
      .o-creator-audition-form__item
        .o-creator-audition-form__title
          LabelText.o-creator-audition-form__label(text="納品希望日")
          RequiredIcon.m-input-set__required(:valid="deliveryDateInputed")
        .o-creator-audition-form__inputs
          DatePicker.o-creator-audition-form__datepicker(
            :date="requestedDeliverlyDate"
            :minDate="minDate"
            ref="requestedDeliverlyDate"
            @change="onChangeRequestedDeliveryDate"
            required
            )
          Checkbox.o-creator-audition-form__checkbox(title="納品日の交渉可能" :checked="deliverlyDateNegotiable" name="creator_audition[deliverlyDateNegotiable]" @change="onChangeDeliveryDateNegotiable")
      .o-creator-audition-form__item
        .o-creator-audition-form__title
          LabelText.o-creator-audition-form__label(text="撮影場所")
          RequiredIcon.m-input-set__required(v-if="shootingLocationRequired" :valid="shootingLocationInputedJA")
        .o-creator-audition-form__inputs
          Textarea(
            :required="shootingLocationRequired"
            :name="inputJaName({name: 'shootingLocation'})"
            :value="shootingLocation"
            :rows="2"
            :placeholder="placeholderShootingLocation"
            size="l"
            maxlength="1000"
            @input="handleInputShootingLocation"
          )
      .o-creator-audition-form__item(v-if="isEN")
        .o-creator-audition-form__title
          LabelText.o-creator-audition-form__label(text="【英訳】撮影場所")
          RequiredIcon.m-input-set__required(v-if="shootingLocationRequired" :valid="shootingLocationInputedEN")
        .o-creator-audition-form__inputs
          Textarea(
            :required="shootingLocationRequired"
            :name="inputEnName({name: 'shootingLocation'})"
            :value="shootingLocationEN"
            :rows="2"
            placeholder="英語の撮影場所"
            size="l"
            maxlength="1000"
            @input="handleInputShootingLocationEN"
          )
</template>

<script>
import dayjs from 'dayjs'
import Button from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card'
import Checkbox from  'src/components/atoms/Form/Checkbox'
import DatePicker from  'src/components/atoms/Form/DatePicker'
import Input from 'src/components/atoms/Form/Input'
import Textarea from 'src/components/atoms/Form/Textarea'
import HelpText from 'src/components/atoms/HelpText'
import LabelText from 'src/components/atoms/LabelText'
import RequiredIcon from 'src/components/atoms/RequiredIcon'
import Title from 'src/components/atoms/Title'
import InputSet from 'src/components/molecules/InputSet'
import RadioSet from 'src/components/molecules/RadioSet'
import TagCheckboxList from 'src/components/molecules/TagCheckboxList'
import AttachedThumbnail from 'src/components/organisms/CreatorAudition/AttachedThumbnail'
import DescriptionJobPartsModal from 'src/components/organisms/CreatorAudition/DescriptionJobPartsModal'
import InputPrice from 'src/components/organisms/InputPrice'
import budgetTypes from 'src/lib/creatorAudition/budgetTypes'
import filmGenres from 'src/lib/creatorAudition/filmGenres'
import { inputJaName,  inputEnName } from 'src/lib/creatorAudition/i18nInput'
import jobOfferPriceRanges from 'src/lib/creatorAudition/jobOfferPriceRanges'
import jobParts from 'src/lib/creatorAudition/jobParts'
import offerTypes from 'src/lib/creatorAudition/offerTypes'
import 'dayjs/locale/ja'

export default {
  name: 'AuditionSummaryForm',
  components: {
    Button,
    Card,
    Checkbox,
    DatePicker,
    Input,
    Textarea,
    HelpText,
    LabelText,
    RequiredIcon,
    Title,
    InputSet,
    RadioSet,
    TagCheckboxList,
    AttachedThumbnail,
    DescriptionJobPartsModal,
    InputPrice,
  },
  props: {
    isEN: {
      type: Boolean,
      required: false,
      default: false
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false
    },
    auditionName: {
      type: String,
      required: false,
      default: null,
    },
    auditionNameEN: {
      type: String,
      required: false,
      default: null,
    },
    offerPrice: {
      type: [Number, String],
      required: false,
      default: 0
    },
    offerPriceRange: {
      type: Number,
      required: false,
      default: null
    },
    thumbnailURL: {
      type: String,
      required: false,
      default: null,
    },
    offerType: {
      type: String,
      required: false,
      default: null
    },
    filmGenre: {
      type: String,
      required: false,
      default: null
    },
    budgetType: {
      type: String,
      required: true,
    },
    offerFeePercentage: {
      type: Number,
      required: false,
      default: 0
    },
    deliveryOfGoods: {
      type: String,
      required: false,
      default: null,
    },
    deliveryOfGoodsEN: {
      type: String,
      required: false,
      default: null,
    },
    requestedDeliverlyDate: {
      type: String,
      required: true,
    },
    shootingLocation: {
      type: String,
      required: false,
      default: null,
    },
    shootingLocationEN: {
      type: String,
      required: false,
      default: null,
    },
    deliverlyDateNegotiable: {
      type: Boolean,
      required: false,
      default: false
    },
    jobPartIdentifiers: {
      type: Array,
      required: false,
      default: () => []
    },
    thumbnailName: {
      type: String,
      requreid: false,
      default: null
    },
    thumbnailFile: {
      type: File,
      required: false,
      default: null
    },
    workflowState: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      offerTypes,
      filmGenres,
      budgetTypes,
      placeholderAuditionNameJA: '例）動画制作プラットフォームの紹介動画を頼めるクリエイターを募集',
      placeholderDeliveryOfGoods: '例）DVD納品 / MP4 / フルHD',
      placeholderShootingLocation: '例) 東京都目黒区',
      minDate: dayjs().locale('ja').format('YYYY-MM-DD'),
      deliveryDateInputed: false,
      shootingLocationInputedJA: false,
      shootingLocationInputedEN: false,
      jobOfferPriceRanges,
    }
  },
  computed: {
    suggestionPriceValue() {
      if (this.budgetType === 'proposal') {
        return this.offerPriceRange || ''
      } else {
        return this.offerPrice
      }
    },
    isValidPrice() {
      if (this.budgetType === 'proposal') {
        return this.offerPriceRange !== null && jobOfferPriceRanges.map(({ id }) => id).includes(this.offerPriceRange)
      } else {
        return this.offerPrice !== null && this.offerPrice >= 1 && this.offerPrice <= 999999999
      }
    },
    jobPartIdentifiersData() {
      const tags = jobParts.map(val => {
        return { id: val.id, name: this.$i18n.t(`creatorAuditions.detail.jobParts.${val.id}`) }
      })

      return { name: 'creator_audition[jobPartIdentifiers][]', tags: tags }
    },
    isProhibitedEditingAfterChecking() {
      return !['draft', 'posted'].includes(this.workflowState)
    },
    shootingLocationRequired() {
      return this.jobPartIdentifiers.includes('photography') || this.jobPartIdentifiers.includes('film_shooting')
    },
  },
  methods: {
    isValid() {
      return this.$refs.requestedDeliverlyDate?.selectedDate !== ''
    },
    handleValidation() {
      this.$emit('validation')
    },
    inputJaName({ name }) {
      return inputJaName({ name, isAdmin: this.isAdmin })
    },
    inputEnName({ name }) {
      return inputEnName({ name })
    },
    handleInputTitle(e) {
      this.$emit('input', { type: 'auditionName', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputTitleEN(e) {
      this.$emit('input', { type: 'auditionNameEN', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputPrice({ payload }) {
      this.$emit('change', { type: 'offerPriceRange', payload: null })
      this.$emit('input', { type: 'offerPrice', payload: payload ? Number(payload.replace(/,/g, '')) : null })
      this.$emit('validation')
    },
    handleInputDeliveryOfGoods(e) {
      this.$emit('input', { type: 'deliveryOfGoods', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputDeliveryOfGoodsEN(e) {
      this.$emit('input', { type: 'deliveryOfGoodsEN', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputShootingLocation(e) {
      this.shootingLocationInputedJA = !!e.target.value
      this.$emit('input', { type: 'shootingLocation', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputShootingLocationEN(e) {
      this.shootingLocationInputedEN = !!e.target.value
      this.$emit('input', { type: 'shootingLocationEN', payload: e.target.value })
      this.$emit('validation')
    },
    setExistingThumbnail(name) {
      this.$emit('existingThumbnail', name)
    },
    setAttachedThumbnail(data) {
      this.$emit('attachedThumbnail', data)
    },
    setThumbnailURL(url) {
      this.$emit('setThumbnailURL', url)
    },
    onChangeBudgetType(type) {
      this.budgetTypeData = type
      this.$emit('change', { type: 'budgetType', payload: type })
    },
    onChangeOfferType(e) {
      this.$emit('change', { type: 'offerType', payload: e.target.value })
      this.$emit('validation')
    },
    onChangeFilmGenre(e) {
      this.$emit('change', { type: 'filmGenre', payload: e.target.value })
      this.$emit('validation')
    },
    onValidateJobPartIdentifier() {
      this.$emit('validation')
    },
    onChangeJobPartIdentifier() {
      const selectedJobPartIdentifiers = this.$refs.jobPartIdentier.$children.filter(jobPartIdentifier => jobPartIdentifier.active).map(jobPartIdentifier => jobPartIdentifier.tag.id)

      this.$emit('change', { type: 'jobPartIdentifiers', payload: selectedJobPartIdentifiers })
      this.$emit('validation')
    },
    onChangeSuggestionPrice({ payload }) {
      this.$emit('input', { type: 'offerPrice', payload: null })
      this.$emit('change', { type: 'offerPriceRange', payload: payload ? Number(payload) : null })
    },
    onChangeRequestedDeliveryDate(date) {
      this.deliveryDateInputed = true
      // NOTE：既存データと日付のformatが違うので最新の日付フォーマットに変換
      const formatDate = dayjs(date).locale('ja').format('YYYY-MM-DD')
      this.$emit('change', { type: 'requestedDeliveryDate', payload: formatDate })
      this.$emit('validation')
    },
    onChangeDeliveryDateNegotiable(e) {
      this.$emit('change', { type: 'deliveryDateNegotiable', payload: e.target.checked })
      this.$emit('validation')
    },
    setFlashMessage(text) {
      this.$emit('setFlashMessage', true, text, true)
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~styles/creator_auditions/components/organisms/creator-audition-edit-form';
</style>
