<template lang='pug'>
li.add-list-modal__creator-list
  .add-list-modal__creator-list-title {{title}}
  .add-list-modal__action
    span.add-list-modal__added-text(v-if='isAdded') 追加済
    button.add-list-modal__add-button(@click='toggle')
      add-list-icon(:isAdded='isAdded')
</template>

<script>
import { mapActions } from 'vuex'
import AddListIcon from './add-list-icon'

export default {
  components: {
    'add-list-icon': AddListIcon
  },

  props: {
    id: {
      type: Number,
      required: true
    },

    title: {
      type: String,
      required: true
    },

    creatorIds: {
      type: Array,
      required: true
    },

    creatorId: {
      type: Number,
      required: true
    }
  },

  computed: {
    isAdded() {
      return this.creatorIds.includes(this.creatorId)
    }
  },

  methods: {
    ...mapActions('addListModal', ['append', 'remove']),
    toggle() {
      if (this.isAdded) {
        this.remove({ creatorListId: this.id, creatorId: this.creatorId })
      } else {
        this.append({ creatorListId: this.id, creatorId: this.creatorId })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.add-list-modal {
  &__creator-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 10px;
    border-top: 1px solid var(--color-mono-7);
  }

  &__creator-list-title {
    color: var(--color-mono-1);
  }

  &__action {
    display: flex;
    align-items: center;
  }

  &__added-text {
    font-size: 13px;
    color: var(--color-pink);
  }

  &__add-button {
    display: flex;
    font-size: 20px;
    border: 0;
    outline: 0;
    appearnce: none;
    background-color: transparent;
  }
}
</style>
