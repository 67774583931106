<template lang='pug'>
  a.a-text-link(@click="onClick")
    slot {{text}}
</template>

<script>
export default {
  name: 'TextLink',
  props: {
    text: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang='scss' scoped>
  // stylesheets/collet/components/atoms/_text_link.scssを参照
</style>
