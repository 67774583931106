import { mixin as clickaway } from 'vue-clickaway'

export default {
  mixins: [ clickaway ],

  data() {
    return {
      showingList: false
    }
  },

  computed: {
    listStyle() {
      return {
        visibility: this.showingList ? 'visible' : 'hidden',
        opacity: this.showingList ? '.98' : '0'
      }
    }
  },

  methods: {
    showList() {
      this.showingList = true
    },

    hideList() {
      this.showingList = false
    },

    toggleList() {
      this.showingList = !this.showingList
    },

    select(selectedItem) {
      this.$emit('setSelectItem', selectedItem)
      this.$emit('search')
      this.hideList()
    },

    // google analyticsのためだけに利用
    _currentTitle() {
      let title
      if(location.href.match(/creators/)) title = 'クリエイター検索'
      if(location.href.match(/portfolios/)) title = '作品検索'

      return title
    },
  }
}
