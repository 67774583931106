<template lang='pug'>
  .m-video-unit
    template(v-if="!isMobile")
      button.m-video-unit__clickable-area(@click="openModal" :style="{ width: thumbnailWidth, cursor: cursorOnVideo }")
        template(v-if="thumbnailURL")
          i.m-video-unit__play-icon.crevo-icon.crevo-triangle_right_fill(v-if="showPlayIcon")
          img.m-video-unit__thumbnail(:src="thumbnailURL" :class="{trackingClass, 'm-video-unit__thumbnail--radius': withRadius}")
        template(v-else)
          .m-video-unit__thumbnail.m-video-unit__thumbnail--no-image(:class="{'m-video-unit__thumbnail--radius': withRadius}")
            i.crevo-icon.crevo-volume(v-if="embedURL")
            i.crevo-icon.crevo-headphone(v-else-if="fileType === 'audio'")
            i.crevo-icon.crevo-link(v-else)
      //- TODO：あとで分離してthin-modalを無くして個別のcomponentにする
      transition(name="fade")
        .m-video-unit__modal(v-if="modalShow")
          i.crevo-icon.crevo-close
          .m-video-unit__player(v-on-clickaway="closeModal")
            Video.m-video-unit__video(
              :fileType="fileType"
              :fileURL="fileURL"
              :embedURL="embedURL"
              :autoplay="autoplay"
              :width="calcWidth"
              :height="calculatedHeight"
            )
    Video.m-video-unit__video.m-video-unit__video--sp(
      v-else
      :fileType="fileType"
      :fileURL="fileURL"
      :embedURL="embedURL"
      :autoplay="autoplay"
      :width="calcWidth"
      :withRadius="withRadius"
    )
</template>

<script>
import Video from 'src/components/atoms/Video'
import { UAParser } from 'ua-parser-js'
import { mixin as clickaway } from 'vue-clickaway'

const deviceType = new UAParser().getDevice().type

export default {
  name: 'VideoUnit',
  components: { Video },
  mixins: [clickaway],
  props: {
    thumbnailWidth: {
      type: String,
      required: false,
      default: '100%',
    },
    mobileThumbnailWidth: {
      type: Number,
      required: false,
      default: null,
    },
    thumbnailURL: {
      type: String,
      required: false,
      default: null
    },
    fileType: {
      type: String,
      required: false,
      default: '',
    },
    fileURL: {
      type: String,
      required: false,
      default: '',
    },
    embedURL: {
      type: String,
      required: false,
      default: '',
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    showPlayIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    withRadius: {
      type: Boolean,
      required: false,
      default: false
    },
    trackingClass: { // NOTE：GTMで動画計測を行う場合にPCとSPでイベント分ける必要があり、PCはclickで計測する必要があるので親コンポーネントからpropsで計測用のclassを渡す
      type: String,
      required: false,
      default: ''
    }
  },
  data(){
    return {
      isMobile: deviceType === 'mobile' || deviceType === 'tablet',
      width: 800,
      modalShow: false,
      calculatedHeight: null
    }
  },
  computed: {
    calcWidth() {
      if (this.isMobile && this.mobileThumbnailWidth) { // NOTE：デザインのサイズが375pxを基準にしているのでそこで指定しているサイズから%を算出して計算
        const windowWidth = window.innerWidth
        const calculateMobileWidthPercent = this.mobileThumbnailWidth / 375
        return windowWidth * calculateMobileWidthPercent
      } else if(this.isMobile) {
        // NOTE：iframeの高さ計算があるので一旦左右で50px余白がある前提で計算
        const windowWidth = window.innerWidth
        return windowWidth - 50
      } else {
        // NOTE：videoタグの場合、アスペクト比をwidthとheightから計算
        if (this.fileURL !== '') {
          let element = document.createElement('video')

          element.src = this.fileURL
          element.onloadedmetadata = () => {
            if (element.videoWidth < element.videoHeight) this.calculatedHeight = `${window.innerHeight * 0.7}px`
          }
          // 動画読み込みのタイミングでないとサイズが取れないので、onloadedmetadataの内部で処理を実行
          element.load()
        }
        return 800
      }
    },
    cursorOnVideo() {
      return this.embedURL === null ? 'default' : 'pointer'
    }
  },
  methods: {
    keyAction(e) {
      // NOTE：モーダル開いている時にescでモーダル閉じる
      if (e.key === 'Escape') this.modalShow = false
    },
    openModal() {
      if (!this.embedURL && this.fileType !== 'audio' && this.fileType !== 'movie') return

      this.modalShow = true
      window.addEventListener('keydown', this.keyAction)
    },
    closeModal() {
      this.modalShow = false
      window.removeEventListener('keydown', this.keyAction)
    }
  }
}
</script>
<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .25s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .m-video-unit {
    @include flex(center);
    background: rgba(0, 0, 0, .3);

    &__clickable-area {
      position: relative;
      width: 100%;
      transition: .2s;
      &:active {
        opacity: .8;
      }
      @include mq-up(narrow-pc) {
        &:hover {
          opacity: .8;
        }
      }
    }
    &__play-icon {
      @include flex(center);
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 50px;
      height: 50px;
      font-size: 20px;
      color: var(--color-mono-2);
      text-indent: 1px;
      background: var(--color-mono-c);
      border-radius: 100%;
      transform: translate(-50%, -50%);
    }
    &__thumbnail {
      width: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
      &--radius {
        border-radius: 5px;
      }
      &--no-image {
        @include flex(center);
        background: var(--color-mono-6);
        .crevo-icon {
          font-size: 40px;
          color: var(--color-mono-c);
        }
      }
    }
    // モーダル
    &__modal {
      @include flex(center);
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10000;
      width: 100%;
      height: 100%;
      background-color: rgba(17, 17, 17, .9);
      border: 0;
      border-radius: 0;

      .crevo-close {
        @include flex(center);
        position: absolute;
        top: 30px;
        right: 30px;
        width: 50px;
        height: 50px;
        font-size: 16px;
        color: var(--color-mono-9);
        cursor: pointer;
        background: var(--color-mono-2);
        border-radius: 100%;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, .15);
        transition: .2s;
        &:hover {
          opacity: .8;
        }
        @include mq-up(narrow-pc) {
          &:hover {
            opacity: .8;
          }
        }

        @include mq-down(tablet--under) {
          top: 10px;
          right: 10px;
        }
      }
    }
    &__player {
      @include flex(center);
      width: 84vw;
      max-width: 1068px;
      max-height: 600px;
    }
    &__video {
      width: 800px;
      &--sp {
        width: 100%;
      }
    }
  }
</style>

<style lang="scss">
/*
  Note：以下理由により、.modal-backdropはdisplay:none;としている。
  そのため、vue-thin-modal を直接利用するなど、
  本コンポーネントを使わない場合は別途利用箇所で.modal-backdropをdisplay: block;で定義しておく必要がある
  - デザイン上バツがつくのと背景色がthin-modalと異なる、clickawayで背景タップで閉じたい
  - 既存のbootstrapのCSSとぶつかる可能性がある
*/
.modal-backdrop {
  display: none;
}
</style>
