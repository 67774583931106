import axios from '@axios'

const baseURL = '/api/creators/creator_auditions'

const state = {
  all: []
}

const getters = {
  all(state) {
    return state.all
  }
}

const mutations = {
  set(state, creatorAuditions) {
    state.all = creatorAuditions
  },

  unset(state) {
    state.all = null
  },

  addInviteCreatorId(state, { creatorAuditionId, creatorId }) {
    const creatorAudition = state.all.find(creatorAudition => creatorAudition.id === creatorAuditionId)
    creatorAudition.invited_creator_ids.push(creatorId)
  }
}

const actions = {
  load({ commit }) {
    axios.get(`${baseURL}`).then(response => {
      commit('set', response.data.creator_auditions)
    })
  },

  invite({ commit }, { creatorId, creatorAudition }) {
    const params = {
      creator_user_id: creatorId
    }

    axios.post(`${baseURL}/${creatorAudition.id}/creator_candidates`, params)
      .then(() => {
        commit('addInviteCreatorId', { creatorAuditionId: creatorAudition.id, creatorId: creatorId })

        this._vm.$notify({
          group: 'creator-audition-list',
          type: 'success',
          title: '招待しました'
        })
      })
      .catch(() => {
        this._vm.$notify({
          group: 'creator-audition-list',
          type: 'error',
          title: '招待できませんでした'
        })
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
