import axios from 'axios'

export default function saveDownloadFile(url, filename) {
  return axios.get(url, { responseType: 'blob' })
    .then(res => {
      const blob = new Blob([res.data], { type: res.data.type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    })
}
