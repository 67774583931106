import axios from '@axios'

export default {
  namespaced: true,
  state: {
    recommendedCreators: [],
    recommendedCreatorsError: null,
  },
  actions: {
    async fetchRecommendedCreators({ commit }) {
      try {
        const response = await axios.get('/api/v2/dashboard/recommended_creators')
        commit('setRecommendedCreators', response.data.recommended_creators)
      } catch(err) {
        commit('setError', err)
      }
    },
  },
  mutations: {
    setRecommendedCreators(state, recommendedCreators) {
      if(recommendedCreators) state.recommendedCreators = recommendedCreators
    },
    setError(state, error) {
      if(error) state.recommendedCreatorsError = error
    }
  }
}
