<template lang='pug'>
  .a-required-icon(:class="{ 'a-required-icon--valid': valid }")
    i.crevo-icon.crevo-check(v-if="valid")
    span(v-if="!valid") {{ $t('atoms.required') }}
</template>

<script>
export default {
  name: 'RequiredIcon',
  props: {
    valid: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.a-required-icon {
  @include flex(center);
  min-width: 32px;
  height: 14px;
  padding: 2px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.1;
  color: var(--color-mono-c);
  background: var(--color-red);
  border-radius: 2px;
  transition: .2s;
  @include mq-down(tablet--under) {
    font-size: 10px;
  }

  &--valid{
    background: var(--color-light-green);
    span {
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;
    }
  }
}
</style>
