<template lang="pug">
  .m-form-description {{ description }}
</template>

<script>
export default {
  name: 'FormDescription',
  props: {
    description: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.m-form-description {
  line-height: 1.6;
  white-space: pre-wrap;
}
</style>
