<template lang='pug'>
  .a-video(:class="{'a-video--radius': isMobile && withRadius}")
    video.a-video__item.a-video__item--video(
      preload="auto"
      controls
      controlslist="nodownload"
      :width="width"
      :height="height"
      :autoplay="autoplay"
      :src="fileURL"
      v-if="fileURL !== '' && fileType === 'movie'")
    .a-video__item.a-video__item--audio(:style="{ width: width }" v-if="fileURL !== '' && fileType === 'audio'")
      audio(
        :style="{ width: '100%' }"
        :src="fileURL"
        :autoplay="autoplay"
        controls
        controlslist="nodownload"
      )
    .a-video__item.a-video__item--iframe(v-if="embedURL !== ''")
      iframe.a-video__iframe-player(
        :width="width"
        :src='`${embedURL}?autoplay=${controlIframeAutoPlay}&rel=0&showinfo=0`'
        frameborder="0"
        data-key="value"
      )

</template>

<script>
import { UAParser } from 'ua-parser-js'
const deviceType = new UAParser().getDevice().type

export default {
  name: 'Video',
  props: {
    fileType: {
      type: String,
      required: false,
      default: '',
    },
    fileURL: {
      type: String,
      required: false,
      default: '',
    },
    embedURL: {
      type: String,
      required: false,
      default: '',
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: [Number, String],
      required: false,
      default: '100%'
    },
    height: {
      type: [Number, String],
      required: false,
      default: null
    },
    withRadius: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      isMobile: deviceType === 'mobile' || deviceType === 'tablet',
    }
  },
  computed: {
    controlIframeAutoPlay() {
      return this.autoplay ? 1 : 0
    }
  }
}
</script>

<style lang="scss">
.a-video {
  &--radius {
    overflow: hidden;
    border-radius: 5px;
  }
  &__item {
    &--iframe {
      aspect-ratio: 16 / 9;
    }
  }
  &__iframe-player {
    width: 100%;
    height: 100%;
  }
}
</style>
