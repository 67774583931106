<template lang="pug">
.o-description-job-parts
  .o-description-job-parts__text
    | ※各仕事の範囲に関する説明は
    button.o-description-job-parts__open-modal(@click="openModal" type="button") こちら
  Modal(:showCloseIcon="false")
    template(v-slot:body="")
      ul.o-description-job-parts__list
        li(v-for="item in jobParts" :key="item.id")
          .o-description-job-parts__label {{ $t(`creatorAuditions.detail.jobParts.${item.id}`) }}
          .o-description-job-parts__description {{ item.description }}
    template(v-slot:footer="")
      .o-description-job-parts__action
        Button.o-description-job-parts__close(@click="closeModal" title="閉じる" color="black" size="s")
</template>

<script>
import Button from 'src/components/atoms/Button'
import Modal from 'src/components/molecules/Modal'
import jobParts from 'src/lib/creatorAudition/jobParts'

export default {
  name: 'DescriptionJobPartsModal',
  components: { Button, Modal },
  data() {
    return {
      jobParts
    }
  },
  methods: {
    keyAction(e) {
      // NOTE：モーダル開いている時にescでモーダル閉じる
      if (e.key === 'Escape') this.$modal.pop()
    },
    openModal() {
      this.$modal.push('modal')
      window.addEventListener('keydown', this.keyAction)
    },
    closeModal() {
      this.$modal.pop()
      window.removeEventListener('keydown', this.keyAction)
    },
  }
}
</script>

<style lang="scss" scoped>
.o-description-job-parts {
  &__text {
    @include flex;
    font-size: 12px;
    line-height: 1.3;
    color: var(--color-mono-3);
  }
  &__open-modal {
    text-decoration: underline;
    &:active {
      text-decoration: none;
    }
    @include mq-up(narrow-pc) {
      &:hover {
        text-decoration: none;
      }
    }
  }
  &__list {
    height: 300px;
    padding: 0 40px;
    overflow: scroll;
    font-size: 14px;
    text-align: left;
    @include mq-down(tablet--under) {
      height: 50vh;
      padding: 0 15px;
    }
  }
  &__label {
    padding: 7px 10px;
    font-weight: bold;
    line-height: 1.3;
    background: var(--color-mono-a);
    border-radius: 3px;
  }
  &__description {
    padding: 0 10px;
    margin: 10px 0 14px;
    line-height: 1.6;
  }
}
</style>
