<template lang="pug">
  ul.m-radio-set
    li.m-radio-set__item(v-for='(radio, index) in values' :key='index')
      input.m-radio-set__radio(
        type='radio'
        v-model='value'
        :name='radio.name'
        :id="`${radio.name}-${index}`"
        :value="radio.value"
        :required='required'
        @change='onChange'
      )
      label.m-radio-set__content(:for="`${radio.name}-${index}`" :class="{ 'm-radio-set__content--no-description': !radio.description}")
        .m-radio-set__title {{ radio.title }}
        Tooltip(:text="radio.description" v-if="radio.description")
          i.crevo-icon.crevo-help_fill
</template>

<script>
import Tooltip from 'src/components/atoms/Tooltip'

export default {
  name: 'RadioSet',
  components: {
    Tooltip
  },
  props: {
    /** @type {Array<{name: string, value: string, title: string, description?: string}>} values */
    values: {
      type: Array,
      required: true
    },
    checkedValue: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      value: ''
    }
  },
  watch: {
    checkedValue: {
      handler: function(newVal) {
        this.value = newVal === '' ?  this.values[0].value : newVal
      }
    }
  },
  mounted(){
    if (this.checkedValue) this.value = this.checkedValue
  },
  methods: {
    onChange(e) {
      this.$emit('change', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.m-radio-set {
  width: 100%;
  margin: 0 auto;
  @include mq-up(tablet) {
    @include flex;
  }
  &__item:not(:last-of-type) {
    @include mq-up(tablet) {
      margin-right: 20px;
    }
    @include mq-down(tablet--under) {
      margin-bottom: 8px;
    }
  }
  &__radio {
    display: none;
    &:checked + .m-radio-set__content {
      &:before {
        border-color: var(--color-logged-out-button);
      }
      &:after {
        opacity: 1;
      }
    }
  }
  &__content {
    position: relative;
    @include flex;
    padding-left: 21px;
    &:before, &:after {
      position: absolute;
      top: 50%;
      display: block;
      content: "";
      border-radius: 100%;
      transition: .2s;
      transform: translateY(-50%);
    }
    &:before {
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid var(--color-mono-4);
    }
    &:after {
      left: 3px;
      width: 9px;
      height: 9px;
      background: var(--color-logged-out-button);
      opacity: 0;
    }
    .crevo-help_fill {
      margin-left: 4px;
    }
  }
  &__title {
    font-size: 14px;
    line-height: 1.3;
  }
}
</style>
