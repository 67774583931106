import Profile from 'src/store/profile/modules/profile'
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import Announcements from './modules/announcements'
import PickedJobOffers from './modules/pickedJobOffers'
import RecommendedCreators from './modules/recommendedCreators'
import SpecialFeatures from './modules/specialFeatures'
import UserRecommendations from './modules/userRecommendations'

Vue.use(Vuex)

const store = new Store({
  modules: {
    Announcements,
    PickedJobOffers,
    Profile,
    RecommendedCreators,
    SpecialFeatures,
    UserRecommendations,
  },
  strict: process.env.NODE_ENV !== 'production'
})

export default store
