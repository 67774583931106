import axios from '@axios'

export default {
  namespaced: true,
  state: {
    organization_id: null,
    organization_audition_rate: 0,
    organization_name: null,
    organization_users: [],
    organizationsError: null,
  },
  actions: {
    async fetchOrganizations({ commit }, { organizationId }) {
      try {
        const {data} = await axios.get(`/api/v2/organizations/${organizationId}`)
        commit('setOrganizations', data.organization)
      } catch (err) {
        commit('setError', err)
      }
    },
  },
  mutations: {
    setOrganizations(state, organization) {
      state.organization_id = organization.id
      state.organization_audition_rate = organization.audition_rate
      state.organization_name = organization.name
      state.organization_users = organization.users
    },
    setError(state, error) {
      if(error) state.organizationsError = error
    }
  },
}
