<template lang="pug">
  .o-confirmation-summary
    JobOfferSummary.o-confirmation-summary__information(:summary="summary")
    .o-confirmation-summary__item
      AuditionRequirements.o-confirmation-summary__requirements(
        v-if="isMobile"
        :deliveryDate="deliveryDate"
        :price="price"
        :priceRangeId="priceRangeId"
        :limitDate="limitDate"
        :contractStatus="contractStatus"
        :negotiable="negotiable"
        :budgetType="budgetType"
      )
      InfoPanel(v-if="otherConditions" title="留意事項" :description="otherConditions" :isCreator="false")
    .o-confirmation-summary__item(v-if="requestedCreator")
      Title.o-confirmation-summary__caption(title="依頼するクリエイター")
      UserProfileUnit(:user="requestedCreator" color="white")
</template>

<script>
import Title from 'src/components/atoms/Title'
import UserProfileUnit from 'src/components/molecules/CreatorAudition/UserProfileUnit'
import InfoPanel from 'src/components/molecules/InfoPanel'
import AuditionRequirements from 'src/components/organisms/CreatorAudition/AuditionRequirements'
import JobOfferSummary from 'src/components/organisms/CreatorAudition/JobOfferSummary'
import { UAParser } from 'ua-parser-js'

const deviceType = new UAParser().getDevice().type

export default {
  name: 'ConfirmationSummary',
  components: {
    Title,
    UserProfileUnit,
    InfoPanel,
    AuditionRequirements,
    JobOfferSummary,
  },
  props: {
    summary: {
      type: Object,
      required: false,
      default: null,
    },
    priceRangeId: {
      type: Number,
      required: false,
      default: null,
    },
    price: {
      type: Number,
      required: false,
      default: 0,
    },
    budgetType: {
      type: String,
      required: true,
    },
    otherConditions: {
      type: String,
      required: false,
      default: null
    },
    deliveryDate: {
      type: [Date, String],
      required: true,
    },
    limitDate: {
      type: [Date, String],
      required: true,
    },
    negotiable: {
      type: Boolean,
      required: true,
    },
    contractStatus: {
      type: String,
      required: false,
      default: '',
    },
    requestedCreator: { // NOTE：再依頼、ダイレクトオファー時にのみ使用
      type: Object,
      required: false,
      default: null
    },
  },
  data() {
    return {
      isMobile: deviceType === 'mobile' || deviceType === 'tablet',
    }
  },
}
</script>

<style lang="scss" scoped>
.o-confirmation-summary {
  &__information {
    margin-bottom: 20px;
  }
  &__requirements {
    margin-bottom: 20px;
    border: 1px solid var(--color-mono-7);
  }
  &__item {
    margin-bottom: 36px;
    @include mq-down(tablet--under) {
      margin-bottom: 30px;
    }
  }
  &__caption {
    margin-bottom: 16px;
  }
}
</style>
