<template lang="pug">
  transition(name="fade")
    .a-notification-icon(
      v-if="isShow"
      :style="{ 'min-width': size + 'px', 'height': size + 'px', 'border-radius': size + 'px' }"
    )
      span.a-notification-icon__text(v-if="count") {{ formattedCount }}
</template>

<script>


export default {
  name: 'NotificationIcon',
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    count: {
      type: Number,
      required: false,
      default: null
    },
    size: {
      type: Number,
      required: false,
      default: 16
    }
  },
  computed: {
    formattedCount() {
      // 100件以上の場合は桁数多くなるので調整
      return this.count >= 100 ? '99+' : this.count
    }
  }
}
</script>

<style lang="scss" scoped>
.a-notification-icon {
  @include flex(center);
  position: absolute;
  z-index: 10;
  line-height: 1.1;
  background: var(--color-red);
  border: 1px solid var(--color-mono-c);
  &__text {
    padding: 0 2px;
    font-family: var(--font-family-default);
    font-size: 10px;
    color: var(--color-mono-c);
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .15s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
