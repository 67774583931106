<template lang='pug'>
transition(name='slide-down' @after-enter='focus')
  .creator-list-create-form(v-if='showing')
    input.creator-list-create-form__input(v-model='title' ref='title' placeholder='グループ名を入力')
    .creator-list-create-form__actions
      button.c-button.creator-list-create-form__cancel(@click='hide') キャンセル
      button.c-button.c-button--primary.creator-list-create-form__create(@click='apply') 作成
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    showing: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  data() {
    return {
      title: null
    }
  },

  methods: {
    ...mapActions('creatorLists', ['create']),

    focus() {
      this.$refs.title.focus()
    },

    apply() {
      this.create({ title: this.title }).then(() => {
        this.hide()
      })
    },

    hide() {
      this.title = null
      this.$emit('hide')
    }
  }
}
</script>

<style lang='scss' scoped>
.creator-list-create-form {
  margin-top: 13px;
  padding-top: 20px;
  border-top: 1px solid var(--color-mono-7);
  transition: .25s cubic-bezier(0, 0.76, 0.27, 1);
  transform: scaleY(1);
  transform-origin: top;

  &.slide-down-enter,
  &.slide-down-leave-to {
    transform: scaleY(0);
  }

  &__input {
    width: 100%;
    height: 40px;
    padding: 13px 15px;
    color: var(--color-mono-1);
    background-color: var(--color-mono-c);
    border-radius: 3px;
    border: 1px solid var(--color-mono-5);
    outline: none;

    &::placeholder {
      color: var(--color-mono-4);
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
  }

  &__cancel {
    margin-right: 8px;
  }
}
</style>
