<template lang='pug'>
  .basecamp-search__condition(:class='showingList ? "basecamp-search__condition--open" : ""')
    .basecamp-search__condition-button(@click.stop='toggleList' :class='buttonState') {{ categoryName }}
    transition(name='fade')
      .basecamp-search__condition-tooltip(v-if='showingList')
        .basecamp-search__condition-container
          slot
        .basecamp-search__action-container
          .basecamp-search__action__cancellation_all(@click="cancellationAll()") ✕ すべて解除
          .basecamp-search__action
            .basecamp-search__cancel(@click.stop="cancel()") キャンセル
            button.c-button.c-button--primary.c-button--no-min-width.basecamp-search__apply(@click.stop.prevent="apply()") 適用
    transition(name='fade')
      .basecamp-search__condition__background(v-if='showingList' @click="cancel()")
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  props: {
    categoryName: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      showingList: false
    }
  },

  computed: {
    listStyle() {
      return {
        visibility: this.showingList ? 'visible' : 'hidden',
        opacity: this.showingList ? '.98' : '0'
      }
    },
    buttonState() {
      if (this.showingList) {
        return 'basecamp-search__condition-button--open'
      } else if (this.$attrs.isActive) {
        return 'basecamp-search__condition-button--active'
      } else {
        return 'basecamp-search__condition-button--inactive'
      }
    }
  },

  methods: {
    ...mapMutations('form', { formSetCheckItems: 'setCheckItems' }),

    showList() {
      this.showingList = true
    },

    hideList() {
      this.showingList = false
    },

    toggleList() {
      this.showingList = !this.showingList
    },

    cancellationAll() {
      this.$emit('cancellationAll')
    },

    cancel() {
      this.$emit('cancel')
      this.hideList()
    },

    apply() {
      this.$emit('apply')
      this.hideList()
    },

    // google analyticsのためだけに利用
    _currentTitle() {
      let title
      if(location.href.match(/creators/)) title = 'クリエイター検索'
      if(location.href.match(/portfolios/)) title = '作品検索'

      return title
    },
  }
}
</script>

<style lang="scss" scoped>
  @mixin generateTemplate($column-count,$row-count) {
    grid-area: condition-container;
    display: grid;
    grid-auto-flow: row;
    gap: 20px;
    grid-template-columns: repeat($column-count, max-content);
    grid-template-rows: repeat($row-count, min-content);
  }

  .basecamp-search {
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active
    {
      z-index: 10000;
    }

    &__condition-button {
      position: relative;
      height: 30px;
      line-height: 12px;
      padding: 10px 9px;
      margin-right: 10px;
      border-radius: 5px;
      transition: .3s;
      cursor: pointer;

      &:hover {
        color: var(--color-mono-1);
        background-color: var(--color-mono-6);
      }

      &--open {
        z-index: 1;
      }

      &--active {
        color: var(--color-mono-c);
        background-color: var(--color-mono-2);
      }

      &--inactive {
        color: var(--color-mono-2);
        background-color: var(--color-mono-7);
      }
    }

    &__condition__background {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10000;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .7);
      transition: .3s opacity;
    }

    &__condition-tooltip {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10001;
      display: grid;
      grid-template:
          ".          .           ." 36px
          ".  condition-container ." auto
          ".          .           ." 10px
          ".   action-container   ." 30px
          ".          .           ." 34px /
        40px auto 36px;
      border-radius: 5px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      background-color: var(--color-mono-c);
      transition: .3s opacity;
      max-width: 1240px;
    }

    &__condition-container {
      grid-area: condition-container;
    }

    &__action-container {
      grid-area: action-container;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__action {
      display: flex;
      align-items: center;
    }

    &__action__cancellation_all {
      color: #0576b9;
      font-size: 13px;
      transition: .3s;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    &__cancel {
      color: #0576b9;
      font-size: 13px;
      margin-right: 20px;
      transition: .3s;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
</style>
