export default {
  namespaced: true,
  state: {
    isShow: false,
    withAlert: false,
    message: null
  },
  mutations: {
    showSuccessMessage(state, { text }) {
      state.isShow = true
      state.withAlert = false
      state.message = text
    },
    showErrorMessage(state, { text }) {
      state.isShow = true
      state.withAlert = true
      state.message = text
    },
    hideMessage(state) {
      state.isShow = false
    },
    setFlashMessageVisibility(state, bool) {
      state.isShow = bool
    }
  }
}
