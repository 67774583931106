require('intersection-observer')

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'url-polyfill'
import { Notifier } from '@airbrake/browser'
import Vue from 'vue'

if (process.env.RAILS_ENV !== 'development' && process.env.RAILS_ENV !== 'test') {
  const airbrake = new Notifier({
    projectId: process.env.AIRBRAKE_PROJECT_ID + 'disable',
    // remove disable string to enable airbrake
    projectKey: process.env.AIRBRAKE_PROJECT_KEY + 'disable',
    environment: process.env.RAILS_ENV
  })

  Vue.config.errorHandler = function (err, vm, info) {
    airbrake.notify({
      error: err,
      context: { component: vm },
      params: { info }
    })
  }
}
