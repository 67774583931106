const state = {
  creator: null,
  loading: false,
  token: null,
}

const getters = {
  creator(state) {
    return state.creator
  },

  loading(state) {
    return state.loading
  },

  token(state) {
    return state.token
  }
}

const actions = {
  share({ dispatch, commit, getters }, params) {
    const newParams = Object.assign(params, { creatorId: getters.creator.id })

    return new Promise((resolve, reject) => {
      commit('asyncStart')

      dispatch('shareAdmission/create', newParams, { root: true }).then(token => {
        commit('setToken', token)
        commit('asyncFinish')

        return resolve(token)
      }).catch(errors => {
        commit('asyncFinish')
        return reject(errors.response.data.messages)
      })
    })
  }
}

const mutations = {
  set(state, creator) {
    state.creator = creator
  },

  unset(state) {
    state.creator = null
  },

  setToken(state, token) {
    state.token = token
  },

  asyncStart(state) {
    state.loading = true
  },

  asyncFinish() {
    state.loading = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
