import axios from '@axios'

export default {
  namespaced: true,
  state: {
    id: null,
    name: '',
    last_name_initial: '',
    creator_portrolio_url: '',
    profile_image_url: '',
    icon_background_color: ''
  },
  actions: {
    async fetchUser({ commit }, { userId }) {
      const {data} = await axios.get(`/api/v2/users/${userId}`)
      commit('setUser', data.user)
    }
  },
  mutations: {
    setUser(state, user) {
      Object.assign(state, user)
    }
  },
}
