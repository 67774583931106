import axios from '@axios'

export default {
  namespaced: true,
  state: {
    auditionChats: [],
    projectChats: [],
    messages: [],
    newlyArrivedMessageCount: 0,
  },
  actions: {
    async fetchStatus({ commit }) {
      const response = await axios.get('/api/status')
      commit('setProjectChats', response.data.events)
      commit('setMessages', response.data.events)
      commit('setNewlyArrivedMessageCount', response.data.events)
    },
    async patchNotification() {
      await axios.patch('/notifications/mark_all_as_read')
    }
  },
  mutations: {
    setProjectChats(state, { chat }) {
      if(chat) state.projectChats = chat
    },
    setMessages(state, { notifications }) {
      if(notifications) state.messages = notifications.messages
    },
    setNewlyArrivedMessageCount(state, { notifications }) {
      state.newlyArrivedMessageCount = notifications.newly_arrived_message_count
    }
  }
}
