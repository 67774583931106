<template lang='pug'>
  .m-unit-menu
    button.m-unit-menu__button(
      @click="toggleUnitMenu($event)"
      v-on-clickaway="hideUnitMenu"
    )
      i.crevo-icon.crevo-option
    Popover.m-unit-menu__popover(
      :isShowPopOver="showUnitMenu"
      :class="{'m-unit-menu__popover--upper': positionUpper}"
      :style="{minWidth: `${width}px`}"
    )
      ul.m-unit-menu__list
        slot
</template>
<script>
import Popover from 'src/components/atoms/Popover'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'UnitMenu',
  components: { Popover },
  mixins: [clickaway],
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 210
    },
    maxUnitHeight: {
      type: [Number, String],
      required: false,
      default: 0
    }
  },
  data() {
    return {
      showUnitMenu: false,
      positionUpper: false,
    }
  },
  methods: {
    toggleUnitMenu(e) {
      this.showUnitMenu = !this.showUnitMenu
      // NOTE：UnitMenuを開いた時のY座標を取得
      const unitMenuPositionY = e.clientY
      // NOTE：取得した座標と表示するユニットの最大の高さ(余裕をもたせて120px)が画面の高さより大きければ上に表示する
      this.positionUpper = unitMenuPositionY + Number(this.maxUnitHeight) > window.innerHeight ? true : false
    },
    hideUnitMenu() {
      this.showUnitMenu = false
    },
  }
}
</script>
<style lang='scss' scoped>
.m-unit-menu {
  position: relative;
  width: 36px;
  &__button {
    @include flex(center);
    width: 36px;
    height: 32px;
    background: transparent;
    border: 0;
    outline: 0;
    transition: .2s;
    &:hover {
      opacity: .7;
    }
    .crevo-icon {
      font-size: 15px;
      color: var(--color-mono-3);
    }
  }
  &__popover {
    position: absolute;
    top: calc(100% + 14px); // NOTE：カードから10px下に余白出来るように調整
    right: -18px;
    bottom: auto;
    z-index: 10;
    padding: 13px;

    &--upper {
      top: auto;
      bottom: 100%;
    }
  }
}
</style>
<style lang="scss">
.m-unit-menu {
  &__list {
    li {
      padding: 13px!important;
      // NOTE：読み込み元の関係ないのが邪魔するのでimportantを設定
      font-size: 13px!important;
      line-height: 1.1;
      cursor: pointer;
      transition: .2s;
      &:hover {
        background: var(--color-mono-b);
      }
    }
  }
}
</style>
