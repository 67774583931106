<template lang='pug'>
i.add-list-icon.crevo-icon(:class='klass')
</template>

<script>
export default {
  props: {
    isAdded: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  computed: {
    klass() {
      return this.isAdded ? 'add-list-icon--is-added crevo-list_user_fill' : 'crevo-list_user'
    }
  }
}
</script>

<style lang='scss' scoped>
  @keyframes add-list{
    0%{
      transform: scale(0.8);
    }
    50%{
      transform: scale(1.2);
    }
    100%{
      transform: scale(1);
    }
  }
  .add-list-icon {
    display: flex;
    color: var(--color-mono-5);
    transition: 0.3s all ease;

    &:hover,
    &:active {
      color: var(--color-mono-4);
    }

    &--is-added {
      color: var(--color-favorite);
      animation: add-list 0.3s ease;
    }
  }
</style>
