const isPlayableVideoUrl = url => {

  const playableUrlPattern = /^https:\/\/(www\.){0,1}(youtube.com|youtu.be|vimeo.com).([^/]+).*/
  // NOTE：player.vimeo.comはvimeo側で500返却されるので除外
  const unplayableUrlPattern = /player.vimeo.com/

  return playableUrlPattern.test(url) && !unplayableUrlPattern.test(url)
}

export default isPlayableVideoUrl
