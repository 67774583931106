<template lang='pug'>
  transition(name="fade")
    .u-tooltip(v-if="showingTooltip", :style="{top: clientY + 'px', left: clientX + 'px'}")
      | {{title}}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('tooltip', {
      showingTooltip: 'showingTooltip',
      title: 'title',
      clientX: 'clientX',
      clientY: 'clientY',
    }),
  },
}
</script>

<style lang="scss" scoped>
.u-tooltip {
  pointer-events: none;
  position: fixed;
  z-index: 9999;
  max-width: 200px;
  font-size: 11px;
  background-color: var(--color-mono-c);
  padding: 3px 10px;
  border-radius: 3px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
  opacity: 0.98;
  // ツールチップ の座標位置
  transform: translate(-50%, -120%);
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
