<template lang="pug">
  .a-card
    slot
</template>
<script>
export default {
  name: 'Card',
}
</script>

<!-- NOTE スコープをグローバルにすることで他の箇所から上書きできるようにしている -->
<style lang="scss">
.a-card {
  padding: 60px;
  background: var(--color-mono-c);
  border-radius: 5px;
  box-shadow: var(--decoration-card-shadow);
  @include mq-down(tablet) {
    border-radius: 0;
  }
}
</style>
