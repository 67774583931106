<template lang="pug">
.o-attached-thumbnail
  LoadingIcon.o-attached-thumbnail__loading-icon(v-if="loading")
  .o-attached-thumbnail__inner(v-else)
    label.o-attached-thumbnail__inputs(
      for="file"
      :class="{'o-attached-thumbnail__inputs--hover': isDragOver}"
      @drop.prevent="changeThumbnail"
      @dragover.prevent="onDrag('over')"
      @dragleave.prevent="onDrag('leave')"
    )
      input.o-attached-thumbnail__input(type="file" id="file" :accept="validImageTypes.join(',')" @change="changeThumbnail")
      i.crevo-icon.crevo-attached_fill
      span.o-attached-thumbnail__text {{ uploadText }}
    ul.o-attached-thumbnail__list
      //- アップロード画像
      li.o-attached-thumbnail__item(v-if="thumbnailURL !== null" @click="selectAttachedThumbnail")
        transition(name="fade")
          .o-attached-thumbnail__selected(v-if="hasSelectedAttachedImage") 選択中
        img.o-attached-thumbnail__image(:src="thumbnailURL")
      //- 選択サムネイル
      li.o-attached-thumbnail__item(
        v-for="thumbnail in thumbnails"
        :key="thumbnail.name"
        @click="selectThumbnail(thumbnail.name)"
      )
        transition(name="fade")
          .o-attached-thumbnail__selected(v-if="thumbnail.name  === thumbnailName") 選択中
        img.o-attached-thumbnail__image(:src="thumbnail.image")
</template>

<script>
import LoadingIcon from 'src/components/atoms/LoadingIcon'
import { thumbnails } from 'src/lib/creatorAudition/thumbnails'
import { UAParser } from 'ua-parser-js'

const deviceType = new UAParser().getDevice().type

export default {
  name: 'AttachedThumbnail',
  components: {
    LoadingIcon
  },
  props: {
    thumbnailURL: { // NOTE：URLで受け取ってから変換などを行う
      type: String,
      default: null,
      required: false
    },
    thumbnailName: {
      type: String,
      default: null,
      required: false
    },
    thumbnailFile: {
      type: File,
      default: null,
      required: false
    },
  },
  data() {
    return {
      isMobile: deviceType === 'mobile' || deviceType === 'tablet',
      loading: false,
      isDragOver: false,
      thumbnails: thumbnails,
      validImageTypes: ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp'],
    }
  },
  computed: {
    uploadText() {
      return this.isMobile ? 'タップで追加' : 'ドロップで追加'
    },
    hasSelectedAttachedImage() {
      return this.thumbnailName === null
    }
  },
  mounted() {
    if (this.thumbnailURL !== null) {
      this.loading = true

      const fileName = this.thumbnailURL.match('.+/(.+?).[a-zA-Z]+([?#;].*)?$')[1]
      const cutFileName = fileName.slice(fileName.indexOf('thumbnail-')).replace('thumbnail-', '')

      // NOTE：デフォルトのサムネイルを選択している場合はファイル名に thumbnail- が含まれている
      if (fileName.includes('thumbnail-')) {
        const checkExistingThumbnail = this.thumbnails.find(data => cutFileName === data.name)
        // NOTE：もし既存サムネイルではないがファイル名にthumbnail-が入っていた場合は何も返却しない
        if (checkExistingThumbnail) {
          this.setThumbnailURL(null)
          this.setThumbnailName(checkExistingThumbnail.name)
        }
        this.loading = false
      } else {
        this.loading = false
      }
    }
  },
  methods: {
    selectThumbnail(name) {
      this.setThumbnailName(name)
    },
    selectAttachedThumbnail() {
      this.setThumbnailName(null)
    },
    encodeToBase64 (file) { // NOTE：アップロードした画像をプレビュー用に非同期にBase64に変換
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    onDrag(type) {
      this.isDragOver = type === 'over'
    },
    changeThumbnail(e) {
      this.isDragOver = false
      const images = e.target.files || e.dataTransfer.files
      // NOTE：キャンセルした場合にfilesの中身がnullで返却されるのでキャンセルの場合はreturn
      if (images.length === 0) return

      if (images[0].size > 5000000) {
        this.setFlashMessage('ファイルの上限サイズ5MBを超えています。')
        return
      }

      if (images.length === 1 && this.validImageTypes.includes(images[0].type)) {
        const file = images[0]
        this.setThumbnailFile(file)

        this.encodeToBase64(file)
          .then(image => {
            this.setThumbnailURL(image)
            this.setThumbnailName(null)
          })
      } else {
        this.setFlashMessage('こちらの画像形式は非対応となります。\n対応画像形式は、jpg、png、gif、webp、bmpのいずれかです。')
      }
    },
    setThumbnailName(name) {
      this.$emit('existingThumbnail', name)
    },
    setThumbnailURL(url) {
      this.$emit('setThumbnailURL', url)
    },
    setThumbnailFile(file) {
      this.$emit('attachedThumbnail', file)
    },
    setFlashMessage(text) {
      this.$emit('setFlashMessage', text)
    },
  }
}
</script>

<style lang="scss" scoped>
.fade-enter, .fade-leave-to {
  opacity: 0;
  transition: .05s;
}
.fade-enter-to {
  opacity: 1;
}

.o-attached-thumbnail {
  width: 100%;
  height: 146px;
  background: var(--color-mono-9);
  border-radius: 5px;
  &__inner {
    @include flex;
    padding: 20px 30px;
    overflow-y: scroll;
  }
  &__list {
    @include flex;
  }
  &__item {
    position: relative;
    width: 160px;
    height: 106px;
    margin-left: 16px;
  }
  &__image {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 5px;
    transition: .2s;
    object-fit: cover;
    &:active {
      opacity: .8;
    }
    @include mq-up(narrow-pc) {
      &:hover {
        opacity: .8;
      }
    }
  }
  // 選択中部分
  &__selected {
    @include flex(center);
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    font-size: 28px;
    color: var(--color-mono-c);
    letter-spacing: 2px;
    background: rgba(70,70,70,.8);
    border-radius: 5px;
  }
  // 画像アップ部分
  &__inputs {
    @include flex(center, center, column);
    flex: 1 0 160px;
    height: 106px;
    border: dashed 2px var(--color-mono-3);
    border-radius: 5px;
    transition: .2s;
    &--hover {
      opacity: .8;
    }
    &:active {
      opacity: .8;
    }
    @include mq-up(narrow-pc) {
      &:hover {
        opacity: .8;
      }
    }
    .crevo-icon {
      margin-bottom: 10px;
      font-size: 50px;
      color: var(--color-mono-5);
    }
  }
  &__text {
    font-size: 12px;
    line-height: 1.1;
    color: var(--color-mono-3);
  }
  &__input {
    display: none;
  }
  // ローディングアイコン
  &__loading-icon {
    @include flex(center);
    height: 100%;
    margin: 0 auto;
  }
}
</style>
