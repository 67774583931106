<template lang='pug'>
  .basecamp-search__select-order
    .basecamp-search__selected-order(@click.stop='toggleList', v-on-clickaway='hideList') {{ selectedName }}
    .basecamp-search__order-caret
    ul.basecamp-search__order-list(:style='listStyle')
      li.basecamp-search__order-item(v-for='sortOption in sortOptions' @click.stop='select(sortOption.id)') {{ sortOption.name }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import selectList from '../mixins/select-list'

export default {
  mixins: [ selectList ],

  computed: {
    ...mapGetters('inputItems', ['sortOptions', 'selectedSortOption']),

    selectedName() {
      return this.selectedSortOption ? this.selectedSortOption.name : '実績件数順'
    },
  },

  methods: {
    ...mapActions('inputItems', { loadInputItems: 'load' })
  }
}
</script>

<style lang="scss" scoped>
  .basecamp-search {
    &__select-order {
      position: relative;
      width: 100px;
      margin-top: 2px;
      border-top-left-radius: 28px;
      border-bottom-left-radius: 28px;
      background-color: var(--color-mono-9);
      text-align: left;
    }

    &__selected-order {
      font-size: 13px;
      color: rgba(17, 17, 17, .9);
      cursor: pointer;
    }

    &__order-caret {
      position: absolute;
      top: 6px;
      right: 20px;
      width: 6px;
      height: 4px;
      transition: .3s transform;
      background-position: center;
      background-repeat: no-repeat;
      pointer-events: none;
    }

    &__order-list {
      position: absolute;
      top: 30px;
      right: 10px;
      z-index: 10;
      width: 180px;  // 一番長い項目に合わせています
      max-height: 355px;
      padding: 10px;
      background-color: var(--color-mono-c);
      border-radius: 3px;
      box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
      overflow-y: scroll;
      list-style-type: none;
      text-align: left;
      transition: .3s;
    }

    &__order-item {
      padding: 11px 14px 10px;
      font-size: 13px;
      color: var(--color-mono-1);
      border-radius: 3px;
      transition: .3s background-color;
      cursor: pointer;

      &:hover {
        background-color: var(--color-mono-a);
      }
    }
  }
</style>
