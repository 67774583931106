<template lang="pug">
  .o-input-reference-movies
    LabelText(text="参考にしたい作品URL")
    .o-input-reference-movies__list
      transition-group(name="fade")
        .o-input-reference-movies__item(v-for="(reference, index) in referenceMoviesData" :key="`reference-${index}`")
          InputReference(
            :url="reference.url"
            :reasonJA="reference.reasonJA"
            :reasonEN="reference.reasonEN"
            :withEN="withEN"
            :index="index"
            @inputReferenceMovie="emitReferenceMovies"
          )
          .o-input-reference-movies__buttons
            transition(name="fade")
              button.o-input-reference-movies__button.o-input-reference-movies__button--minus(type="button" v-if="index > 0" @click="deleteReference(index)")
                i.crevo-icon.crevo-minus
            transition(name="fade")
              button.o-input-reference-movies__button.o-input-reference-movies__button--plus(type="button" v-if="referenceMoviesData.length === index + 1" @click="addReference")
                i.crevo-icon.crevo-plus
    .o-input-reference-movies__addition(v-if="withAddition")
      HelpText.o-input-reference-movies__help-text(message="※YouTube／Vimeo／画像URL、等\n※Crevoの実績動画に参考にしたい作品がありましたらお気軽にご相談ください。")
      Button.o-input-reference-movies__to-example(title="制作事例" href="https://videoworks.com/case/" target="_blank" size="s")
</template>

<script>
import Button from 'src/components/atoms/Button'
import HelpText from 'src/components/atoms/HelpText'
import LabelText from 'src/components/atoms/LabelText'
import InputReference from 'src/components/molecules/CreatorAudition/InputReference'

export default {
  name: 'InputReferenceMovies',
  components: {
    Button,
    HelpText,
    LabelText,
    InputReference,
  },
  props: {
    withAddition: { // NOTE：管理画面側だと不要なのでpropsで表示判定できるように用意
      type: Boolean,
      required: false,
      default: true
    },
    referenceMovies: {
      type: Array,
      required: false,
      default: () => []
    },
    withEN: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      referenceMoviesData: []
    }
  },
  mounted() {
    if (this.referenceMovies.length === 0) {
      this.addReference()
    } else {
      this.referenceMoviesData = [...this.referenceMovies]
    }
  },
  methods: {
    emitReferenceMovies(data, index) {
      // NOTE：moleculesから渡ってきたものをindexで順番取得して置換
      this.referenceMoviesData.splice(index, 1, data)
      this.$emit('inputReferenceMovies', [...this.referenceMoviesData])
      this.$emit('validation')
    },
    addReference() {
      const referenceMovieFormat = {
        url: '',
        reasonJA: '',
        reasonEN: '',
      }
      this.referenceMoviesData.push(referenceMovieFormat)
    },
    deleteReference(deleteIndex) {
      const filterReference = this.referenceMoviesData.filter((data, index) => index !== deleteIndex)
      this.referenceMoviesData = filterReference
      this.$emit('inputReferenceMovies', [...this.referenceMoviesData])
      this.$emit('validation')
    }
  }
}
</script>

<style lang="scss" scoped>
.o-input-reference-movies {
  &__list {
    margin-top: 10px;
  }
  &__item {
    position: relative;
    margin-bottom: 10px;
    @include mq-down(tablet--under) {
      width: calc(100% - 30px);
    }
  }
  &__help-text {
    display: block;
    margin-bottom: 12px;
    line-height: 1.6;
    white-space: pre-wrap;
  }
  &__buttons {
    position:absolute;
    top: 0;
    right: -38px;
    z-index: 1;
    width: 24px;
    height: 94px; // inputx2と余白の合計
    @include mq-down(tablet--under) {
      right: -30px;
    }
  }
  &__button {
    @include flex(center);
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    font-size: 12px;
    color: var(--color-mono-c);
    border-radius: 100%;
    transition: .2s;
    &:active {
      opacity: .8;
    }
    @include mq-up(narrow-pc) {
      &:hover {
        opacity: .8;
      }
    }
    &--minus {
      top: 20px;
      margin-bottom: 6px;
      background: var(--color-red);
    }
    &--plus {
      bottom: 20px;
      background: var(--color-green);
    }
  }
}
// アニメーション
.fade-enter-active, .fade-leave-active {
  transition: opacity .15s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
