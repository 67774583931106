<template lang='pug'>
.a-small-tooltip
  .a-small-tooltip__item(@click="showTooltip" @mouseover="showTooltip" @mouseleave="hideTooltip")
    slot
  transition(name="fade")
    .a-small-tooltip__content(v-if="isShow" v-on-clickaway="hideTooltip" :style="{'max-width': maxWidth}") {{ text }}
</template>

<script>
import { UAParser } from 'ua-parser-js'
import { mixin as clickaway } from 'vue-clickaway'
const deviceType = new UAParser().getDevice().type

export default {
  mixins: [clickaway],
  props: {
    text: {
      type: String,
      required: true
    },
    maxWidth: {
      type: String,
      required: false,
      default: '120px'
    }
  },
  data() {
    return {
      isTouchDevice: deviceType === 'mobile' || deviceType === 'tablet',
      isShow: false,
    }
  },
  methods: {
    showTooltip() {
      // TODO：画面端に出てしまいそうなパターンなどが発生したら座標取得と調整を追加する
      this.isShow = true
    },
    hideTooltip() {
      this.isShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.a-small-tooltip {
  position: relative;

  &__content {
    @include text-overflow;
    position: absolute;
    bottom: 110%;
    left: 50%;
    padding: 6px;
    font-size: 12px;
    line-height: 1.1;
    color: var(--color-mono-c);
    background: var(--color-mono-2);
    border-radius: 3px;
    transform: translateX(-50%);
  }

  .fade-enter-active, .fade-leave-active {
    transition: .2s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}
</style>
