const state = {
  showingTooltip: false,
  title: null,
  clientX: 0,
  clientY: 0,
}

const getters = {
  showingTooltip(state) {
    return state.showingTooltip
  },

  title(state) {
    return state.title
  },

  clientX(state) {
    return state.clientX
  },

  clientY(state) {
    return state.clientY
  },
}

const mutations = {
  setShowingTooltip(state, boolean) {
    state.showingTooltip = boolean
  },

  setTitle(state, title) {
    state.title = title
  },

  setClientX(state, x) {
    state.clientX = x
  },

  setClientY(state, y) {
    state.clientY = y
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
