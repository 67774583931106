<template lang='pug'>
  transition(name='fade')
    a.basecamp-search__move-top-button(v-if='show' href='#basecamp-search-form' v-smooth-scroll='{ duration: 500, offset: -50 }')
      img(:src='imagePath("basecamp/common/move-top-button.svg")')
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.basecamp-search__move-top-button {
  position: fixed;
  z-index: 9999;
  right: 20px;
  bottom: 95px;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  transition: .3s;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);

  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
