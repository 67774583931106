<template lang="pug">
  .a-no-data
    .a-no-data__text {{ text }}
    .a-no-data__item(v-if="this.$slots.default !== undefined")
      slot
</template>

<script>
export default {
  name: 'NoData',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.a-no-data {
  @include flex(center, center, column);
  font-size: 14px;
  line-height: 2;
  color: var(--color-mono-1);

  &__text {
    text-align: center;
    white-space: pre-wrap;
  }

  &__item {
    margin-top: 14px;
  }
}
</style>
