<template lang='pug'>
  span
    template(v-if='user.profile_image_url')
      img.u-user-avatar-initial(:class='sizeClass' :src='user.profile_image_url')
    template(v-else)
      i.u-user-avatar-initial(:class='sizeClass' :style="initialIconStyle") {{user.last_name_initial}}
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },

    // see app/assets/stylesheets/common/object/utility/user-avatar.scss
    size: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      sizeClass: `u-user-avatar--${this.size}`,
      initialIconStyle: {
        backgroundColor: this.user.icon_background_color
      }
    }
  }
}
</script>
