<template lang='pug'>
  span.a-help-text {{ message }}
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang='scss' scoped>
// stylesheets/collet/components/atoms/_help_text.scssを参照
</style>
