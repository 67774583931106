<template lang="pug">
.o-input-price
  .o-input-price__wrapper
    Select.o-input-price__select(
      :options="budgetTypes"
      :selected="budget_type"
      :showEmpty="false"
      :disabled="disabled"
      size="l"
      v-if="showPullDown"
      @change="onSelectBudgetType"
    )
    .o-input-price__content(v-show="isProposal")
      .o-input-price__item
        .o-input-price__description
          h3.o-input-price__title 募集する募集金額のレンジ(税抜)
          p.o-input-price__text
            | ※こちらの金額をもとにクリエイターにご提案いただけます。
            br
            | ご請求の際は、依頼手数料({{ offerFeePercentage }}%)と消費税を加えた金額が請求されます。
        .o-input-price__inputs(v-if="isProposal")
          Select.o-input-price__pulldown(ref="suggestionPriceSelect" :required="isProposal" placeholder="価格帯を選択" :options="suggestionPrices" :selected="suggestionPriceValue" @change="onChangeSuggestionPrice" size="l" :disabled="disabled")
          transition(name="o-input-price__validation-message")
            p.o-input-price__validation-message(v-if="!valid")
              i.crevo-icon.crevo-exclamation_fill
              | {{validationMessage}}

    .o-input-price__content(v-show="!isProposal")
      .o-input-price__item
        .o-input-price__description
          h3.o-input-price__title(:class="{'o-input-price__title--required': withRequiredIcon}") 募集金額(税抜)
          p.o-input-price__text こちらの金額がクリエイターに表示されます。
        .o-input-price__inputs
          .o-input-price__input
            span &yen;
            Input(ref="specifyPriceInput" :required="!isProposal" type="number" :value="suggestionPriceValue" placeholder="300,000" size="l" min="1" max="999999999" @input="handleInputSpecifyPrice" :disabled="disabled")
          transition(name="o-input-price__validation-message")
            p.o-input-price__validation-message(v-if="!valid")
              i.crevo-icon.crevo-exclamation_fill
              | {{validationMessage}}

      .o-input-price__item
        .o-input-price__description
          h3.o-input-price__title 依頼手数料({{ offerFeePercentage }}%)
        .o-input-price__inputs
          p.o-input-price__fee &yen;{{ formatPriceValue(offerFee) }}

      .o-input-price__item.o-input-price__item--align-top
        .o-input-price__description
          h3.o-input-price__title 請求金額(税抜)
          p.o-input-price__text ご請求の際は、消費税を加えた金額が請求されます
        .o-input-price__inputs
          p.o-input-price__total &yen;{{ formatPriceValue(totalPrice) }}
  .o-input-price__note(v-if="showNote")
    | ※仕事依頼の参考価格は
    a(href="https://videoworks.com/help/pricelist/" target="_blank") こちら
    | を参照してください。
    br
    | ※相場や予算に関してのご相談は右下のチャットボタンより事務局までご相談ください。
</template>

<script>
import Input from 'src/components/atoms/Form/Input'
import Select from 'src/components/atoms/Form/Select'

export default {
  name: 'InputPrice',
  components: { Input, Select },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    budget_type: {
      type: String,
      required: false,
      default: null
    },
    budgetTypes: {
      type: Array,
      required: false,
      default: null
    },
    suggestionPriceValue: {
      type: [Number, String],
      required: false,
      default: null,
    },
    suggestionPrices: {
      type: Array,
      required: false,
      default: null
    },
    offerFeePercentage: {
      type: Number,
      required: false,
      default: 0
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showPullDown: {
      type: Boolean,
      required: false,
      default: true
    },
    showNote: {
      type: Boolean,
      required: false,
      default: true
    },
    withRequiredIcon: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      valid: true,
      validationMessage: null,
      showLoading: true,
    }
  },
  computed: {
    offerFee() {
      // 金額から手数料を計算
      // ex) 金額: 100,000、手数料%: 10%
      //     100000 * 0.1 = 10000
      return Number.isInteger(this.suggestionPriceValue) ? this.suggestionPriceValue * this.offerFeeRate : 0
    },
    offerFeeRate() {
      // %を割合に変換
      // ex) 10% -> 0.1
      return this.offerFeePercentage / 100
    },
    totalPrice() {
      return this.suggestionPriceValue + this.offerFee
    },
    isProposal() {
      return this.budget_type === 'proposal'
    }
  },
  watch: {
    suggestionPriceValue() {
      this.$emit('validation')
    }
  },
  updated() {
    this.checkValidity()
  },
  methods: {
    checkValidity() {
      if (this.suggestion) {
        this.valid = this.$refs.suggestionPriceSelect.checkValidity()
        this.validationMessage = this.$refs.suggestionPriceSelect.validationMessage()
      } else {
        this.valid = this.$refs.specifyPriceInput.checkValidity()
        this.validationMessage = this.$refs.specifyPriceInput.validationMessage()
      }

      this.$emit('validation')
    },
    onSelectBudgetType(e) {
      this.$emit('setBudgetType', e.target.value)
      this.$emit('validation')
      if(e.target.value === 'specify') {
        this.$nextTick(() => {
          this.$refs.specifyPriceInput.$el.focus()
        })
      }
    },
    onChangeSuggestionPrice(e) {
      this.valid = this.$refs.suggestionPriceSelect.checkValidity()
      this.validationMessage = this.$refs.specifyPriceInput.validationMessage()
      this.$emit('change', { type: 'suggestionPrice', payload: e.target.value })
      this.$emit('validation')
    },
    handleInputSpecifyPrice(e) {
      this.$emit('validation')
      this.$emit('input', { type: 'suggestionPrice', payload: e.target.value })
    },
    formatPriceValue(price) {
      const inputPrice = String(price).split('.')
      return inputPrice[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
    }
  }
}
</script>

<style lang="scss" scoped>
.o-input-price {
  &__wrapper {
    padding: 40px 40px 36px;
    color: var(--color-mono-1);
    background: var(--color-mono-b);
    border-radius: 5px;
    @include mq-down(tablet--under) {
      padding: 30px;
    }
  }
  &__select {
    margin-bottom: 30px;
  }
  &__item {
    @include mq-up(tablet) {
      @include flex(between, center);
    }
    &:not(:first-of-type) {
      margin-top: 20px;
    }
    &--align-top {
      align-items: flex-start;
    }
  }
  &__pulldown, &__input {
    width: 180px;
    @include mq-down(tablet--under) {
      width: 100%;
      margin-top: 14px;
    }
  }
  &__inputs {
    @include mq-up(tablet) {
      margin-left: 20px;
    }
  }
  &__input {
    position: relative;
    .a-input {
      // 43px(Yenの幅 + 左右のマージン)
      padding: 0 16px 0 43px;
      text-align: right;
    }
    span {
      position: absolute;
      top: 50%;
      left: 16px;
      font-size: 14px;
      letter-spacing: .4px;
      transform: translateY(-50%);
    }
  }
  &__title {
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.1;
    &--required {
      &:after {
        display: inline;
        color: var(--color-red);
        content: " *";
      }
    }
  }
  &__note {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.6;
    color: var(--color-mono-3);

    a {
      color: var(--color-text-link);

      &:hover, &:active {
        color: var(--color-text-link--hover);
        text-decoration: underline;
      }
    }
  }
  &__text {
    font-size: 14px;
    line-height: 1.6;
  }
  &__fee, &__total {
    margin-right: 6px;
  }
  &__fee {
    font-size: 16px;
  }
  &__total {
    font-size: 22px;
    font-weight: bold;
    @include mq-down(tablet--under) {
      margin-top: 6px;
    }
  }
  &__validation-message{
    @include flex(start, center);
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.2;
    color: var(--color-error);
    transition: .3s var(--transition-timing);
    &-enter{
      opacity: 0;
      transform: translateY(-100%);
    }
    &-leave-to{
      opacity: 0;
    }
    .crevo-icon{
      margin-right: 2px;
    }
  }
}
</style>
