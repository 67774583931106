export default [
  {
    name: 'creator_audition[offerType]',
    value: 'package',
    title: '動画全体を依頼',
    description: '動画制作の企画から納品まで動画制作の全てをお願いしたい。'
  },
  {
    name: 'creator_audition[offerType]',
    value: 'part',
    title: '部分的に依頼',
    description: '動画制作の一部の仕事をお願いしたい。'
  },
]
