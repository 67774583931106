export default {
  ja: {
    dashboard: {
      announcement: {
        title: 'ニュース',
        link: 'もっと見る',
        noData: 'お知らせはありません。'
      },
      profile: {
        applied: '応募中の案件'
      },
      recommendation: {
        title: '重要なお知らせ',
        noData: '現在重要なおしらせはありません。',
        description: 'ここでは、あなたに次にやってほしい重要なお知らせが表示されます。'
      }
    }
  },
  en: {
    dashboard: {
      announcement: {
        title: 'Announcement',
        link: 'See More',
        noData: 'No Announcement.'
      },
      profile: {
        applied: 'Applied Job Offers'
      },
      recommendation: {
        title: 'Important Information',
        noData: 'No Important Information.',
        description: 'Here is important information about your next action.',
        button: {
          remove: '通知を削除'
        }
      }
    }
  }
}
