import axios from '@axios'

export default {
  namespaced: true,
  state: {
    pickedJobOffers: [],
    pickedJobOffersError: null
  },
  actions: {
    async fetchPickedJobOffers({ commit }) {
      try {
        const response = await axios.get('/api/v2/dashboard/picked_job_offers')
        commit('setPickedJobOffers', response.data.picked_job_offers)
      } catch(err) {
        commit('setError', err)
      }
    },
  },
  mutations: {
    setPickedJobOffers(state, pickedJobOffers) {
      if(pickedJobOffers) state.pickedJobOffers = pickedJobOffers
    },
    setError(state, error) {
      if(error) state.pickedJobOffersError = error
    }
  }
}
