import axios from 'axios'

const baseURL = '/api/v2/profile_selection_items'

export default {
  fetchAll() {
    return new Promise((resolve, reject) => {
      axios.get(baseURL)
        .then(response => { resolve(response.data) })
        .catch(error => { reject(error) })
    })
  }
}
