// import axios from 'axios'
// import dayjs from 'dayjs'

// NOTE: 設置時は別途グローバル変数(var ONB)を宣言してください。

/*
  NOTE：引数は(種別、id)となる。
    ※idはorderならorder_id、job_offerならjob_offer_id
    ※種別毎に渡すパラメーターが違うので注意
    user：ユーザータイプのみ
    contact：ユーザータイプ、アカウント作成日時、依頼公開数、マッチング数、業種分類、初動ステータス、弊社からのサポートについてご希望するもの、動画制作のご予定について
    order：累計依頼公開回数、検収待ちの制作物があるかどうか、アカウント作成日時、依頼公開数、マッチング数、業種分類、初動ステータス、弊社からのサポートについてご希望するもの、動画制作のご予定について
    jobOffer:ユーザータイプ、案件状況（選定中、公開中など）、応募数、公開範囲、受注ステータス（受注前など）
  DOCS：https://docs.google.com/spreadsheets/d/1L7gU8mqhUmC20qFvVAwGLtEfMjfctszg/edit#gid=1312126577

*/
const withParameter = async (type, id) => {
    console.log(type, id)
  // const userAgent = window.navigator.userAgent.toLowerCase()
  // if (userAgent.indexOf('msie') >= 0 || userAgent.indexOf('trident') >= 0 ) return
  //
  // var ONB = ONB || {}
  // ONB.ignition_url = 'https://api.onboarding-app.io/v1/onboarding-init?aid=91&pid=108'
  //
  // try {
  //   if (type === 'order') {
  //     const {data} = await axios.get(`/api/v2/onboarding/orders/${id}`)
  //     if (data.created_at) data.created_at = dayjs(data.created_at).format('YYYY-MM-DD')
  //
  //     ONB._queryparam = data
  //
  //   } else if (type === 'jobOffer') {
  //     const {data} = await axios.get(`/api/v2/onboarding/job_offers/${id}`)
  //     if (data.created_at) data.created_at = dayjs(data.created_at).format('YYYY-MM-DD')
  //
  //     ONB._queryparam = data
  //
  //   } else {
  //     const {data} = await axios.get('/api/v2/onboarding/contact')
  //     if (data.created_at) data.created_at = dayjs(data.created_at).format('YYYY-MM-DD')
  //
  //     if (type === 'user') {
  //       ONB._queryparam = { role: data.role }
  //     } else {
  //       ONB._queryparam = data
  //     }
  //   }
  //
  //   // NOTE: Onboardingを除外したいパス
  //   ONB.black_list = []
  //
  //   // NOTE: コールバックの指定
  //   ONB._custom_functions = {}
  //
  //   ONB.embed = function(){for(ONB.item in ONB._queryparam){ONB.ignition_url += '&' + ONB.item + '=' + encodeURIComponent(ONB._queryparam[ONB.item])}for(ONB.d = 0;ONB.d < ONB.black_list.length;ONB.d++){if(location.href.indexOf(ONB.black_list[ONB.d]) != -1){return}}if(Object.keys(ONB._custom_functions).length > 0){ONB.ignition_url += '&custom_functions=' + encodeURIComponent(JSON.stringify(ONB._custom_functions))}ONB.b = document.createElement('script'),ONB.c = document.getElementsByTagName('head')[0];ONB.b.src = ONB.ignition_url;ONB.b.id = 'stands_onbd_point';ONB.b.charset = 'utf-8';ONB.b.async = 'async';ONB.c.appendChild(ONB.b)}
  //   ONB.embed()
  // } catch (error) {
  //   return Promise.reject(error)
  // }
}

const messageParameter = async (messageCount) => {
    console.log(messageCount)
  // const userAgent = window.navigator.userAgent.toLowerCase()
  // if (userAgent.indexOf('msie') >= 0 || userAgent.indexOf('trident') >= 0 ) return
  //
  // var ONB = ONB || {}
  // ONB.ignition_url = 'https://api.onboarding-app.io/v1/onboarding-init?aid=91&pid=108'
  //
  // try {
  //   const {data} = await axios.get('/api/v2/onboarding/contact')
  //
  //   ONB._queryparam = {
  //     role: data.role,
  //     message_count: messageCount
  //   }
  //
  //   // NOTE: Onboardingを除外したいパス
  //   ONB.black_list = []
  //
  //   // NOTE: コールバックの指定
  //   ONB._custom_functions = {}
  //
  //   ONB.embed = function(){for(ONB.item in ONB._queryparam){ONB.ignition_url += '&' + ONB.item + '=' + encodeURIComponent(ONB._queryparam[ONB.item])}for(ONB.d = 0;ONB.d < ONB.black_list.length;ONB.d++){if(location.href.indexOf(ONB.black_list[ONB.d]) != -1){return}}if(Object.keys(ONB._custom_functions).length > 0){ONB.ignition_url += '&custom_functions=' + encodeURIComponent(JSON.stringify(ONB._custom_functions))}ONB.b = document.createElement('script'),ONB.c = document.getElementsByTagName('head')[0];ONB.b.src = ONB.ignition_url;ONB.b.id = 'stands_onbd_point';ONB.b.charset = 'utf-8';ONB.b.async = 'async';ONB.c.appendChild(ONB.b)}
  //   ONB.embed()
  // } catch (error) {
  //   return Promise.reject(error)
  // }
}

export { withParameter, messageParameter }
