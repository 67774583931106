<template lang="pug">
.p-creator-audition-complete
  CompleteTemplate(
    title="申請が完了しました"
    description1="募集要項をご記入いただき、ありがとうございました！"
    description2="現在、事務局が内容を確認しております。少々お待ちください。"
    buttonText="自社依頼へ"
    linkPath="/basecamp/job_offers/histories"
  )
</template>

<script>
import CompleteTemplate from 'src/components/templates/CompleteTemplate'

export default {
  name: 'CreatorAuditionComplete',
  components: { CompleteTemplate },
}
</script>
