export default [
  {
    value: 'proposal',
    label: '提案してもらう'
  },
  {
    value: 'specify',
    label: '指定する'
  },
]
