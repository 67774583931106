import thumbnailCg from '@images/job_offer/thumbnail-cg.jpg'
import thumbnailComposite from '@images/job_offer/thumbnail-composite.jpg'
import thumbnailFood from '@images/job_offer/thumbnail-food.jpg'
import thumbnailInhouse from '@images/job_offer/thumbnail-inhouse.jpg'
import thumbnailLive from '@images/job_offer/thumbnail-live.jpg'
import thumbnailLocation from '@images/job_offer/thumbnail-location.jpg'
import thumbnailModel from '@images/job_offer/thumbnail-model.jpg'
import thumbnailVr from '@images/job_offer/thumbnail-vr.jpg'

export const thumbnails = [
  {
    name: 'model',
    image: thumbnailModel
  },
  {
    name: 'composite',
    image: thumbnailComposite
  },
  {
    name: 'location',
    image: thumbnailLocation
  },
  {
    name: 'cg',
    image: thumbnailCg
  },
  {
    name: 'live',
    image: thumbnailLive
  },
  {
    name: 'inhouse',
    image: thumbnailInhouse
  },
  {
    name: 'food',
    image: thumbnailFood
  },
  {
    name: 'vr',
    image: thumbnailVr
  },
]
