<template lang="pug">
.t-complete-template(:style="{ height: `${contentHeight}px`}")
  .t-complete-template__container
    img.t-complete-template__image(:src="completeImage")
    h1.t-complete-template__title {{ title }}
    p.t-complete-template__description
      | {{ description1 }}
      br.t-complete-template__description--pc(v-if="description2")
      | {{ description2 }}
    Button.t-complete-template__button(:title="buttonText" :href="linkPath" color="black" size="l")
</template>

<script>
import completeImage from '@images/common/complete-image.png'
import Button from 'src/components/atoms/Button'

import { UAParser } from 'ua-parser-js'
const deviceType = new UAParser().getDevice().type

export default {
  name: 'CompleteTemplate',
  components: { Button },
  props: {
    title: {
      type: String,
      required: true
    },
    description1: {
      type: String,
      required: true
    },
    description2: {
      type: String,
      required: false,
      default: null
    },
    buttonText: {
      type: String,
      required: true
    },
    linkPath: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      completeImage,
      contentHeight: null,
      isTouchDevice: deviceType === 'mobile' || deviceType === 'tablet'
    }
  },
  mounted() {
    window.addEventListener('resize', this.calcHeight)
    this.calcHeight()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calcHeight)
  },
  methods: {
    // NOTE：resizeイベント使用したいのでmethodsからdataに値を入れている
    calcHeight() {
      // NOTE：PCとSPでヘッダーの高さ違うので分岐して引算
      if (this.isTouchDevice) {
        this.contentHeight = window.innerHeight - 56
      } else {
        this.contentHeight = window.innerHeight - 90
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.t-complete-template {
  min-height: 500px;
  // NOTE：padding-topはヘッダーの高さ + 上部余白分
  padding: 40px;
  background-color: var(--color-mono-9);

  @include mq-down(tablet--under) {
    padding: 0;
  }

  &__container {
    @include flex(center, center, column);
    width: 100%;
    height: 100%;
    padding: 100px;
    background-color: var(--color-mono-c);
    @include mq-up(tablet) {
      border-radius: 5px;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1);
    }
    @include mq-down(tablet--under) {
      padding: 20px;
    }
  }

  &__image {
    @include flex(center);
    margin-bottom: 50px;
    @include mq-up(tablet) {
      height: 145px;
    }
    @include mq-down(tablet--under) {
      width: 100%;
      margin-bottom: 44px;
    }
  }

  &__title {
    grid-area: title;
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: normal;
    color: var(--color-mono-1);
    text-align: center;
    letter-spacing: .5px;
    @include mq-down(tablet--under) {
      margin-bottom: 20px;
      font-size: 22px;
    }
  }

  &__description {
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 1.8;
    color: var(--color-mono-1);
    text-align: center;
    @include mq-down(tablet--under) {
      font-size: 14px;
    }
    &--pc {
      @include mq-down(tablet--under) {
        display: none;
      }
    }
  }

  &__button {
    @include flex(center);
    width: 210px;
    min-height: 48px;
    @include mq-down(tablet--under) {
      width: 100%;
    }
  }
}
</style>
