<template lang="pug">
  .o-creator-jobs-tab-menu
    //- NOTE：SPで選択してるメニューを表示
    button.o-creator-jobs-tab-menu__button(@click="toggleMenu" v-if="isSP" :class="{'o-creator-jobs-tab-menu__button--open': isShowMenu}")
      span {{ menus[selectedMenuIndex] }}
      i.crevo-icon.crevo-triangle_bottom_fill
    transition(name="menu")
      .o-creator-jobs-tab-menu__wrapper(v-if="isShowMenu" :style="{'height': `${calculateHeight}px`}")
        ul.o-creator-jobs-tab-menu__list(:class="{'o-creator-jobs-tab-menu__list--show': isShowMenu}")
          li.o-creator-jobs-tab-menu__item(
            v-for="(menu, index) in menus"
            ref="menuElement"
            :key="`menu-${index}`"
            :class="{'o-creator-jobs-tab-menu__item--active': index === selectedMenuIndex}"
            @click="onSelectMenu(index)"
          )
            span(ref="menuText") {{ menu }}
        //- NOTE：PCで選択しているメニューの下に追従する
        .o-creator-jobs-tab-menu__bar(v-if="!isSP" :style="{'left': `${barPosition}px`, 'width': `${barWidth}px`}")
</template>

<script>
import { UAParser } from 'ua-parser-js'
const deviceType = new UAParser().getDevice().type

export default {
  name: 'TabMenu',
  props: {
    menus: {
      type: Array,
      required: true,
    },
    selectedMenuIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isSP: deviceType === 'mobile',
      isShowMenu: this.isSP ? false : true,
      barPosition: 0,
      barWidth: 0,
      activeMenu: 0
    }
  },
  computed: {
    calculateHeight () {
      if (!this.isSP || !this.isShowMenu) return 'auto'
      // NOTE： 画面の高さ - ヘッダーの高さ(56px) + SP用のボタンの高さ(40px)
      return window.innerHeight - 96
    },
  },
  mounted() {
    this.activeMenu = this.selectedMenuIndex
    if (this.isSP) {
      this.isShowMenu = false
    } else {
      this.calculateBar(this.selectedMenuIndex)
      window.addEventListener('resize', this.calculateBar, false)
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calculateBar, false)
  },
  methods: {
    toggleMenu () {
      if (!this.isSP) return
      this.isShowMenu = !this.isShowMenu
    },
    calculateBar() {
      const targetElement = this.$refs.menuElement[this.activeMenu]
      // NOTE：文字の両端に余白を4px設ける
      this.barWidth = this.$refs.menuText[this.activeMenu].clientWidth + 8
      // NOTE：クリックしたメニューの横幅 - 対象の中身の幅の半分 + 親要素からのX座標
      this.barPosition = (targetElement.clientWidth / 2) - (this.barWidth / 2) + targetElement.offsetLeft
    },
    onSelectMenu(index) {
      if (this.selectedMenuIndex === index) return
      if (this.isSP) this.toggleMenu()
      this.$emit('selectMenu', index)
      this.activeMenu = index
      this.calculateBar()
    },
  }
}
</script>

<style lang="scss" scoped>
.o-creator-jobs-tab-menu {
  width: 100%;
  @include mq-down(tablet--under) {
    position: fixed;
    top: $sp-header-height;
    left: 0;
    z-index: 10;
    width: 100vw;
  }
  &__button {
    @include flex(center);
    gap: 6px;
    width: 100vw;
    height: 40px;
    background: var(--color-mono-c);
    transition: .2s;

    .crevo-icon {
      font-size: 10px;
      transition: .4s;
    }

    &--open {
      .crevo-icon {
        transform: rotate(180deg);
      }
    }

    &:active {
      opacity: .8;
    }
  }
  &__wrapper {
    position: relative;
    @include mq-down(tablet--under) {
      width: 100vw;
      overflow: hidden;
      background: rgba(0,0,0,.5);
    }
  }
  &__list {
    height: 50px;
    background: var(--color-mono-c);
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0,0,0,.1);
    @include mq-up(tablet) {
      @include flex(center);
    }
    @include mq-down(tablet--under) {
      height: 0;
      &--show {
        height: auto;
        animation: .65s slideDown;
      }
    }
  }
  &__item {
    @include flex(center);
    min-width: 132px;
    height: 50px;
    padding: 0 20px;
    font-size: 16px;
    color: var(--color-mono-1);
    text-align: center;
    cursor: pointer;
    transition: .2s;
    &--active {
      font-weight: bold;
    }
    @include mq-up(narrow-pc) {
      &:hover {
        opacity: .8;
      }
    }
    @include mq-down(narrow-pc--under) {
      &:active {
        opacity: .8;
      }
    }
    @include mq-down(tablet--under) {
      justify-content: flex-start;
      background: var(--color-mono-c);
      border-bottom: 1px solid var(--color-mono-9);
      &--active {
        color: #003684;
      }
    }
  }
  &__bar {
    position: absolute;
    bottom: 0;
    width: 44px;
    height: 3px;
    background: var(--color-mono-1);
    border-radius: 2px 2px 0 0;
    transition: .35s;
  }
}
.menu-enter-active, .menu-leave-active {
  transition: .15s;
}
.menu-enter, .menu-leave-to {
  opacity: 0;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }

  40% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
