export default {
  ja: {
    dateFormat: {
      dateWithDay: 'YYYY年MM月DD日 (dd)',
      dateWithTime: 'YYYY.MM.DD H:mm',
      dateWithTimeUpdate: 'YYYY.MM.DD H:mm 更新',
      dotDate: 'YYYY.MM.DD',
    },
    atoms: {
      input: {
        placeholder: '入力してください'
      },
      select: {
        placeholder: '選択してください'
      },
      textarea: {
        placeholder: '入力してください'
      },
      tagCheckboxList: {
        require: '項目を選択してください',
        minlength: '最低{minlength}つ選択してください (あと{remaining})',
        maxlengh: '{maxlength}つ選択を減らしてください'
      },
      searchInput: {
        placeholder: '検索'
      },
      required: '必須'
    },
    molecules: {
      filter: {
        head: 'フィルター',
        apply: '適用',
        noSelect: '選択なし',
        startDate: '開始日',
        endDate: '終了日',
        clearAll: 'すべてクリア'
      },
      dateSelect: {
        year: '年',
        month: '月',
        date: '日'
      },
      tagCheckboxList: {
        require: '項目を選択してください',
        minlength: '最低{minlength}個選択してください (あと{remaining})',
        maxlengh: '{maxlength}個選択を減らしてください'
      },
      filterModal: {
        cancel: 'キャンセル',
        clear: '選択解除',
        submit: '絞込む'
      },
    },
    amountBreakdown: {
      budget: '募集金額(税抜)',
      referralFeeRate: '紹介手数料({rate}%)',
      reward: '報酬金額(税抜)'
    },
    errorPage: {
      contact: 'お問い合わせ'
    },
    errors: {
      messages: {
        invalidSameValue: '同じ{value}を入力してください',
        required: {
          input: 'このフィールドは必ず入力してください。',
          select: 'このフィールドは必ず選択してください。'
        }
      }
    }
  },
  en: {
    dateFormat: {
      dateWithDay: 'ddd, DD MMM, YYYY',
      dateWithTime: '[At] H:mm. on dddd, MMM DD,YYYY',
      dateWithTimeUpdate: '[Updated at] H:mm on dddd, MMM DD,YYYY',
      dotDate: 'DD.MM.YYYY',
    },
    atoms: {
      input: {
        placeholder: 'This field is empty. Please fill this field.'
      },
      select: {
        placeholder: 'Please select the item on the list.'
      },
      textarea: {
        placeholder: 'This field is empty. Please fill this field.'
      },
      tagCheckboxList: {
        require: 'Please select the item in the tags',
        minlength: 'Please select {minlength} items at least. (You need to select {remaining} more item.)',
        maxlengh: 'Please reduce {maxlengh} item.'
      },
      searchInput: {
        placeholder: 'Search'
      },
      required: 'Required'
    },
    molecules: {
      filter: {
        head: 'Filter',
        apply: 'Apply',
        noSelect: 'No Select',
        startDate: 'Start',
        endDate: 'End',
        clearAll: 'Clear all'
      },
      dateSelect: {
        year: 'year',
        month: 'month',
        date: 'date'
      },
      filterModal: {
        cancel: 'Cancel',
        clear: 'Clear',
        submit: 'Filter',
      }
    },
    amountBreakdown: {
      budget: 'Project offer price(Tax excluded)',
      referralFeeRate: 'Matching fee({rate}%)',
      reward: ' Reward(Tax excluded)'
    },
    errorPage: {
      contact: 'Contact us'
    },
    errors: {
      messages: {
        invalidSameValue: 'Please enter the same {value}.',
        required: {
          input: 'Please fill this field.',
          select: 'Please select item.'
        }
      }
    }
  }
}
