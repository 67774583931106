import infoTables from 'src/lib/creatorAudition/infoTables'
import mediaTypes from 'src/lib/creatorAudition/mediaTypes'

export default {
  computed: {
    informationTableData() {
      // TODO：仕事情報で表示する項目を配列で持たせて空で無いもののみ配列で渡す
      const formattedInformation = [
        { id: 'shooting_location', description: this.shooting_location },
        { id: 'client_name', description: this.client_name },
        { id: 'requested_movie_time_and_number', description: this.requested_movie_time_and_number },
        { id: 'publishing_media_types', description: this.mediaTypes },
        { id: 'delivery_of_goods', description: this.delivery_of_goods },
        { id: 'service_target', description: this.service_target },
      ]
      return formattedInformation.filter(val => val.description)
    },
    information() {
      // NOTE：infoTablesの項目とstateに入っている項目を比較して一致するものを表示
      return this.informationTableData.reduce((acc, data) => {
        const matchedInformation = infoTables.find(info => data.id === info.id)
        return matchedInformation ? [...acc, {...data, head: this.$i18n.t(`creatorAuditions.detail.infoTables.${matchedInformation.id}`)}] : acc
      }, [])
    },
    mediaTypes() {
      // NOTE: 用意された配信媒体に合致する項目のみを返す
      if (this.publishing_media_types.length == 0) return null
      return mediaTypes.filter(data => this.publishing_media_types.includes(data.id))
        .map(data => {
          if (data.id.includes('other') && this.publishing_media) {
            return `${this.$i18n.t(`creatorAuditions.detail.mediaTypes.${data.id}`)} (${this.publishing_media})`
          } else {
            return this.$i18n.t(`creatorAuditions.detail.mediaTypes.${data.id}`)
          }
        })
        .join(' / ')
    }
  }
}
