<template lang="pug">
  transition(name="slide-fade")
    .a-flash-message(
      :class="{'a-flash-message--alert': withAlert}"
      @click="onHide"
      v-if="isShowMessage"
    )
      i.crevo-icon.crevo-close.a-flash-message__close
      slot {{ text }}
</template>

<script>
export default {
  name: 'FlashMessage',
  props: {
    disabled: { // NOTE：clickで非表示を無効化したい時に使用
      type: Boolean,
      required: false,
      default: false,
    },
    isShow: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    withAlert: { // NOTE：アラート表示用に使用
      type: Boolean,
      required: false,
      default: false
    },
    hideTime: { // NOTE：自動で非表示する際に使用(msで指定, デフォルトは3秒)
      type: Number,
      required: false,
      default: 3000
    }
  },
  data() {
    return {
      isShowMessage: this.isShow,
    }
  },
  watch: {
    isShow(newVal) {
      this.isShowMessage = newVal
      this.hideMessageAuto(this.hideTime)
    }
  },
  mounted() {
    this.hideMessageAuto(this.hideTime)
  },
  methods: {
    hideMessageAuto(time) {
      if (this.hideTime === null) return

      setTimeout(() => {
        this.isShowMessage = false
        this.$emit('changeFlashMessageVisibility', false)
      }, time)
    },
    onHide() {
      if (this.disabled) return
      this.isShowMessage = false
      // NOTE：呼び出し元のprops更新しておかないと再利用する時に正常に動作しないので、emitで渡して呼び出し元で更新するようにする
      this.$emit('changeFlashMessageVisibility', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.a-flash-message {
  @include flex;
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000; // NOTE：headerの下に表示されてかぶらないのでheaderと同じ数値を指定している
  z-index: 10001; //NOTE:モーダルが10000なのでそれより大きく設定(moleccules/Modal、molecules/v2/Modalが対象)
  min-width: 240px;
  max-width: 360px;
  min-height: 60px;
  padding: 14px 16px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.4;
  color: var(--color-mono-c);
  text-align: left;
  white-space: pre-wrap;
  cursor: pointer;
  // colorsの--color-greenを使用
  background: rgba(#3a9a59, .8);
  border-radius: 5px;
  box-shadow: var(--decoration-card-shadow);

  @include mq-down(tablet--under) {
    top: calc(#{$sp-header-height} + 20px);
    bottom: auto;
    left: 20px;
    // 左右20pxの余白あける想定
    width: calc(100vw - 40px);
  }
  &--alert {
    // colorsの--color-redを使用
    background: rgba(#e60012, .7);
  }
  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 10px;
    transform: scale(.8);
  }
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: .3s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  @include mq-up(tablet) {
    transform: translateX(-10px);
  }
  @include mq-down(tablet--under) {
    transform: translateY(-10px);
  }
}
</style>
