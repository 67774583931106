import axios from '@axios'

export default {
  namespaced: true,
  state: {
    announcements: [],
    announcementsError: null
  },
  actions: {
    async fetchAnnouncements({ commit }) {
      try {
        const response = await axios.get('/api/v2/dashboard/announcements')
        const formatResponse = response.data.map(element => ({
          title: element.title.rendered,
          link:  element.link,
          date:  element.date
        }))
        commit('setAnnouncements', formatResponse.slice(0, 3))
      } catch(err) {
        commit('setError', err)
      }
    },
  },
  mutations: {
    setAnnouncements(state, announcements) {
      if(announcements) state.announcements = announcements
    },
    setError(state, error) {
      if(error) state.announcementsError = error
    }
  }
}
