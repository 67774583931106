import { camelCase, snakeCase } from 'lodash'

const isObject = value => (
  typeof value === 'object' &&
	value !== null &&
  !Array.isArray(value) &&
  !(value instanceof Date)
)

const convertCase = (convertFunction, value) => {
  if (Array.isArray(value)) {
    return value.map(v => convertCase(convertFunction, v))
  } else if (isObject(value)) {
    return Object.fromEntries(
      Object.entries(value).map(([key, value]) => [convertFunction(key), convertCase(convertFunction, value)])
    )
  } else {
    return value
  }
}

export const camelcaseKeys = value => convertCase(camelCase, value)
export const snakecaseKeys = value => convertCase(snakeCase, value)
