<template lang="pug">
  .m-page-head
    h1.m-page-head__title {{ title }}
    .m-page-head__description(v-if="text") {{ text }}
    .m-page-head__note(v-if="note") {{ note }}
</template>

<script>
export default {
  name: 'PageHead',
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: null
    },
    note: {
      type: String,
      required: false,
      default: null
    },
  }
}
</script>

<style lang="scss" scoped>
.m-page-head {
  @include flex(start, center, column);
  padding: 50px 0 0;
  margin: 0 auto 44px;
  text-align: center;
  @include mq-down(tablet--under) {
    padding: 40px 20px 0;
    margin-bottom: 36px;
  }
  &__title {
    font-size: 22px;
    line-height: 1.1;
  }
  &__description {
    margin-top: 14px;
    font-size: 14px;
    line-height: 1.6;
  }
  &__note {
    margin-top: 8px;
    font-size: 12px;
    line-height: 1.4;
    color: var(--color-red);
  }
}
</style>
